import React, { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "@frontegg/react";
import { useNavigate } from "react-router-dom";
import {
	HomeIcon,
	ClipboardDocumentListIcon,
	UserIcon,
	Bars3Icon,
	ArrowLeftEndOnRectangleIcon,
	ArrowTopRightOnSquareIcon,
	DocumentTextIcon,
	UsersIcon,
	EnvelopeIcon,
	RocketLaunchIcon,
	BeakerIcon,
	SignalIcon,
	ChatBubbleLeftRightIcon,
	SparklesIcon,
	CurrencyDollarIcon,
	Cog6ToothIcon
} from "@heroicons/react/24/solid";
import { PiStudentFill } from "react-icons/pi";
import { AdminPortal } from "@frontegg/react";
import logo from "../../assets/images/logo.png";
import { Switch } from "../../components/shadcn-ui/Switch";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator
} from "../../components/shadcn-ui/DropdownMenu";
import { Sheet, SheetContent, SheetTrigger } from "../../components/shadcn-ui/Sheet";
import { Badge } from "../../components/shadcn-ui/Badge"; // Added Badge import
import TokenManager from "../../services/TokenManager";
import { PhoneIcon } from "lucide-react";
import { NavigateService } from "../../services/NavigateService";
import posthog from "posthog-js";
import { PromptService } from "../../services/PromptService";
import { useToast } from "../../hooks/useToast";

interface HeaderProps {
	maxWidth?: string;
}

const Header: React.FC<HeaderProps> = ({ maxWidth = "max-w-7xl" }) => {
	const { user } = useAuth();
	const { debugMode, setDebugMode } = useAppContext();
	const navigate = useNavigate();
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { toast } = useToast();

	const [isStudentMode, setIsStudentMode] = useState(() => {
		const stored = sessionStorage.getItem("isStudentMode");
		return stored === "true";
	});

	// useEffect(() => {
	// 	sessionStorage.setItem("isStudentMode", isStudentMode.toString());
	// 	console.log("Session Storage IsStudentMode ", sessionStorage.getItem("isStudentMode"));
	// }, [isStudentMode, user]);

	const handleStudentModeToggle = (newValue: boolean) => {
		setIsStudentMode(newValue);
		sessionStorage.setItem("isStudentMode", newValue.toString());

		if (window.location.pathname !== "/user" && sessionStorage.getItem("isStudentMode") === "true") {
			navigate("/user");
		}
	};

	const handleRefreshPrompts = async () => {
		try {
			const success = await PromptService.refreshPrompts();
			if (success) {
				toast({
					title: "Success",
					description: "Prompts refreshed successfully",
					variant: "default"
				});
			} else {
				toast({
					title: "Error",
					description: "Failed to refresh prompts",
					variant: "destructive"
				});
			}
		} catch (error) {
			console.log("error refreshing prompts", error);
			toast({
				title: "Error",
				description: error instanceof Error ? error.message : "An unknown error occurred",
				variant: "destructive"
			});
		}
	};

	const menuItems = [
		// Non-debug mode items
		{ icon: HomeIcon, label: "Dashboard", onClick: () => navigate("/user"), visible: TokenManager.hasMemberClearance() },
		{ icon: DocumentTextIcon, label: "Assignments", onClick: () => navigate("/admin"), visible: TokenManager.hasFacultyClearance() },
		{
			icon: ClipboardDocumentListIcon,
			label: "Session Log",
			onClick: () => navigate("/admin/session-log"),
			visible: TokenManager.hasFacultyClearance()
		},
		{
			icon: UserIcon,
			label: "Account",
			onClick: () => {
				navigate("#/admin-box/users");
				AdminPortal.show();
			},
			visible: TokenManager.hasMemberClearance()
		},
		{
			icon: ArrowTopRightOnSquareIcon,
			label: "Simulations",
			onClick: () => {
				window.location.href = process.env.REACT_APP_INSTAGE_URL || "";
			},
			visible: TokenManager.hasMemberClearance()
		},
		{
			icon: ChatBubbleLeftRightIcon,
			label: "Live Support Chat",
			onClick: () => navigate("/support"),
			visible: TokenManager.hasMemberClearance()
		},
		{
			icon: PiStudentFill,
			label: "Student Mode",
			onClick: () => handleStudentModeToggle(!isStudentMode),
			visible: TokenManager.hasFacultyClearance() || sessionStorage.getItem("isStudentMode") === "true",
			customRender: (isMobile: boolean) => (
				<div className="flex items-center justify-between w-full">
					<div className="flex items-center space-x-2">
						<PiStudentFill className="h-5 w-5" />
						<span>Student Mode</span>
					</div>
					<Switch
						checked={isStudentMode}
						onCheckedChange={handleStudentModeToggle} // Use the wrapper function
						aria-label="Toggle student mode"
					/>
				</div>
			)
		},
		{ icon: Cog6ToothIcon, label: "Admin Settings", onClick: () => navigate("/admin/settings"), visible: TokenManager.hasAdminClearance() },
		// Debug mode items
		{
			icon: UsersIcon,
			label: "Connected Users",
			onClick: () => navigate("/admin/connected-users"),
			visible: TokenManager.hasInstageAdminClearance() && debugMode,
			isDebug: true
		},
		{
			icon: EnvelopeIcon,
			label: "Sent Emails",
			onClick: () => navigate("/admin/sent-emails"),
			visible: TokenManager.hasInstageAdminClearance() && debugMode,
			isDebug: true
		},
		{
			icon: RocketLaunchIcon,
			label: "Onboarding",
			onClick: () => NavigateService.navToOnboarding(),
			visible: TokenManager.hasInstageAdminClearance() && debugMode,
			isDebug: true
		},
		{
			icon: PhoneIcon,
			label: "Phone Call",
			onClick: () => NavigateService.navToPhoneCall(),
			visible: TokenManager.hasInstageAdminClearance() && debugMode,
			isDebug: true
		},
		{
			icon: CurrencyDollarIcon,
			label: "Costs",
			onClick: () => navigate("/admin/costs"),
			visible: TokenManager.hasInstageAdminClearance() && debugMode,
			isDebug: true
		},
		{
			icon: SignalIcon,
			label: "Provider Status",
			onClick: () => window.open("https://status.instage.io", "_blank"),
			visible: TokenManager.hasInstageAdminClearance() && debugMode,
			isDebug: true
		},
		{
			icon: SparklesIcon,
			label: "Refresh Prompts",
			onClick: handleRefreshPrompts,
			visible: TokenManager.hasInstageAdminClearance() && debugMode,
			isDebug: true
		},
		// Logout item
		{
			icon: ArrowLeftEndOnRectangleIcon,
			label: user ? "Log Out" : "Log In",
			onClick: () => {
				sessionStorage.clear();
				console.log("MAJOR_EVENT: User Logged Out");
				posthog.capture("MAJOR_EVENT: User Logged Out", {
					timestamp: new Date().toISOString()
				});
				navigate("/account/logout");
			},
			visible: TokenManager.hasMemberClearance()
		}
	];

	const visibleMenuItems = menuItems.filter((item) => item.visible);

	const renderMenuItems = (isMobile = false) => (
		<>
			{menuItems.map((item, index) => {
				if (!item.visible) return null;

				// Add separator before first debug mode item
				const isFirstDebugItem = item.isDebug && !menuItems[index - 1]?.isDebug;
				const showSeparator = isFirstDebugItem || index === visibleMenuItems.length - 2;

				return (
					<React.Fragment key={item.label}>
						{isFirstDebugItem && (isMobile ? <div className="h-px bg-gray-200 my-2" /> : <DropdownMenuSeparator />)}

						{/* Existing menu item rendering code */}
						{isMobile ? (
							<button
								onClick={() => {
									item.onClick();
									setMobileMenuOpen(false);
								}}
								className="flex items-center space-x-2 text-left py-2 w-full"
							>
								{item.customRender ? (
									item.customRender(isMobile)
								) : (
									<>
										<item.icon className="h-5 w-5" />
										<span>{item.label}</span>
									</>
								)}
							</button>
						) : (
							<DropdownMenuItem
								onSelect={(e) => {
									if (item.customRender) e.preventDefault();
									item.onClick();
								}}
								className="cursor-pointer"
							>
								{item.customRender ? (
									item.customRender(isMobile)
								) : (
									<>
										<item.icon className="h-5 w-5 mr-2" />
										<span>{item.label}</span>
									</>
								)}
							</DropdownMenuItem>
						)}

						{/* Separator before logout */}
						{showSeparator && !isFirstDebugItem && (isMobile ? <div className="h-px bg-gray-200 my-2" /> : <DropdownMenuSeparator />)}
					</React.Fragment>
				);
			})}
		</>
	);

	return (
		<header className="bg-white border-b border-[#eaecf0] h-16">
			<div className={`container mx-auto px-4 sm:px-6 lg:px-8 ${maxWidth} h-full`}>
				<div className="flex justify-between items-center h-full">
					<div className="flex items-center space-x-4">
						<a href="/user" className="text-[#00a9af] hover:text-gray-200 transition-colors duration-200">
							<img src={logo} alt="InStage Logo" className="h-8 w-auto" />
						</a>
						<Badge variant="secondary" className="text-xs bg-[#ffe8c4] text-[#945e0c] rounded-md px-2 py-1 flex items-center">
							<BeakerIcon className="h-3 w-3 mr-1" />
							Beta
						</Badge>
					</div>
					{user && (
						<div className="flex items-center space-x-4">
							{TokenManager.hasInstageAdminClearance() && (
								<Switch
									id="debug-mode"
									checked={debugMode}
									onCheckedChange={(checked) => {
										setDebugMode(checked);
										sessionStorage.setItem("isAdminAccess", checked.toString());
									}}
									className="mr-2"
								/>
							)}
							{/* Desktop menu */}
							<div className="hidden md:block h-8">
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<button className="text-[#00a9af] hover:text-[#008c91] transition-colors duration-200 focus:outline-none">
											<Bars3Icon className="h-8 w-8" />
										</button>
									</DropdownMenuTrigger>
									<DropdownMenuContent align="end" className="w-56">
										{renderMenuItems()}
									</DropdownMenuContent>
								</DropdownMenu>
							</div>

							{/* Mobile menu */}
							<div className="md:hidden h-8">
								<Sheet open={isMobileMenuOpen} onOpenChange={setMobileMenuOpen}>
									{" "}
									{/* Control open state */}
									<SheetTrigger asChild>
										<button className="text-[#00a9af] hover:text-[#008c91] transition-colors duration-200 focus:outline-none">
											<Bars3Icon className="h-8 w-8" />
										</button>
									</SheetTrigger>
									<SheetContent side="right" className="w-[300px] sm:w-[400px] pt-12">
										<nav className="flex flex-col space-y-4">{renderMenuItems(true)}</nav>
									</SheetContent>
								</Sheet>
							</div>
						</div>
					)}
				</div>
			</div>
			{sessionStorage.getItem("isStudentMode") === "true" && (
				<div className="flex justify-center">
					<div className="text-sm text-gray-500 text-center rounded-b-md bg-[rgb(234,236,240)] px-2 py-1 inline-block z-10">
						Student Mode Enabled
					</div>
				</div>
			)}
		</header>
	);
};

export default Header;
