import React, { useEffect, useMemo, useState } from "react";
import { FieldArray } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon, PlusIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../shadcn-ui/Button";
import CustomTooltip from "../../../Utility/CustomTooltip";
import { Switch } from "../../../shadcn-ui/Switch";
import { FrontlineStage, FrontlineProgramName } from "../../../../types/FrontlinePayload";
import { getInitialConfig } from "./FrontlinePlan";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import { FrontlineService } from "../../../../services/FrontlineService";
import FrontlinePlan from "./FrontlinePlan";

const PROGRAM_OPTIONS = [
	{ label: "Flight Services", value: "Flight Services" },
	{ label: "Hospitality Services", value: "Hospitality Services" },
	{ label: "Office Administration", value: "Office Administration" }
] as const;

interface Session {
	date: Date | null;
	startDate: Date | null;
}

interface Values {
	sessions: Session[];
	stages: FrontlineStage[];
	frontlineProgramName?: FrontlineProgramName;
	[key: string]: any;
}

export interface FrontlineDatesProps {
	values: Values;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

interface DateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onStartDateChange: (date: Date | null) => void;
	onEndDateChange: (date: Date | null) => void;
	disabled?: boolean;
	minDate?: Date;
	maxDate?: Date;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onStartDateChange, onEndDateChange, disabled, minDate, maxDate }) => {
	const convertNullToUndefined = (date: Date | null): Date | undefined => {
		return date || undefined;
	};

	const handleEndDateChange = (date: Date | null) => {
		onEndDateChange(date);
		if (date) {
			if (!startDate || startDate > date) {
				const defaultStartDate = new Date(date);
				defaultStartDate.setDate(defaultStartDate.getDate() - 5);
				onStartDateChange(defaultStartDate);
			}
		}
	};

	const handleStartDateChange = (date: Date | null) => {
		if (date && endDate && date > endDate) {
			return;
		}
		onStartDateChange(date);
	};

	return (
		<div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
			<div className="flex-1">
				<div className="flex items-center mb-1">
					<label className="block text-sm font-medium text-gray-700">Due Date*</label>
					<CustomTooltip content="The final date by which students must complete this specific stage." />
				</div>
				<div className="relative">
					<DatePicker
						selected={endDate}
						onChange={handleEndDateChange}
						selectsEnd
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						minDate={minDate}
						maxDate={maxDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border border-[#00a9af] pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select due date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
			<div className="flex-1">
				<div className="flex items-center mb-1">
					<label className="block text-sm font-medium text-gray-700">Start of Availability Window*</label>
				</div>
				<div className="relative">
					<DatePicker
						selected={startDate}
						onChange={handleStartDateChange}
						selectsStart
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						maxDate={endDate || undefined}
						minDate={minDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border border-[#00a9af] pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select start date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

const isStageValid = (stage: FrontlineStage): boolean => {
	if (!stage.enabled) return true;
	if (!stage.frontlineStageType) return false;

	const config = stage.config;
	if (!config) return false;

	// Required fields for all stage types
	const hasRequiredFields = Boolean(
		config.frontlineStudentRole?.trim() &&
			config.frontlineAssistantRole?.trim() &&
			config.frontlineAssistantProblem?.trim() &&
			config.frontlineAssistantRequest?.trim()
	);

	if (!hasRequiredFields) return false;

	// Validate key points - required for all stages
	if (!config.frontlineKeyPoints || config.frontlineKeyPoints.length === 0 || !config.frontlineKeyPoints.every((item) => item.trim() !== "")) {
		return false;
	}

	// Additional validation based on stage type
	switch (stage.frontlineStageType) {
		case "deny":
			return Boolean(config.frontlineIssueWithRequest?.trim());
		case "comply":
		case "gather":
			return Boolean(
				config.frontlineInfoToCollect &&
					config.frontlineInfoToCollect.length > 0 &&
					config.frontlineInfoToCollect.every((item) => item.trim() !== "")
			);
		default:
			return false;
	}
};

const FrontlineDates: React.FC<FrontlineDatesProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onNext,
	onPrevious,
	isValid,
	dirty,
	isEditMode
}) => {
	const [savedPlans, setSavedPlans] = useState<any[]>([]);

	// Ensure values.sessions is always an array
	const sessions = useMemo(() => (Array.isArray(values.sessions) ? values.sessions : []), [values.sessions]);

	// Initialize sessions if not already set
	useEffect(() => {
		if (!sessions || sessions.length === 0) {
			const initialConfig = getInitialConfig("deny");

			setFieldValue("sessions", [{ date: null, startDate: null }]);
			setFieldValue("stages", [
				{
					frontlineStageOrderNumber: 1,
					frontlineStageType: "deny",
					enabled: true,
					date: null,
					startDate: null,
					config: initialConfig
				},
				{
					frontlineStageOrderNumber: 2,
					frontlineStageType: "deny",
					enabled: false,
					date: null,
					startDate: null,
					config: initialConfig
				},
				{
					frontlineStageOrderNumber: 3,
					frontlineStageType: "deny",
					enabled: false,
					date: null,
					startDate: null,
					config: initialConfig
				}
			]);
		}
	}, [sessions, setFieldValue]);

	useEffect(() => {
		const fetchSavedPlans = async () => {
			if (!values.frontlineProgramName) return;

			try {
				const plans = await FrontlineService.getSavedPlans(values.frontlineProgramName);
				setSavedPlans(plans);
			} catch (err) {
				console.error("Error fetching saved plans:", err);
			}
		};

		fetchSavedPlans();
	}, [values.frontlineProgramName]);

	const handleSavedPlanSelect = (stageNumber: number, selectedPlan: any) => {
		if (!values.stages) return;

		const updatedStages = values.stages.map((stage) => {
			if (stage.frontlineStageOrderNumber === stageNumber) {
				return {
					...stage,
					frontlineStageType: selectedPlan.frontlineStageType,
					config: {
						frontlineStudentRole: selectedPlan.frontlineStudentRole,
						frontlineAssistantRole: selectedPlan.frontlineAssistantRole,
						frontlineAssistantProblem: selectedPlan.frontlineAssistantProblem,
						frontlineAssistantRequest: selectedPlan.frontlineAssistantRequest,
						frontlineKeyPoints: selectedPlan.frontlineKeyPoints || [],
						frontlineIssueWithRequest: selectedPlan.frontlineIssueWithRequest,
						frontlineInfoToCollect: selectedPlan.frontlineInfoToCollect || []
					}
				};
			}
			return stage;
		});

		setFieldValue("stages", updatedStages);
	};

	const isFormValid = () => {
		if (isEditMode) return true;

		if (!values.frontlineProgramName) return false;

		// Check if at least one stage is enabled
		const hasEnabledStage = values.stages?.some((stage) => stage.enabled);
		if (!hasEnabledStage) return false;

		// Check each stage
		return (
			values.stages?.every((stage) => {
				if (!stage.enabled) return true;
				return isStageValid(stage) && stage.date && stage.startDate;
			}) ?? false
		);
	};

	const addNewStage = () => {
		if (!values.stages || values.stages.length >= 5) return;

		const newStageNumber = values.stages.length + 1;
		const initialConfig = getInitialConfig("deny");

		const newStage = {
			frontlineStageOrderNumber: newStageNumber,
			frontlineStageType: "deny",
			enabled: true,
			date: null,
			startDate: null,
			config: initialConfig
		};

		setFieldValue("stages", [...values.stages, newStage]);
		setFieldValue("sessions", [
			...values.sessions,
			{
				date: null,
				startDate: null
			}
		]);
	};

	const canAddNewStage = useMemo(() => {
		if (!values.stages || values.stages.length >= 5) return false;
		// Can only add new stage if all current stages are enabled
		return values.stages.every((stage) => stage.enabled);
	}, [values.stages]);

	const handleStageToggle = (stageNumber: number, enabled: boolean) => {
		if (!values.stages) return;

		// Can't disable stage 1
		if (stageNumber === 1) return;

		// When enabling, ensure all previous stages are enabled
		if (enabled) {
			const previousStagesEnabled = values.stages.filter((s) => s.frontlineStageOrderNumber < stageNumber).every((s) => s.enabled);
			if (!previousStagesEnabled) return;
		}

		// When disabling, ensure all later stages are disabled
		if (!enabled) {
			const updatedStages = values.stages.map((stage) => {
				if (stage.frontlineStageOrderNumber === stageNumber) {
					return { ...stage, enabled };
				}
				// Disable all later stages
				if (stage.frontlineStageOrderNumber > stageNumber) {
					return { ...stage, enabled: false };
				}
				return stage;
			});
			setFieldValue("stages", updatedStages);

			// Update sessions to match enabled stages
			const updatedSessions = updatedStages
				.filter((stage) => stage.enabled)
				.map((stage) => ({
					date: stage.date,
					startDate: stage.startDate
				}));

			setFieldValue("sessions", updatedSessions);
			return;
		}

		// Normal enable case
		const updatedStages = values.stages.map((stage) => {
			if (stage.frontlineStageOrderNumber === stageNumber) {
				return { ...stage, enabled };
			}
			return stage;
		});

		setFieldValue("stages", updatedStages);

		// Update sessions to match enabled stages
		const updatedSessions = updatedStages
			.filter((stage) => stage.enabled)
			.map((stage) => ({
				date: stage.date,
				startDate: stage.startDate
			}));

		setFieldValue("sessions", updatedSessions);
	};

	return (
		<div className="space-y-8">
			<Panel title="Configure Your Frontline Assignment">
				<p className="text-base text-gray-600 mb-4">
					Welcome to the Frontline Assignment setup! Each session represents a unique scenario where students will practice handling
					different types of interactions with an AI caller.
				</p>
				<p className="text-base text-gray-600 mb-4">
					For example, in one session a student might practice professionally denying a request that can't be fulfilled, while in another
					they might help someone complete a standard service request.
				</p>
				<p className="text-base text-gray-600">
					First, select your program and configure up to five sessions. For each session, you'll set the availability window and customize
					the scenario, including the specific roles, situation details, and key points students should cover. Students will progress
					through all chosen sessions in order.
				</p>
			</Panel>

			<Panel title="Select Program" validationState={values.frontlineProgramName ? "valid" : "invalid"}>
				<p className="text-base text-gray-600 mb-4">
					Choose the program type to customize your frontline sessions. This will determine the context and scenarios available.
				</p>
				<div>
					<label className="block text-sm font-medium text-gray-700 mb-2">Program Name*</label>
					<Select
						value={values.frontlineProgramName || ""}
						onValueChange={(value: FrontlineProgramName) => {
							setFieldValue("frontlineProgramName", value);
							setFieldValue("courseName", value);
						}}
					>
						<SelectTrigger className="w-full">
							<SelectValue placeholder="Select your program" />
						</SelectTrigger>
						<SelectContent>
							{PROGRAM_OPTIONS.map((option) => (
								<SelectItem key={option.value} value={option.value}>
									{option.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
			</Panel>

			{values.frontlineProgramName && (
				<FieldArray name="sessions">
					{({ push, remove }) => (
						<div className="space-y-4">
							{values.stages?.map((stage, index) => (
								<Panel
									key={stage.frontlineStageOrderNumber}
									title={`Session ${stage.frontlineStageOrderNumber}`}
									validationState={
										stage.enabled ? (isStageValid(stage) && stage.date && stage.startDate ? "valid" : "invalid") : "none"
									}
								>
									<div className={`${!stage.enabled ? "opacity-70" : ""}`}>
										<div className="flex items-center mb-4">
											<Switch
												checked={stage.enabled}
												onCheckedChange={(checked) => handleStageToggle(stage.frontlineStageOrderNumber, checked)}
												disabled={
													isEditMode ||
													stage.frontlineStageOrderNumber === 1 || // First stage always enabled
													values.stages.some(
														(s) => s.frontlineStageOrderNumber < stage.frontlineStageOrderNumber && !s.enabled
													) // Can't enable if previous stages are disabled
												}
											/>
											<span className="text-base text-gray-700 ml-3">Enable Session</span>
										</div>

										{stage.enabled && (
											<div className="space-y-6">
												<DateRangePicker
													startDate={stage.startDate}
													endDate={stage.date}
													onStartDateChange={(date) => {
														setFieldValue(`stages[${index}].startDate`, date);
														if (stage.enabled) {
															const sessionIndex = values.stages.filter((s, i) => s.enabled && i < index).length;
															setFieldValue(`sessions[${sessionIndex}].startDate`, date);
														}
													}}
													onEndDateChange={(date) => {
														setFieldValue(`stages[${index}].date`, date);
														if (stage.enabled) {
															const sessionIndex = values.stages.filter((s, i) => s.enabled && i < index).length;
															setFieldValue(`sessions[${sessionIndex}].date`, date);
														}
													}}
													disabled={isEditMode || !stage.enabled}
													minDate={new Date()}
												/>

												<FrontlinePlan
													frontlineStageOrderNumber={stage.frontlineStageOrderNumber}
													values={values}
													setFieldValue={setFieldValue}
													savedPlans={savedPlans}
													onSavedPlanSelect={(plan) => handleSavedPlanSelect(stage.frontlineStageOrderNumber, plan)}
												/>
											</div>
										)}
									</div>
								</Panel>
							))}

							{values.stages?.length < 5 && !isEditMode && (
								<Button
									variant="outline"
									onClick={addNewStage}
									disabled={!canAddNewStage}
									className={`w-full text-primary-900 border-primary-900 ${!canAddNewStage ? "opacity-50 cursor-not-allowed" : ""}`}
								>
									<PlusIcon className="h-5 w-5 mr-2 text-primary-900" />
									<span className="text-primary-900">Add Session</span>
								</Button>
							)}
						</div>
					)}
				</FieldArray>
			)}

			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	validationState?: "valid" | "invalid" | "none";
	tooltipContent?: string;
}> = ({ title, children, validationState = "none", tooltipContent }) => (
	<div
		className={`bg-white border rounded-lg p-6 ${
			validationState === "valid" ? "border-[#00a9af]" : validationState === "invalid" ? "border-incomplete" : "border-[#eaecf0]"
		}`}
	>
		<div className="flex justify-between items-center mb-4">
			<div className="flex items-center gap-2">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{tooltipContent && <CustomTooltip content={tooltipContent} />}
			</div>
			{validationState === "valid" && <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />}
			{validationState === "invalid" && <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />}
		</div>
		<div className="space-y-4">{children}</div>
	</div>
);

export default FrontlineDates;
