import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { PencilIcon, ClipboardIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { AssignmentPayload } from "../../../../types/AssignmentPayload";
import { AssignmentService } from "../../../../services/AssignmentService";
import { Button } from "../../../shadcn-ui/Button";
import { AdminAssignmentView } from "@/src/types/AdminAssignmentView";
import { StudentNotications } from "../../../../types/AssignmentPayload";
import { Loader2 } from "lucide-react";

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle
} from "../../../shadcn-ui/AlertDialog";
import { useAppContext } from "../../../../contexts/AppContext";
import { SchedulePayload } from "../../../../types/SchedulePayload";
import { InterviewStage } from "../../../../types/InterviewPayload";
import { FrontlineStage } from "../../../../types/FrontlinePayload";

// Define props for the ReviewAssignment component
export interface ReviewAssignmentProps {
	values: any;
	onNext: () => void;
	onPrevious: () => void;
	onEdit: (step: number) => void;
	isEditMode: boolean;
	onSubmit: (assignmentPayload: AdminAssignmentView) => void;
	assignmentType?: "reflection" | "interview" | "frontline" | "jobSearch" | "checkInSim";
	clientSettings: any;
}

// ReviewAssignment component for reviewing and submitting assignment details
const ReviewAssignment: React.FC<ReviewAssignmentProps> = ({
	values,
	onSubmit,
	onNext,
	onPrevious,
	onEdit,
	isEditMode,
	assignmentType = "reflection",
	clientSettings
}) => {
	const { assignmentId } = useParams<{ step?: string; assignmentId?: string }>();
	const [isDeployDialogOpen, setIsDeployDialogOpen] = useState(false);
	const [isDeploying, setIsDeploying] = useState(false);
	const { instageUser } = useAppContext();

	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	// Helper function to get the correct step index based on section and assignment type
	const getStepIndex = (section: "dates" | "setup" | "details") => {
		const stepOrder = {
			dates: 0,
			setup: assignmentType === "jobSearch" ? -1 : 1,
			details: assignmentType === "jobSearch" ? 1 : 2
		};
		return stepOrder[section];
	};

	const [expandedSessions, setExpandedSessions] = useState<number[]>([]);

	const toggleSessionExpand = (sessionNumber: number) => {
		setExpandedSessions((prev) => (prev.includes(sessionNumber) ? prev.filter((n) => n !== sessionNumber) : [...prev, sessionNumber]));
	};

	// Format date to a readable string
	const formatDate = (date: Date | null | undefined) => {
		return date ? format(date, "MMMM d, yyyy") : "Not set";
	};

	// Get availability date text for a session
	const getAvailabilityDateText = (session: any, index: number) => {
		if (session.startDate) {
			return formatDate(session.startDate);
		}
		if (index === 0) {
			return "Anytime";
		}
		return "As soon as previous session is completed";
	};

	// Copy text to clipboard
	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		// Optionally, show a tooltip or notification that the text was copied
	};

	// Get the title for a session
	const getSessionTitle = (session: any, index: number) => {
		if (assignmentType === "checkInSim") {
			if (session.title === "First Check-In") return "First Check-In";
			if (session.title === "Final Check-In") return "Final Check-In";
			return `Check-In ${index}`;
		} else {
			if (session.title === "Pre-Reflection") return "Pre-Reflection";
			if (session.title === "Final Reflection") return "Final Reflection";
			return `Reflection ${index}`; // Changed from index + 1 to index
		}
	};

	// Update the sortedSessions memo with proper typing
	const sortedSessions = React.useMemo(() => {
		if (!values.sessions) return [];
		return [...values.sessions].sort((a, b) => {
			if (a.title === "Pre-Reflection") return -1;
			if (b.title === "Pre-Reflection") return 1;
			if (a.title === "Final Reflection") return 1;
			if (b.title === "Final Reflection") return -1;
			return values.sessions.indexOf(a) - values.sessions.indexOf(b); // Maintain original order for other reflections
		});
	}, [values.sessions, values.stages, assignmentType]);

	// Handle assignment creation or update
	const handleCreateAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		if (status === "deployed") {
			if (isAdminPath) {
				// For admins, open the dialog before creating the assignment
				setIsDeployDialogOpen(true);
			} else {
				// For users, create the assignment right away
				return await createAssignment(status);
			}
		} else {
			// Proceed with creating draft
			return await createAssignment(status);
		}
		return null;
	};

	// Create or update assignment
	const createAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		setIsDeploying(true);
		try {
			// Ensure instageUser email is included in recipientList when not in admin path
			let updatedRecipientList = values.recipientList;
			if (!isAdminPath && instageUser?.email) {
				const emailList = values.recipientList ? values.recipientList.split(",").map((e: string) => e.trim()) : [];
				if (!emailList.includes(instageUser.email)) {
					emailList.push(instageUser.email);
					updatedRecipientList = emailList.join(",");
				}
			}

			const assignmentPayload = new AssignmentPayload(
				{
					title: !isAdminPath && assignmentType === "interview" ? `Self-Practice Interview for ${values.staticJobTitle}` : values.title,
					adminEmail: instageUser?.email || "admin@instage.io",
					adminFirstName: instageUser?.firstName || "InStage",
					adminLastName: instageUser?.lastName || "",
					additionalReportRecipients: values.additionalReportRecipients,
					emailDeliveryOptions: values.emailDeliveryOptions,
					description: values.description,
					courseName: values.courseName,
					courseCode: values.courseCode,
					courseSection: values.courseSection,
					includeCSV: values.includeCSV,
					includePDF: values.includePDF,
					includeUserIdentification: values.includeUserIdentification,
					recipientList: updatedRecipientList,
					reportFrequency: values.reportFrequency,
					sendReminders: values.notify24HoursBefore,
					experienceType: values.experienceType,
					sessions: values.sessions.map((session: any, index: number) => {
						let date = null;
						let startDate = null;
						if (session.date) {
							const localDate = new Date(session.date);
							localDate.setHours(23, 59, 0, 0);
							date = localDate.toISOString();
						}
						if (session.startDate) {
							const localDate = new Date(session.startDate);
							localDate.setHours(0, 1, 0, 0);
							startDate = localDate.toISOString();
						}

						// For interview assignments, include interview-specific data
						if (assignmentType === "interview" && values.stages) {
							// Get enabled stages and sort them by their type in the desired order (Resume Review is included in initial stages but defaulted to null)
							const stageTypeOrder = ["resume", "screening", "middle", "final"];
							const enabledStages = values.stages
								.filter((stage: InterviewStage) => stage.enabled)
								.sort(
									(a: InterviewStage, b: InterviewStage) =>
										stageTypeOrder.indexOf(a.interviewStageType) - stageTypeOrder.indexOf(b.interviewStageType)
								);
							const currentStage = enabledStages[index];
							const stagePlan = values.stagePlan[currentStage.interviewStageOrderNumber];

							return new SchedulePayload({
								...session,
								date,
								startDate,
								interview: {
									interviewStageOrderNumber: currentStage?.interviewStageOrderNumber,
									interviewStageType: currentStage?.interviewStageType,
									jobTitle: values.staticJobTitle,
									jobDescription: values.jobDescription,
									interviewQuestionList: stagePlan?.interviewQuestionList || [],
									jobSummary: values.jobSummary || "",
									jobFunction: values.jobFunction || "",
									jobExperienceLevel: values.jobExperienceLevel || "",
									jobResponsibilities: values.jobResponsibilities || [],
									jobSkillsRequired: values.jobSkillsRequired || [],
									interviewerAttitude: stagePlan?.interviewerAttitude || ""
								}
							});
						}

						// For frontline assignments, include frontline-specific data
						if (assignmentType === "frontline" && values.stages) {
							// Get enabled stages
							const enabledStages = values.stages.filter((stage: FrontlineStage) => stage.enabled);
							const currentStage = enabledStages[index];

							return new SchedulePayload({
								...session,
								date,
								startDate,
								frontlinePayload: {
									frontlineProgramName: values.frontlineProgramName,
									frontlineStageOrderNumber: currentStage?.frontlineStageOrderNumber,
									frontlineStageType: currentStage?.frontlineStageType,
									config: currentStage?.config
								}
							});
						}

						// For reflection assignments or if no stage data found
						return new SchedulePayload({
							...session,
							date,
							startDate
						});
					}),
					competencies: values.competencies,
					assignmentCode: values.assignmentCode,
					includeTranscript: values.includeTranscript,
					includeAudio: values.includeAudio,
					studentNotifications: values.studentNotifications || [],
					isPublished: status === "deployed" ? true : false,
					soloMode: !isAdminPath ? true : false,
					gradingType: values.gradingType,
					gradingWeights: values.gradingWeights,
					showGradesToStudents: values.showGradesToStudents
				},
				clientSettings
			);

			// console.log("assignmentPayload", assignmentPayload);

			if (isEditMode && assignmentId) {
				const data = await AssignmentService.updateAssignment(assignmentId, assignmentPayload);
				console.log("Assignment updated successfully:", data);
				onSubmit(data);
				return data;
			} else {
				const data = await AssignmentService.createAssignment(assignmentPayload);
				console.log("Assignment created successfully:", data);
				onSubmit(data);
				return data;
			}
		} catch (error) {
			console.error("Error creating assignment:", error);
		} finally {
			setIsDeploying(false);
			setIsDeployDialogOpen(false);
		}
		return null;
	};

	// Extract common button content into a component
	const DeployButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button onClick={() => handleCreateAssignment("deployed")} disabled={isDeploying} className={className}>
			{isDeploying ? (
				<>
					<Loader2 className="mr-2 h-4 w-4 animate-spin" />
					Deploying...
				</>
			) : values.isPublished ? (
				isAdminPath ? (
					"Update Assignment"
				) : (
					"Update"
				)
			) : isAdminPath ? (
				"Deploy Assignment"
			) : (
				"Create"
			)}
		</Button>
	);

	const PreviousButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button
			variant="secondary"
			onClick={() => {
				onPrevious();
			}}
			className={className}
		>
			Previous
		</Button>
	);

	// Helper function to get stage plan
	const getStagePlan = (stage: InterviewStage) => {
		return values.stagePlan?.[stage.interviewStageOrderNumber] || null;
	};

	// Helper function to get stage session
	const getStageSession = (stage: InterviewStage | FrontlineStage) => {
		const orderNumber = "interviewStageOrderNumber" in stage ? stage.interviewStageOrderNumber : stage.frontlineStageOrderNumber;
		return values.sessions?.[orderNumber - 1] || null;
	};

	// Sort stages by order number for both interview and frontline assignments
	const sortedStages = React.useMemo(() => {
		if (!values.stages) return [];
		if (assignmentType === "interview") {
			return values.stages.slice().sort((a: InterviewStage, b: InterviewStage) => a.interviewStageOrderNumber - b.interviewStageOrderNumber);
		}
		if (assignmentType === "frontline") {
			return values.stages.slice().sort((a: FrontlineStage, b: FrontlineStage) => a.frontlineStageOrderNumber - b.frontlineStageOrderNumber);
		}
		return [];
	}, [values.stages, assignmentType]);

	// Get enabled stages for both interview and frontline
	const enabledStages = React.useMemo(() => {
		return sortedStages.filter((stage: InterviewStage | FrontlineStage) => stage.enabled);
	}, [sortedStages]);

	return (
		<div className="space-y-8">
			<Panel
				title={
					isAdminPath
						? "Review Your Assignment"
						: `Review Your Self-Practice ${assignmentType === "interview" ? "Interview" : "Reflection"}`
				}
			>
				<p className="text-base text-gray-600 mb-6">
					{isAdminPath
						? "Review all the settings you've selected. If everything looks good, click 'Deploy Assignment' to finalize. You can always come back later to adjust settings or view reports from the dashboard."
						: `Review all the settings you've selected. If everything looks good, click 'Create' to finalize.`}
				</p>
			</Panel>

			{/* Sessions/Stages Panel */}
			<Panel title="Dates" onEdit={() => onEdit(getStepIndex("dates"))}>
				{assignmentType === "reflection"
					? // Reflection sessions
					  sortedSessions.map((session, index) => {
							const sessionTitle = getSessionTitle(session, index);
							const reflectionNumber = sessionTitle.startsWith("Reflection") ? index : null;
							return (
								<div key={index} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">
										{reflectionNumber ? `Reflection ${reflectionNumber}` : sessionTitle}
									</h4>
									<Detail label="Due Date" value={formatDate(session.date)} />
									<Detail label="Available From" value={getAvailabilityDateText(session, index)} />
								</div>
							);
					  })
					: assignmentType === "jobSearch"
					? // Job Search sessions
					  sortedSessions.map((session, index) => {
							return (
								<div key={index} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">{`Call ${index + 1}`}</h4>
									<Detail label="Due Date" value={formatDate(session.date)} />
									<Detail label="Available From" value={getAvailabilityDateText(session, index)} />
								</div>
							);
					  })
					: assignmentType === "interview"
					? // Interview stages
					  enabledStages.map((stage: InterviewStage) => {
							const stagePlan = getStagePlan(stage);
							const stageSession = getStageSession(stage);

							let stageDueDate = stage.date;
							let stageStartDate = stage.startDate;
							if (!stage.date || !stage.startDate) {
								stageDueDate = stageSession?.date;
								stageStartDate = stageSession?.startDate;
							}

							return (
								<div key={stage.interviewStageOrderNumber} className="mb-6 border rounded-lg p-4">
									<div
										className="flex justify-between items-center cursor-pointer"
										onClick={() => toggleSessionExpand(stage.interviewStageOrderNumber)}
									>
										<h4 className="font-semibold text-gray-800">{stage.title}</h4>
										<Button variant="ghost" size="sm" className="h-8 px-2 hover:bg-gray-100">
											{expandedSessions.includes(stage.interviewStageOrderNumber) ? (
												<ChevronUpIcon className="h-5 w-5 text-gray-500" />
											) : (
												<ChevronDownIcon className="h-5 w-5 text-gray-500" />
											)}
										</Button>
									</div>

									<div className="space-y-2 mt-4">
										<div className="grid grid-cols-2 gap-x-4">
											<Detail label="Due Date" value={formatDate(stageDueDate)} />
											<Detail label="Available From" value={formatDate(stageStartDate)} />
										</div>
									</div>

									{expandedSessions.includes(stage.interviewStageOrderNumber) && stagePlan && (
										<div className="mt-6 space-y-4">
											<Detail label="Questions" value={`${stagePlan.interviewQuestionList?.length || 0} questions`} />
											{stagePlan.interviewQuestionList && stagePlan.interviewQuestionList.length > 0 && (
												<div>
													<h5 className="text-gray-600 mb-2">Interview Questions:</h5>
													<ul className="list-disc pl-5 space-y-1">
														{stagePlan.interviewQuestionList.map((question: { text: string }, index: number) => (
															<li key={index} className="text-gray-800">
																{question.text}
															</li>
														))}
													</ul>
												</div>
											)}
											<Detail
												label="Interviewer Attitude"
												value={
													(stagePlan.interviewerAttitude || "Neutral").charAt(0).toUpperCase() +
													(stagePlan.interviewerAttitude || "Neutral").slice(1)
												}
											/>
										</div>
									)}
								</div>
							);
					  })
					: assignmentType === "checkInSim"
					? // Check-In sessions
					  sortedSessions.map((session, index) => {
							const sessionTitle = getSessionTitle(session, index);
							const checkInNumber = sessionTitle.startsWith("Check-In") ? index : null;
							return (
								<div key={index} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">{checkInNumber ? `Check-In ${checkInNumber}` : sessionTitle}</h4>
									<Detail label="Due Date" value={formatDate(session.date)} />
									<Detail label="Available From" value={getAvailabilityDateText(session, index)} />
								</div>
							);
					  })
					: // Frontline stages
					  enabledStages.map((stage: FrontlineStage) => {
							const stageSession = getStageSession(stage);

							return (
								<div key={stage.frontlineStageOrderNumber} className="mb-6 border rounded-lg p-4">
									<div
										className="flex justify-between items-center cursor-pointer"
										onClick={() => toggleSessionExpand(stage.frontlineStageOrderNumber)}
									>
										<h4 className="font-semibold text-gray-800">Session {stage.frontlineStageOrderNumber}</h4>
										<Button variant="ghost" size="sm" className="h-8 px-2 hover:bg-gray-100">
											{expandedSessions.includes(stage.frontlineStageOrderNumber) ? (
												<ChevronUpIcon className="h-5 w-5 text-gray-500" />
											) : (
												<ChevronDownIcon className="h-5 w-5 text-gray-500" />
											)}
										</Button>
									</div>

									<div className="space-y-2 mt-4">
										<div className="grid grid-cols-2 gap-x-4">
											<Detail label="Due Date" value={formatDate(stageSession?.date)} />
											<Detail label="Available From" value={formatDate(stageSession?.startDate)} />
										</div>
										<Detail
											label="Framework"
											value={
												stage.frontlineStageType === "deny"
													? "Denying a Request"
													: stage.frontlineStageType === "comply"
													? "Complying with a Request"
													: "Information Gathering"
											}
										/>
									</div>

									{expandedSessions.includes(stage.frontlineStageOrderNumber) && stage.config && (
										<div className="mt-6 space-y-4">
											<div className="grid grid-cols-2 gap-x-4">
												<Detail label="Student Role" value={stage.config.frontlineStudentRole} />
												<Detail label="AI Role" value={stage.config.frontlineAssistantRole} />
											</div>

											<Detail label="AI's Problem" value={stage.config.frontlineAssistantProblem} />
											<Detail label="AI's Request" value={stage.config.frontlineAssistantRequest} />

											{stage.frontlineStageType === "deny" && stage.config.frontlineIssueWithRequest && (
												<Detail label="Issue with Request" value={stage.config.frontlineIssueWithRequest} />
											)}

											{(stage.frontlineStageType === "comply" || stage.frontlineStageType === "gather") &&
												stage.config.frontlineInfoToCollect &&
												stage.config.frontlineInfoToCollect.length > 0 && (
													<div>
														<h5 className="text-gray-600 mb-2">Information to Gather:</h5>
														<ul className="list-disc pl-5 space-y-1">
															{stage.config.frontlineInfoToCollect.map((info, index) => (
																<li key={index} className="text-gray-800">
																	{info}
																</li>
															))}
														</ul>
													</div>
												)}

											{stage.config.frontlineKeyPoints && stage.config.frontlineKeyPoints.length > 0 && (
												<div>
													<h5 className="text-gray-600 mb-2">Key Points:</h5>
													<ul className="list-disc pl-5 space-y-1">
														{stage.config.frontlineKeyPoints.map((point, index) => (
															<li key={index} className="text-gray-800">
																{point}
															</li>
														))}
													</ul>
												</div>
											)}
										</div>
									)}
								</div>
							);
					  })}
			</Panel>

			{(assignmentType === "reflection" || assignmentType === "interview") && (
				<Panel
					title={assignmentType === "reflection" ? "Reflection Setup" : assignmentType === "interview" ? "Interview Setup" : ""}
					onEdit={() => {
						const setupStep = getStepIndex("setup");
						if (setupStep >= 0) onEdit(setupStep);
					}}
				>
					{assignmentType === "reflection" && values.competencies && values.competencies.length > 0 && (
						// Reflection-specific details
						<div className="flex">
							<span className="font-normal text-gray-600 w-1/3">Selected Competencies:</span>
							<span className="text-gray-800">{values.competencies.join(", ")}</span>
						</div>
					)}
					{assignmentType === "interview" && (
						// Interview-specific details
						<Detail label="Job Title" value={values.jobTitleChoice === "Static" ? values.staticJobTitle : "Students will select"} />
					)}
				</Panel>
			)}

			{isAdminPath && (
				<>
					<Panel title="Details" onEdit={() => onEdit(getStepIndex("details"))}>
						<Detail label="Assignment Title" value={values.title} />
						<Detail label="Description" value={values.description} />
						{isAdminPath && assignmentType !== "checkInSim" && (
							<>
								<Detail label="Course Name" value={values.courseName} />
								<Detail label="Course Code" value={values.courseCode} />
								<Detail label="Course Section" value={values.courseSection} />
							</>
						)}
					</Panel>

					<Panel title="Grading" onEdit={() => onEdit(getStepIndex("details"))}>
						<Detail
							label="Grading Type"
							value={
								values.gradingType === "none"
									? "None"
									: values.gradingType === "passOrFail"
									? "Pass or Fail"
									: values.gradingType === "numeric"
									? "Numeric"
									: values.gradingType
							}
						/>

						{values.gradingType === "numeric" && values.gradingWeights && (
							<div className="mt-4 pt-4">
								<Detail label="Professionalism" value={`${values.gradingWeights.professionalism}%`} />
								<Detail label="Checklist Items" value={`${values.gradingWeights.checklist}%`} />
							</div>
						)}
					</Panel>

					<Panel title="Distribution" onEdit={() => onEdit(getStepIndex("details"))}>
						{values.recipientList && (
							<div className="mt-4">
								<h4 className="text-md font-normal text-gray-700 mb-2">Added Emails:</h4>
								<ul className="list-disc pl-5 space-y-1">
									{values.recipientList
										.split(",")
										.slice(0, 10)
										.map((email: string, index: number) => (
											<li key={index} className="text-sm text-gray-800">
												{email.trim()}
											</li>
										))}
									{values.recipientList.split(",").length > 10 && (
										<li className="text-sm text-gray-800">... {values.recipientList.split(",").length - 10} more</li>
									)}
								</ul>
							</div>
						)}
						<div className="mt-2">
							<h4 className="text-md font-normal text-gray-700 mb-2">Sign-up Link:</h4>
							<div className="flex items-center space-x-2">
								<span className="text-sm text-gray-800">{`https://www.reflection.instage.io/assignment/${
									values.assignmentCode || "ABC123"
								}`}</span>
								<Button
									variant="outline"
									size="icon"
									onClick={() =>
										copyToClipboard(`https://www.reflection.instage.io/assignment/${values.assignmentCode || "ABC123"}`)
									}
								>
									<ClipboardIcon className="h-5 w-5" />
								</Button>
							</div>
						</div>
					</Panel>

					<Panel title="Student Notifications" onEdit={() => onEdit(getStepIndex("details"))}>
						<Detail label="Send Enrollment Notification" value="Yes" />
						<Detail label="Send Report" value="Yes" />
						{clientSettings?.allowNotifyAvailabilityWindow && (
							<Detail
								label="Send Availability Reminders"
								value={values.studentNotifications?.includes(StudentNotications.notifyAvailabilityWindow) ? "Yes" : "No"}
							/>
						)}
						{clientSettings?.allowNotify24HoursBefore && (
							<Detail
								label="Send 24 Hour Notice Reminders"
								value={values.studentNotifications?.includes(StudentNotications.notify24HoursBefore) ? "Yes" : "No"}
							/>
						)}
						{clientSettings?.allowNotifyDueDate && (
							<Detail
								label="Send Reminder On Due Date"
								value={values.studentNotifications?.includes(StudentNotications.notifyDueDate) ? "Yes" : "No"}
							/>
						)}
					</Panel>

					<Panel title="Aggregated Summary Options" onEdit={() => onEdit(getStepIndex("details"))}>
						{clientSettings?.allowUserIdentification && (
							<Detail label="Include Student Name and Email" value={values.includeUserIdentification ? "Yes" : "No"} />
						)}
						{assignmentType !== "interview" && clientSettings?.allowTranscript && (
							<Detail label="Include Transcript" value={values.includeTranscript ? "Yes" : "No"} />
						)}
						{clientSettings?.allowAudio && <Detail label="Include Audio" value={values.includeAudio ? "Yes" : "No"} />}
						<Detail
							label="Report Frequency"
							value={
								values.reportFrequency === "afterEachDueDate"
									? "After each due date"
									: values.reportFrequency === "afterFinalDueDate"
									? "After final due date"
									: "No email reports"
							}
						/>
						{values.reportFrequency !== "noReports" && (
							<>
								<Detail label="Include CSV" value={values.includeCSV ? "Yes" : "No"} />
								<Detail label="Include PDF" value={values.includePDF ? "Yes" : "No"} />
								{values.additionalReportRecipients && (
									<div className="mt-4">
										<h4 className="text-md font-normal text-gray-700 mb-2">Additional Report Recipients:</h4>
										<ul className="list-disc pl-5 space-y-1">
											{values.additionalReportRecipients.split(",").map((email: string, index: number) => (
												<li key={index} className="text-sm text-gray-800">
													{email.trim()}
												</li>
											))}
										</ul>
									</div>
								)}
							</>
						)}
						<p className="mt-2 text-sm text-gray-600 italic">
							Note: You can always log in to the dashboard to view assignment activity at any time.
						</p>
					</Panel>
				</>
			)}

			{/* Navigation and action buttons - desktop */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<PreviousButton />
				<div className="space-x-4">
					{!values.isPublished && false && (
						<Button variant="outline" onClick={() => handleCreateAssignment("draft")}>
							Save Draft
						</Button>
					)}
					<DeployButton />
				</div>
			</div>

			{/* Navigation and action buttons - mobile */}
			<div className="flex gap-2 mt-8 md:hidden">
				<PreviousButton />
				<DeployButton />
			</div>

			{/* Deployment confirmation dialog */}
			<AlertDialog open={isDeployDialogOpen} onOpenChange={setIsDeployDialogOpen}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Confirm Assignment Deployment</AlertDialogTitle>
						<AlertDialogDescription>Important notes:</AlertDialogDescription>
						<ul className="list-disc pl-5 space-y-2 text-sm text-muted-foreground">
							<li>Students will receive an email about the upcoming assignment.</li>
							<li>Due dates and competencies cannot be changed after deployment.</li>
							<li>You can add students up until the first session due date.</li>
						</ul>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel onClick={() => setIsDeployDialogOpen(false)} disabled={isDeploying}>
							Cancel
						</AlertDialogCancel>
						{/* Deploy Assignment: Where actual deployment happens */}
						<AlertDialogAction onClick={() => createAssignment("deployed")} disabled={isDeploying}>
							{" "}
							{isDeploying ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									Deploying...
								</>
							) : (
								"Confirm Deployment"
							)}
						</AlertDialogAction>
						{!values.isPublished && false && (
							<Button variant="outline" onClick={() => createAssignment("draft")} disabled={isDeploying}>
								Save as Draft
							</Button>
						)}
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

// Panel component for grouping related details
const Panel: React.FC<{ title: string; children: React.ReactNode; onEdit?: () => void }> = ({ title, children, onEdit }) => (
	<div className="bg-white border border-[#00a9af] rounded-lg p-6">
		<div className="flex justify-between items-center mb-4">
			<h3 className="text-lg font-medium text-gray-800">{title}</h3>
			{onEdit && (
				<Button variant="outline" onClick={onEdit}>
					<PencilIcon className="h-5 w-5 mr-1" />
					Edit
				</Button>
			)}
		</div>
		<div className="space-y-2">{children}</div>
	</div>
);

// Detail component for displaying individual details
const Detail: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
	<div className="flex space-x-2">
		<div className="text-gray-600">{label}:</div>
		<div className="text-gray-800 font-semibold">{value}</div>
	</div>
);

export default ReviewAssignment;
