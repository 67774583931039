import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import TemplateViewer from "../ResumeAssist/TemplateViewer";
import UploadJobForm from "../ResumeAssist/UploadJobForm";
import { ArrowUpTrayIcon, WrenchScrewdriverIcon, EllipsisVerticalIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { ProfileView } from "../../types/ProfileView";
import { ProfileService } from "../../services/ProfileService";
import { useAppContext } from "../../contexts/AppContext";
import BackButton from "../Utility/BackButton";
import { ResumeService } from "../../services/ResumeService";
import { useParams } from "react-router-dom";
import { ResumeView } from "../../types/ResumeView";
import { TemplateType, templates } from "../ResumeAssist/ResumeTemplates/TemplateRegistry";
import htmlDocx from "html-docx-js/dist/html-docx";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import ResumeFeedback from "../ResumeAssist/ResumeFeedback";
import NewResumeFeedback from "../ResumeAssist/NewResumeFeedback";
import RewriteSectionPopup from "../ResumeAssist/RewriteSectionPopup";
import DocxTemplate from "../ResumeAssist/ResumeTemplates/DocxTemplate";

export interface UploadJobFormProps {
	profile: ProfileView | null;
	setJobTitle: (jobTitle: string) => void;
	setJobDescription: (jobDescription: string) => void;
	jobTitle: string;
	jobDescription: string;
	generateResume: () => void;
}

export interface TemplateViewerProps {
	profile: ProfileView | null;
	jobTitle: string;
	jobDescription: string;
}

export interface ResumeStyleProps {
	fontSize: number;
	accentColor: string;
}

const ResumeAssist: React.FC = () => {
	// Define a discriminated union type for steps
	const { resumeId } = useParams();
	type Step = {
		component: React.ComponentType<any>;
		label: string;
		icon: React.ForwardRefExoticComponent<any>;
		type: "upload" | "template";
	};

	const [currentStep, setCurrentStep] = useState(0);
	const [jobTitle, setJobTitle] = useState("");
	const [jobDescription, setJobDescription] = useState("");
	const { instageUser } = useAppContext();
	const [profile, setProfile] = useState<ProfileView | null>(null);
	const [resume, setResume] = useState<ResumeView | null>(null);
	const [workingResume, setWorkingResume] = useState<ResumeView | null>(null);
	const [style, setStyle] = useState<ResumeStyleProps>({
		fontSize: 1,
		accentColor: "#00a9af"
	});
	const [template, setTemplate] = useState<TemplateType>("classic");
	const [isEditing, setIsEditing] = useState(false);
	const [isFeedbackExpanded, setIsFeedbackExpanded] = useState(true);

	const [isRewriteSectionPopupOpen, setIsRewriteSectionPopupOpen] = useState(false);
	const [selectedSection, setSelectedSection] = useState<string | null>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const docxTemplateRef = React.useRef<{ downloadDocument: () => Promise<void> }>({ downloadDocument: async () => {} });

	useEffect(() => {
		if (!hasResumeContent(resume)) {
			setCurrentStep(0);
		} else {
			setCurrentStep(1);
		}
	}, [resume]);

	useEffect(() => {
		async function fetchProfile() {
			console.log("fetchProfile");
			if (!instageUser) {
				return;
			}
			console.log("instageUser", instageUser);
			const fetchedProfile = await ProfileService.fetchProfileByUserId(instageUser.id);
			if (!fetchedProfile) {
				// Show Profile Not Found UI
				return;
			}
			console.log("fetchedProfile", fetchedProfile);
			setProfile(fetchedProfile);
		}
		fetchProfile();
	}, [instageUser]);

	useEffect(() => {
		async function fetchResume() {
			if (!resumeId) {
				return;
			}
			const fetchedResume = await ResumeService.fetchResume(resumeId);
			if (!fetchedResume) {
				return;
			}
			console.log("fetchedResume", fetchedResume);
			setJobTitle(fetchedResume.jobTitle);
			setJobDescription(fetchedResume.jobDescription);
			setResume(fetchedResume);
			setWorkingResume(fetchedResume);
		}
		fetchResume();
	}, [resumeId]);

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			if (event.data.type === "OPEN_REWRITE_SECTION") {
				setSelectedSection(event.data.section);
				setIsRewriteSectionPopupOpen(true);
			}
		};

		window.addEventListener("message", handleMessage);
		return () => window.removeEventListener("message", handleMessage);
	}, []);

	const steps: Step[] = [
		{
			component: UploadJobForm,
			label: "Step 1: Target Job",
			icon: ArrowUpTrayIcon,
			type: "upload"
		},
		{
			component: TemplateViewer,
			label: "Step 2: Resume Editor",
			icon: WrenchScrewdriverIcon,
			type: "template"
		}
		// {
		// 	component: TemplateViewer,
		// 	label: "Cover Letter",
		// 	icon: WrenchScrewdriverIcon,
		// 	type: "template"
		// }
	];

	const generateResume = async () => {
		console.log("generateResume");
		if (!profile) {
			console.log("No profile found");
			return;
		}
		if (!resumeId) {
			console.log("No resumeId found");
			return;
		}

		try {
			const generatedResume = await ResumeService.generateResume(profile.id, jobTitle, jobDescription, resumeId);

			window.location.reload();
			// Verify we have the necessary data
			if (!generatedResume || !generatedResume.feedbackNew) {
				console.error("Generated resume is missing required data");
				return;
			}

			// Merge the resumes while preserving the id and other fields
			const mergedResume: ResumeView = {
				...generatedResume,
				id: resumeId,
				userId: generatedResume.userId,
				userEmail: generatedResume.userEmail,
				createdAt: resume?.createdAt || generatedResume.createdAt,
				updatedAt: new Date()
			};

			// Set both resume and workingResume before changing step
			setResume(mergedResume);
			setWorkingResume(mergedResume);

			// Wait for state updates to complete before changing step
			await new Promise((resolve) => setTimeout(resolve, 100));

			console.log("Updated resume:", mergedResume);

			// Only change step if we still have the data
			if (mergedResume.feedbackNew) {
				setCurrentStep(1);
			}
		} catch (error) {
			console.error("Error generating resume:", error);
		}
	};

	const saveUpdatedResume = async () => {
		console.log("saveUpdatedResume");
		if (!workingResume) return;

		if (isEditing) {
			await ResumeService.updateResume(workingResume.id, workingResume);
			setResume(workingResume);
		}
		setIsEditing(!isEditing);
	};

	const downloadDocx = () => {
		if (!resume) return;

		// For DocxTemplate, we'll use its direct download function
		docxTemplateRef.current?.downloadDocument();
	};

	const downloadPdf = () => {
		if (!resume) return;

		const TemplateComponent = templates[template].component;
		const html = ReactDOMServer.renderToString(<TemplateComponent resume={resume} style={style} />);
		const htmlWithStyles = `<!DOCTYPE html><html><head></head><body>${html}</body></html>`;

		const element = document.createElement("div");
		element.innerHTML = htmlWithStyles;
		document.body.appendChild(element);

		const options = {
			margin: 10,
			filename: "resume.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: {
				scale: 2,
				useCORS: true,
				logging: true,
				letterRendering: true
			},
			jsPDF: {
				unit: "mm",
				format: "a4",
				orientation: "portrait"
			}
		};

		html2pdf()
			.set(options)
			.from(element)
			.save()
			.then(() => {
				document.body.removeChild(element);
			})
			.catch((err: Error) => {
				console.error("PDF generation failed:", err);
				document.body.removeChild(element);
			});
	};

	const handleStyleChange = (property: keyof ResumeStyleProps, value: string | number) => {
		console.log(`handleStyleChange - ${property}:`, value);
		setStyle((prevStyle) => ({
			...prevStyle,
			[property]: value
		}));
	};

	const hasResumeContent = (resume: ResumeView | null): boolean => {
		if (!resume) return false;

		return !!(
			resume.summary?.trim() ||
			resume.contact?.firstName ||
			resume.contact?.lastName ||
			resume.contact?.email ||
			resume.contact?.phone ||
			resume.skills.technical.length > 0 ||
			resume.skills.coreCompetencies.length > 0 ||
			resume.skills.tools.length > 0
		);
	};

	return (
		<div className={`container mx-auto px-4 sm:px-6 lg:px-8 min-h-full ${isEditing && isRewriteSectionPopupOpen ? "max-w-7xl" : "max-w-7xl"}`}>
			{/* {resume && currentStep === 1 && <ResumeFeedback resume={resume} />} */}

			<div className="flex flex-col">
				<div className="w-full">
					<BackButton />
					{currentStep === 1 && (
						<div className="m-0 p-0 mb-4">
							<button
								onClick={() => setIsFeedbackExpanded(!isFeedbackExpanded)}
								className="flex justify-between items-center rounded-lg transition-colors"
							>
								<span className="font-medium">View Feedback</span>
								<svg
									className={`w-5 h-5 transition-transform ${isFeedbackExpanded ? "rotate-180" : ""}`}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
								</svg>
							</button>
							{isFeedbackExpanded && resume && (
								<div className="mt-2">
									<NewResumeFeedback resume={resume} setResume={setResume} />
								</div>
							)}
						</div>
					)}
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-xl font-semibold text-gray-800">Resume Preview</h2>
						<div className="flex gap-2">
							{/* {isEditing && (
								<button
									className="flex items-center gap-2 px-4 py-2 border border-[#00a9af] text-[#00a9af] rounded-lg hover:bg-[#00a9af]/10"
									onClick={() => {
										setWorkingResume(resume);
										setIsEditing(false);
									}}
								>
									Cancel
								</button>
							)}
							<button
								className="flex items-center gap-2 px-4 py-2 bg-[#00a9af] text-white rounded-lg hover:bg-[#008f94]"
								onClick={saveUpdatedResume}
							>
								{isEditing ? "Save" : "Edit"}
							</button> */}

							{/* <button
								className="flex items-center gap-2 px-4 py-2 bg-[#00a9af] text-white rounded-lg hover:bg-[#008f94]"
								onClick={downloadDocx}
							>
								Download as DOCX
							</button> */}
						</div>
					</div>

					<div className="flex items-center justify-between gap-4 mb-4">
						<div className="flex gap-2">
							{steps.map((step, index) => (
								<button
									key={index}
									className={`flex items-center px-4 py-2 rounded-lg transition-colors border
										${
											currentStep === index
												? "border-[#00a9af] text-[#00a9af] bg-[#00a9af]/10"
												: index === 1 && !hasResumeContent(resume)
												? "border-gray-200 text-gray-400 cursor-not-allowed"
												: "border-gray-200 hover:bg-gray-50 text-gray-700"
										}
										${currentStep === 0 && index === 1 && hasResumeContent(resume) ? "animate-fast-pulse !border-[#00a9af] !text-[#00a9af]" : ""}`}
									disabled={index === 1 && !hasResumeContent(resume)}
									onClick={() => {
										hasResumeContent(resume) ? setCurrentStep(index) : setCurrentStep(0);
									}}
								>
									<div className="p-1 rounded-full mr-2">
										{React.createElement(step.icon, {
											className: `w-5 h-5 ${currentStep === index ? "text-[#00a9af]" : "text-gray-600"}`
										})}
									</div>
									<span>{step.label}</span>
								</button>
							))}
						</div>

						{currentStep === 1 && (
							<div className="flex flex-row gap-2">
								<button
									onClick={() => {
										downloadDocx();
										setIsDropdownOpen(false);
									}}
									className="flex items-center gap-2 px-4 py-2 bg-[#00a9af] text-white rounded-lg hover:bg-[#008f94]"
								>
									<ArrowDownTrayIcon className="w-4 h-4" />
									<span>Download DOCX</span>
								</button>
								<div className="relative">
									<button
										onClick={() => setIsDropdownOpen(!isDropdownOpen)}
										className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
									>
										<EllipsisVerticalIcon className="w-6 h-6 text-gray-600" />
									</button>

									{isDropdownOpen && (
										<div className="absolute right-0 mt-2 w-72 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
											<div className="p-4 space-y-4">
												<div className="space-y-2">
													<label className="text-sm font-medium text-gray-700">Template</label>
													<select
														value={template}
														onChange={(e) => {
															setTemplate(e.target.value as TemplateType);
															console.log("template", e.target.value);
														}}
														className="w-full h-8 px-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-[#00a9af] focus:ring-1 focus:ring-[#00a9af]"
													>
														{Object.entries(templates).map(([key, { name }]) => (
															<option key={key} value={key}>
																{name}
															</option>
														))}
													</select>
												</div>

												<div className="space-y-2">
													<label className="text-sm font-medium text-gray-700">Size</label>
													<div className="flex items-center gap-2">
														<input
															type="range"
															min="0.8"
															max="1.3"
															step="0.1"
															value={style.fontSize}
															className="flex-1 appearance-none bg-transparent [&::-webkit-slider-runnable-track]:bg-gray-200 [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:h-1 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#00a9af] [&::-webkit-slider-thumb]:mt-[-4px]"
															onChange={(e) => handleStyleChange("fontSize", parseFloat(e.target.value))}
														/>
														<span className="text-sm text-gray-600 min-w-[24px]">{style.fontSize}x</span>
													</div>
												</div>

												<div className="space-y-2">
													<label className="text-sm font-medium text-gray-700">Color</label>
													<div className="flex items-center gap-2">
														<input
															type="color"
															value={style.accentColor}
															className="w-8 h-8 p-0.5 border border-gray-300 rounded-md cursor-pointer"
															onChange={(e) => handleStyleChange("accentColor", e.target.value)}
														/>
														<span className="text-sm text-gray-600 uppercase">{style.accentColor}</span>
													</div>
												</div>

												{/* <button
													onClick={() => {
														downloadPdf();
														setIsDropdownOpen(false);
													}}
													className="flex items-center gap-2 w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md transition-colors"
												>
													<ArrowDownTrayIcon className="w-4 h-4" />
													<span>Download PDF</span>
												</button> */}
											</div>
										</div>
									)}
								</div>
							</div>
						)}
					</div>

					{currentStep === 0 ? (
						<UploadJobForm
							profile={profile}
							setJobTitle={setJobTitle}
							setJobDescription={setJobDescription}
							jobTitle={jobTitle}
							jobDescription={jobDescription}
							generateResume={generateResume}
						/>
					) : currentStep === 1 ? (
						<>
							{resume && workingResume && (
								<div style={{ display: "flex", flexDirection: "row", height: "100%", gap: "16px" }}>
									<div className="h-full flex flex-col flex-1 items-center">
										<div className="bg-white border p-0 min-h-[calc(100vh-250px)] overflow-y-auto m-0 rounded-lg w-full">
											<DocxTemplate
												resume={isEditing ? workingResume : resume}
												style={style}
												onDownload={(downloadFn) => {
													docxTemplateRef.current.downloadDocument = downloadFn;
												}}
												TemplateType={template}
											/>
										</div>
									</div>
									{isEditing && isRewriteSectionPopupOpen && (
										<div
											className={`${
												isRewriteSectionPopupOpen ? "w-[350px]" : "w-0"
											} min-h-[calc(100vh-250px)] overflow-y-auto m-0`}
										>
											<RewriteSectionPopup
												resume={resume}
												setResume={setWorkingResume}
												isOpen={isRewriteSectionPopupOpen}
												onClose={() => setIsRewriteSectionPopupOpen(false)}
												content={selectedSection && resume ? selectedSection : ""}
											/>
										</div>
									)}
								</div>
							)}
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ResumeAssist;
