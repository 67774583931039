import axios from "axios";
import { SavedFrontlinePlan, FrontlineProgramName } from "../types/FrontlinePayload";

export class FrontlineService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/frontline`;

	static async getSavedPlans(programName: FrontlineProgramName): Promise<SavedFrontlinePlan[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/saved-plans`, {
				params: { frontlineProgramName: programName }
			});
			return response.data;
		} catch (error) {
			console.error("Error fetching saved frontline plans:", error);
			throw error;
		}
	}
}
