import React from "react";
import Header from "./Header";
import StatusBanner from "../UserPages/StatusBanner";

interface LayoutProps {
	children: React.ReactNode;
	maxWidth?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, maxWidth = "max-w-7xl" }) => {
	return (
		<div className="min-h-screen flex flex-col bg-background">
			<Header maxWidth={maxWidth} />
			<main className="flex-grow py-8 overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:none]">
				<div className={`container mx-auto px-4 sm:px-6 lg:px-8 ${maxWidth} min-h-full`}>{children}</div>
			</main>
		</div>
	);
};

export default Layout;
