import React, { useState } from "react";
import { ProfileView } from "../../types/ProfileView";

interface ProfileProgressBarProps {
	profile: ProfileView | null;
	setSelectedSection?: (section: string) => void;
}

interface SectionStatus {
	name: string;
	status: "empty" | "partial" | "complete";
	displayName: string;
	missingFields: string[];
}

const ProfileProgressBar: React.FC<ProfileProgressBarProps> = ({ profile, setSelectedSection }) => {
	const [hoveredSection, setHoveredSection] = useState<string | null>(null);

	const getMissingFields = (section: string): string[] => {
		if (!profile) return [];

		switch (section) {
			case "contact": {
				const contactFields = [
					{ key: "firstName", label: "First Name" },
					{ key: "lastName", label: "Last Name" },
					{ key: "email", label: "Email" },
					{ key: "phone", label: "Phone" },
					{ key: "address", label: "Address" },
					{ key: "linkedinURL", label: "LinkedIn" }
				];
				return contactFields
					.filter((field) => !profile.contact || !profile.contact[field.key as keyof typeof profile.contact])
					.map((field) => field.label);
			}
			case "summary":
				return !profile.summary || profile.summary.trim().length <= 50 ? ["Complete summary"] : [];
			case "experience":
				if (!profile.experience?.length) return ["Add work experience"];
				const missingExp: string[] = [];
				profile.experience.forEach((exp, idx) => {
					if (!exp.companyName) missingExp.push(`Experience ${idx + 1}: Company name`);
					if (!exp.jobTitle) missingExp.push(`Experience ${idx + 1}: Job title`);
					if (!exp.startMonth || !exp.startYear) missingExp.push(`Experience ${idx + 1}: Start date`);
					if (!exp.roles?.length) missingExp.push(`Experience ${idx + 1}: Responsibilities`);
				});
				return missingExp;
			case "education":
				if (!profile.education?.length) return ["Add education"];
				const missingEdu: string[] = [];
				profile.education.forEach((edu, idx) => {
					if (!edu.school) missingEdu.push(`Education ${idx + 1}: School name`);
					if (!edu.degree) missingEdu.push(`Education ${idx + 1}: Degree`);
					if (!edu.program) missingEdu.push(`Education ${idx + 1}: Program`);
					if (!edu.graduatingYear) missingEdu.push(`Education ${idx + 1}: Graduation year`);
				});
				return missingEdu;
			case "skills": {
				const missingSkills: string[] = [];
				if (!profile.skills?.tools?.length) missingSkills.push("Tools");
				if (!profile.skills?.languages?.length) missingSkills.push("Languages");
				if (!profile.skills?.technical?.length) missingSkills.push("Technical skills");
				if (!profile.skills?.frameworks?.length) missingSkills.push("Frameworks");
				if (!profile.skills?.coreCompetencies?.length) missingSkills.push("Core competencies");
				return missingSkills.length ? missingSkills.map((s) => `Add ${s.toLowerCase()}`) : [];
			}
			case "projects":
				if (!profile.projects?.length) return ["Add projects"];
				const missingProj: string[] = [];
				profile.projects.forEach((proj, idx) => {
					if (!proj.projectName) missingProj.push(`Project ${idx + 1}: Name`);
					if (!proj.description) missingProj.push(`Project ${idx + 1}: Description`);
					if (!proj.startMonth || !proj.startYear) missingProj.push(`Project ${idx + 1}: Date`);
				});
				return missingProj;
			default:
				return [];
		}
	};

	const calculateSectionStatus = (section: string): "empty" | "partial" | "complete" => {
		if (!profile) return "partial";
		const missingFields = getMissingFields(section);
		if (missingFields.length === 0) return "complete";
		if (section === "experience" && !profile.experience?.length) return "empty";
		return "partial";
	};

	const sections: SectionStatus[] = [
		{ name: "contact", displayName: "Contact", status: calculateSectionStatus("contact"), missingFields: getMissingFields("contact") },
		{ name: "summary", displayName: "Summary", status: calculateSectionStatus("summary"), missingFields: getMissingFields("summary") },
		{
			name: "experience",
			displayName: "Experience",
			status: calculateSectionStatus("experience"),
			missingFields: getMissingFields("experience")
		},
		{ name: "education", displayName: "Education", status: calculateSectionStatus("education"), missingFields: getMissingFields("education") },
		{ name: "skills", displayName: "Skills", status: calculateSectionStatus("skills"), missingFields: getMissingFields("skills") },
		{ name: "projects", displayName: "Projects", status: calculateSectionStatus("projects"), missingFields: getMissingFields("projects") }
	];

	const getStatusColor = (status: string) => {
		switch (status) {
			case "empty":
				return "bg-red-500/10 text-red-500 border border-red-500";
			case "partial":
				return "bg-yellow-500/10 text-yellow-600 border border-yellow-500";
			case "complete":
				return "bg-[#00a9af]/10 text-[#00a9af] border border-[#00a9af]";
			default:
				return "bg-gray-200";
		}
	};

	const calculateProgress = () => {
		if (!profile) return 0;

		let totalPoints = 0;
		let earnedPoints = 0;

		// Contact section (10 points)
		const contactFields = ["firstName", "lastName", "email", "phone", "address", "linkedinURL"];
		contactFields.forEach((field) => {
			if (profile.contact && profile.contact[field as keyof typeof profile.contact]) {
				earnedPoints += 2;
			}
		});
		totalPoints += 12;

		// Summary section (10 points)
		if (profile.summary && profile.summary.trim().length > 50) {
			earnedPoints += 10;
		}
		totalPoints += 10;

		// Experience section (25 points)
		if (profile.experience && profile.experience.length > 0) {
			profile.experience.forEach((exp) => {
				if (exp.companyName) earnedPoints += 5;
				if (exp.jobTitle) earnedPoints += 5;
				if (exp.startMonth && exp.startYear) earnedPoints += 5;
				if (exp.roles && exp.roles.length > 0) earnedPoints += 10;
			});
		}
		totalPoints += 25;

		// Education section (20 points)
		if (profile.education && profile.education.length > 0) {
			profile.education.forEach((edu) => {
				if (edu.school) earnedPoints += 5;
				if (edu.degree) earnedPoints += 5;
				if (edu.program) earnedPoints += 5;
				if (edu.graduatingYear) earnedPoints += 3;
				if (edu.notes && edu.notes.length > 0) earnedPoints += 2;
			});
		}
		totalPoints += 20;

		// Skills section (20 points)
		if (profile.skills) {
			if (profile.skills.tools?.length > 0) earnedPoints += 4;
			if (profile.skills.languages?.length > 0) earnedPoints += 4;
			if (profile.skills.technical?.length > 0) earnedPoints += 4;
			if (profile.skills.frameworks?.length > 0) earnedPoints += 4;
			if (profile.skills.coreCompetencies?.length > 0) earnedPoints += 4;
		}
		totalPoints += 20;

		// Projects section (15 points)
		if (profile.projects && profile.projects.length > 0) {
			profile.projects.forEach((project) => {
				if (project.projectName) earnedPoints += 3;
				if (project.description) earnedPoints += 3;
				if (project.startMonth && project.startYear) earnedPoints += 3;
				if (project.projectURL) earnedPoints += 1;
			});
		}
		totalPoints += 15;

		const percentage = (earnedPoints / totalPoints) * 100;
		return Math.min(100, Math.round(percentage));
	};

	const progress = calculateProgress();

	return (
		<div className="w-full space-y-4 mb-4">
			<div>
				<div className="flex justify-between mb-1">
					<span className="text-sm font-medium text-gray-700">Profile Progress</span>
					<span className="text-sm font-medium text-gray-700">{progress}%</span>
				</div>
				<div className="w-full bg-gray-200 rounded-full h-2.5">
					<div className="bg-[#00a9af] h-2.5 rounded-full transition-all duration-500" style={{ width: `${progress}%` }}></div>
				</div>
			</div>

			{/* <div className="flex gap-2 flex-wrap">
				{sections.map((section) => (
					<div
						key={section.name}
						onClick={() => setSelectedSection?.(section.name)}
						onMouseEnter={() => setHoveredSection(section.name)}
						onMouseLeave={() => setHoveredSection(null)}
						className={`px-3 py-1 rounded-md text-sm flex items-center gap-1 cursor-pointer relative
							${getStatusColor(section.status)}`}
					>
						<span>{section.displayName}</span>
						{section.status === "partial" && <span className="w-1.5 h-1.5 rounded-full bg-yellow-500"></span>}

						{hoveredSection === section.name && section.missingFields.length > 0 && (
							<div className="absolute bottom-full left-0 mb-2 w-48 p-2 bg-white rounded-md shadow-lg border border-gray-200 z-10">
								<p className="font-medium mb-1 text-gray-700">Missing:</p>
								<ul className="text-xs text-gray-600 list-disc pl-4">
									{section.missingFields.map((field, idx) => (
										<li key={idx}>{field}</li>
									))}
								</ul>
							</div>
						)}
					</div>
				))}
			</div> */}
		</div>
	);
};

export default ProfileProgressBar;
