import { QuestionType } from "./InterviewPayload";

export class Goal {
	description: string;

	constructor(description: string) {
		this.description = description;
	}
}

export class Goals {
	goals: Goal[];

	constructor(goals: Goal[] = []) {
		this.goals = goals;
	}
}

export class GoalProgress {
	description: string;
	summary: string;
	isReviewed?: boolean;
	goal_status: "Yes" | "Partial" | "No";

	constructor(description: string, summary: string, goal_status: "Yes" | "Partial" | "No", isReviewed?: boolean) {
		this.description = description;
		this.summary = summary;
		this.goal_status = goal_status;
		this.isReviewed = isReviewed;
	}
}

export class GoalProgressList {
	goals: GoalProgress[];

	constructor(goals: GoalProgress[] = []) {
		this.goals = goals.slice(0, 5); // Ensure max 5 goals
	}
}

export class CompetencyProgress {
	name: string;
	assessment: string;
	summary: string;

	constructor(name: string, assessment: string, summary: string) {
		this.name = name;
		this.assessment = assessment;
		this.summary = summary;
	}
}

export class CompetencyFocus {
	name: string;
	assessment: string;
	summary: string;

	constructor(name: string, assessment: string, summary: string) {
		this.name = name;
		this.assessment = assessment;
		this.summary = summary;
	}
}

export class CompetencyProgressList {
	competencies: CompetencyProgress[];

	constructor(competencies: CompetencyProgress[] = []) {
		this.competencies = competencies.slice(0, 4); // Ensure max 4 competencies
	}
}
export class FinalReview {
	takeaways: {
		point: string;
		example: string;
	}[];
	growth: string;
	feedback: {
		valuableAspects: string;
		contribution: string;
		suggestions: string;
		preferredMethod: "guided" | "traditional" | "unknown";
		rating: number;
	};

	constructor(
		takeaways: { point: string; example: string }[] = [],
		growth: string = "",
		feedback: {
			valuableAspects: string;
			contribution: string;
			suggestions: string;
			preferredMethod: "guided" | "traditional" | "unknown";
			rating: number;
		} = {
			valuableAspects: "",
			contribution: "",
			suggestions: "",
			preferredMethod: "unknown",
			rating: 0
		}
	) {
		this.takeaways = takeaways.slice(0, 3); // Ensure max 3 takeaways
		this.growth = growth;
		this.feedback = feedback;
	}
}

export class NoteworthyComments {
	positive: {
		comment: string;
		priority: "low" | "medium" | "high";
	}[];
	negative?: {
		comment: string;
		priority: "low" | "medium" | "high";
	}[];

	constructor(
		positive: { comment: string; priority: "low" | "medium" | "high" }[] = [],
		negative: { comment: string; priority: "low" | "medium" | "high" }[] = []
	) {
		if (positive.length < 2) {
			throw new Error("At least two positive comments are required");
		}
		this.positive = positive;
		this.negative = negative.length > 0 ? negative : undefined;
	}
}

export class Expectations {
	content: string;
	outlook: "positive" | "negative" | "mixed";

	constructor(content: string = "", outlook: "positive" | "negative" | "mixed" = "positive") {
		this.content = content;
		this.outlook = outlook;
	}
}
export class Purpose {
	description: string;
	summary: string;
	progress: "Yes" | "Partial" | "No" | "New";

	constructor(description: string = "", summary: string = "", progress: "Yes" | "Partial" | "No" | "New" = "New") {
		this.description = description;
		this.summary = summary;
		this.progress = progress;
	}
}

export class QuestionListSummary {
	id: string;
	text: string;
	type: QuestionType;
	assessment: {
		score: {
			grade_1: number;
			grade_2: number;
		};
		feedback: string;
	};
	combined_messages: {
		role: string;
		text: string;
	}[];

	constructor(
		id: string = "",
		text: string = "",
		type: QuestionType = "Opening",
		assessment: {
			score: {
				grade_1: number;
				grade_2: number;
			};
			feedback: string;
		} = {
			score: {
				grade_1: 0,
				grade_2: 0
			},
			feedback: ""
		},
		combined_messages: {
			role: string;
			text: string;
		}[] = []
	) {
		this.id = id;
		this.text = text;
		this.type = type;
		this.assessment = assessment;
		this.combined_messages = combined_messages;
	}
}

export class InterviewAssessment {
	strengths: string;
	improvements: string;
	briefing: string;

	constructor(strengths: string = "", improvements: string = "", briefing: string = "") {
		this.strengths = strengths;
		this.improvements = improvements;
		this.briefing = briefing;
	}
}

export class FrontlineAssessment {
	strengths: string;
	improvements: string;
	professionalism: {
		status: string;
		reason: string;
	};
	key_points_evaluation?: {
		point: string;
		status: boolean;
		example?: string;
	}[];
	info_collection_evaluation?: {
		point: string;
		status: boolean;
		example?: string;
	}[];
	score: {
		professionalism: number;
		checklist: number;
	};

	constructor(
		strengths: string = "",
		improvements: string = "",
		professionalism: { status: string; reason: string } = { status: "", reason: "" },
		key_points_evaluation?: {
			point: string;
			status: boolean;
			example?: string;
		}[],
		info_collection_evaluation?: {
			point: string;
			status: boolean;
			example?: string;
		}[],
		score: { professionalism: number; checklist: number } = { professionalism: 0, checklist: 0 }
	) {
		this.strengths = strengths;
		this.improvements = improvements;
		this.professionalism = professionalism;
		this.key_points_evaluation = key_points_evaluation;
		this.info_collection_evaluation = info_collection_evaluation;
		this.score = score;
	}
}
