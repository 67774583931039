import React, { useEffect, useState } from "react";
import {
	Document,
	Packer,
	Paragraph,
	TextRun,
	Table,
	TableCell,
	TableRow,
	HeadingLevel,
	VerticalAlign,
	AlignmentType,
	BorderStyle,
	WidthType,
	TabStopType,
	TabStopPosition
} from "docx";
import { BaseTemplateProps } from "../BaseTemplate";
import { ResumeView } from "../../../../types/ResumeView";
import ResumeEditForm from "../../ResumeEditForm";
import { SectionHeader } from "../../SectionHeader";
import { ResumeStyleProps } from "../../../UserPages/ResumeAssist";

interface ClassicTemplateProps extends BaseTemplateProps {
	onDownload?: (downloadFn: () => Promise<void>) => void;
}

export const ClassicTemplateHTML = (
	resume: ResumeView,
	style: ResumeStyleProps,
	handleSectionClick: (section: string) => void,
	{
		editingSection,
		editedData,
		setEditedData,
		isEditFormOpen,
		setIsEditFormOpen
	}: {
		editingSection: string;
		editedData: any;
		setEditedData: (data: any) => void;
		isEditFormOpen: boolean;
		setIsEditFormOpen: (open: boolean) => void;
	}
) => {
	const singleColumnStyles = (
		<style>{`
       
       .resume-section {
           border: 1px solid transparent;
		   border-radius: 8px;
		   padding: 8px;
           position: relative;  /* Add this */
        }
        .resume-section:hover {
            border-radius: 8px;
            padding: 8px;
            margin: 0px;
            background-color: rgba(0, 169, 175, 0.1);
            border: 1px solid #00a9af;
        }

        .score-button {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 4px;
            padding: 4px 8px;
            border-radius: 4px;
            transition: all 0.2s ease;
            border: 1px solid rgba(0, 169, 175, 0.3);
            background-color: rgba(0, 169, 175, 0.05);
            cursor: pointer;
            position: absolute;  
            top: 0;             
            right: 0;           
        }
        
        .score-button:hover {
            background-color: rgba(0, 169, 175, 0.1);
            
            border-color: rgba(0, 169, 175, 0.5);
        }
        
    `}</style>
	);
	return (
		<>
			{singleColumnStyles}
			<div
				style={{
					fontFamily: "Calibri, sans-serif",
					padding: "60px 40px",

					margin: "0 auto",
					fontSize: `${14 * style.fontSize}px`,
					lineHeight: 1.4,
					color: "#333"
				}}
			>
				{/* Header Section */}
				<div className="resume-section" onClick={() => handleSectionClick("contact")} style={{ cursor: "pointer", marginBottom: "24px" }}>
					<h1 style={{ fontSize: `${24 * style.fontSize}px`, fontWeight: "bold", marginBottom: "8px" }}>
						{resume.contact.firstName} {resume.contact.lastName}
					</h1>
					<div style={{ display: "flex", gap: "16px", color: "#666666", fontSize: `${14 * style.fontSize}px` }}>
						<span>{resume.contact.phone}</span>
						<span>•</span>
						<span>{resume.contact.email}</span>
						{resume.contact.address && (
							<>
								<span>•</span>
								<span>{resume.contact.address}</span>
							</>
						)}
						{resume.contact.website && (
							<>
								<span>•</span>
								<span>{resume.contact.website}</span>
							</>
						)}
					</div>
				</div>

				{/* Summary Section */}
				<div className="resume-section" onClick={() => handleSectionClick("summary")} style={{ cursor: "pointer", marginBottom: "24px" }}>
					<SectionHeader title="SUMMARY" section="summary" resume={resume} style={style} />
					<p style={{ fontSize: `${14 * style.fontSize}px`, lineHeight: 1.6 }}>{resume.summary}</p>
				</div>

				{/* Experience Section */}
				<div className="resume-section" onClick={() => handleSectionClick("experience")} style={{ cursor: "pointer", marginBottom: "24px" }}>
					<SectionHeader title="EXPERIENCE" section="experience" resume={resume} style={style} />
					{resume.experience.map((exp, i) => (
						<div key={i} style={{ marginBottom: "16px" }}>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "4px" }}>
								<h3 style={{ fontSize: `${15 * style.fontSize}px`, fontWeight: "bold" }}>{exp.jobTitle}</h3>
								<span style={{ fontSize: `${14 * style.fontSize}px`, color: "#666666" }}>
									{exp?.startMonth} {exp?.startYear} - {exp?.endMonth} {exp?.endYear}
								</span>
							</div>
							<p style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "8px", color: "#666666" }}>{exp.companyName}</p>
							{exp.roles.map((role, j) => (
								<p key={j} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px", marginLeft: "16px" }}>
									• {role}
								</p>
							))}
						</div>
					))}
				</div>

				{/* Projects Section */}
				<div className="resume-section" onClick={() => handleSectionClick("projects")} style={{ cursor: "pointer", marginBottom: "24px" }}>
					<SectionHeader title="PROJECTS" section="projects" resume={resume} style={style} />
					{resume.projects.map((project, i) => (
						<div key={i} style={{ marginBottom: "16px" }}>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "4px" }}>
								<h3 style={{ fontSize: `${15 * style.fontSize}px`, fontWeight: "bold" }}>{project.projectName}</h3>
								<span style={{ fontSize: `${14 * style.fontSize}px`, color: "#666666" }}>
									{project?.startMonth} {project?.startYear} - {project?.endMonth} {project?.endYear}
								</span>
							</div>
							<p style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "8px" }}>{project?.description}</p>
							{project.notes.map((note, j) => (
								<p key={j} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px", marginLeft: "16px" }}>
									• {note}
								</p>
							))}
						</div>
					))}
				</div>

				{/* Education Section */}
				<div className="resume-section" onClick={() => handleSectionClick("education")} style={{ cursor: "pointer", marginBottom: "24px" }}>
					<SectionHeader title="EDUCATION" section="education" resume={resume} style={style} />
					{resume.education.map((edu, i) => (
						<div key={i} style={{ marginBottom: "16px" }}>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "4px" }}>
								<h3 style={{ fontSize: `${15 * style.fontSize}px`, fontWeight: "bold" }}>{edu.degree}</h3>
								<span style={{ fontSize: `${14 * style.fontSize}px`, color: "#666666" }}>Graduaing Year: {edu.graduatingYear}</span>
							</div>
							<p style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "8px", color: "#666666" }}>{edu.school}</p>
							{edu.notes.map((note, j) => (
								<p key={j} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px", marginLeft: "16px" }}>
									• {note}
								</p>
							))}
						</div>
					))}
				</div>

				{/* Extra Curricular Section */}
				<div className="resume-section" onClick={() => handleSectionClick("extraCurricular")} style={{ cursor: "pointer" }}>
					<SectionHeader title="EXTRA CURRICULAR" section="extraCurricular" resume={resume} style={style} />
					{resume.extraCurricular.map((activity, i) => (
						<div key={i} style={{ marginBottom: "16px" }}>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "4px" }}>
								<h3 style={{ fontSize: `${15 * style.fontSize}px`, fontWeight: "bold" }}>{activity.activity}</h3>
								<span style={{ fontSize: `${14 * style.fontSize}px`, color: "#666666" }}>
									{activity?.startMonth} {activity?.startYear} - {activity?.endMonth} {activity?.endYear}
								</span>
							</div>
							<p style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "8px", color: "#666666" }}>{activity?.orgName}</p>
							{activity.tasks.map((task, j) => (
								<p key={j} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px", marginLeft: "16px" }}>
									• {task}
								</p>
							))}
						</div>
					))}
				</div>

				{/* Skills Section */}
				<div className="resume-section" onClick={() => handleSectionClick("skills")} style={{ cursor: "pointer", marginBottom: "24px" }}>
					<SectionHeader title="SKILLS" section="skills" resume={resume} style={style} />
					<div style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
						<div style={{ flex: "1 1 200px" }}>
							<h3 style={{ fontSize: `${14 * style.fontSize}px`, fontWeight: "bold", marginBottom: "8px" }}>Technical</h3>
							{resume.skills.technical.map((skill, i) => (
								<p key={i} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px" }}>
									{skill}
								</p>
							))}
						</div>
						<div style={{ flex: "1 1 200px" }}>
							<h3 style={{ fontSize: `${14 * style.fontSize}px`, fontWeight: "bold", marginBottom: "8px" }}>Core Competencies</h3>
							{resume.skills.coreCompetencies.map((skill, i) => (
								<p key={i} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px" }}>
									{skill}
								</p>
							))}
						</div>
						<div style={{ flex: "1 1 200px" }}>
							<h3 style={{ fontSize: `${14 * style.fontSize}px`, fontWeight: "bold", marginBottom: "8px" }}>Tools</h3>
							{resume.skills.tools.map((tool, i) => (
								<p key={i} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px" }}>
									• {tool}
								</p>
							))}
						</div>
						<div style={{ flex: "1 1 200px" }}>
							<h3 style={{ fontSize: `${14 * style.fontSize}px`, fontWeight: "bold", marginBottom: "8px" }}>Languages</h3>
							{resume.skills.languages.map((lang, i) => (
								<p key={i} style={{ fontSize: `${14 * style.fontSize}px`, marginBottom: "4px" }}>
									• {lang}
								</p>
							))}
						</div>
					</div>
				</div>
			</div>
			<ResumeEditForm
				editingSection={editingSection}
				editedData={editedData}
				setEditedData={setEditedData}
				isOpen={isEditFormOpen}
				setIsOpen={setIsEditFormOpen}
				resumeId={resume.id}
				resume={resume}
			/>
		</>
	);
};

export const ClassicTemplateDocX = (resume: ResumeView, style: { fontSize: number; accentColor: string }) => {
	return new Document({
		styles: {
			paragraphStyles: [
				{
					id: "Normal",
					name: "Normal",
					run: {
						font: "Calibri",
						size: 22
					},
					paragraph: {
						spacing: {
							after: 100,
							line: 300
						}
					}
				}
			]
		},
		sections: [
			{
				properties: {
					page: {
						margin: {
							top: 720,
							right: 720,
							bottom: 720,
							left: 720
						}
					}
				},
				children: [
					// Header with name and contact info
					new Paragraph({
						children: [
							new TextRun({
								text: `${resume.contact.firstName} ${resume.contact.lastName}`,
								size: (32 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						],
						spacing: { after: 120 }
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: `${resume.contact.phone} • ${resume.contact.email}${
									resume.contact.address ? ` • ${resume.contact.address}` : ""
								}${resume.contact.website ? ` • ${resume.contact.website}` : ""}`,
								size: (22 / 2) * style.fontSize * 2,
								color: "666666",
								font: "Calibri"
							})
						],
						spacing: { after: 240 }
					}),

					// Summary section
					new Paragraph({
						children: [
							new TextRun({
								text: "SUMMARY",
								size: (24 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						],
						spacing: { after: 120 },
						border: {
							bottom: { style: BorderStyle.SINGLE, size: 6, color: "000000" }
						}
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: resume.summary,
								size: (22 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 240 }
					}),

					// Experience section
					new Paragraph({
						children: [
							new TextRun({
								text: "EXPERIENCE",
								size: (24 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						],
						spacing: { before: 120, after: 120 },
						border: {
							bottom: { style: BorderStyle.SINGLE, size: 6, color: "000000" }
						}
					}),
					...resume.experience
						.map((exp) => [
							new Table({
								rows: [
									new TableRow({
										children: [
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: exp.jobTitle,
																size: (22 / 2) * style.fontSize * 2,
																bold: true,
																font: "Calibri"
															})
														]
													})
												],
												width: {
													size: 60,
													type: WidthType.PERCENTAGE
												}
											}),
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: `${exp?.startMonth || ""} ${exp?.startYear || ""} - ${exp?.endMonth || ""} ${
																	exp?.endYear || ""
																}`,
																size: (22 / 2) * style.fontSize * 2,
																color: "666666",
																font: "Calibri"
															})
														],
														alignment: AlignmentType.RIGHT
													})
												],
												width: {
													size: 40,
													type: WidthType.PERCENTAGE
												}
											})
										]
									})
								],
								width: {
									size: 100,
									type: WidthType.PERCENTAGE
								},
								borders: {
									top: { style: BorderStyle.NONE },
									bottom: { style: BorderStyle.NONE },
									left: { style: BorderStyle.NONE },
									right: { style: BorderStyle.NONE },
									insideHorizontal: { style: BorderStyle.NONE },
									insideVertical: { style: BorderStyle.NONE }
								}
							}),
							new Paragraph({
								children: [
									new TextRun({
										text: exp.companyName,
										size: (22 / 2) * style.fontSize * 2,
										color: "666666",
										font: "Calibri"
									})
								],
								spacing: { after: 120 }
							}),
							...exp.roles.map(
								(role) =>
									new Paragraph({
										children: [
											new TextRun({
												text: `• ${role}`,
												size: (22 / 2) * style.fontSize * 2,
												font: "Calibri"
											})
										],
										spacing: { after: 60 },
										indent: { left: 360 }
									})
							)
						])
						.flat(),

					// Education section
					new Paragraph({
						children: [
							new TextRun({
								text: "EDUCATION",
								size: (24 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						],
						spacing: { before: 120, after: 120 },
						border: {
							bottom: { style: BorderStyle.SINGLE, size: 6, color: "000000" }
						}
					}),
					...resume.education
						.map((edu) => [
							new Table({
								rows: [
									new TableRow({
										children: [
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: edu.degree,
																size: (22 / 2) * style.fontSize * 2,
																bold: true,
																font: "Calibri"
															})
														]
													})
												],
												width: {
													size: 60,
													type: WidthType.PERCENTAGE
												}
											}),
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: `Graduating Year: ${edu?.graduatingYear || ""}`,
																size: (22 / 2) * style.fontSize * 2,
																color: "666666",
																font: "Calibri"
															})
														],
														alignment: AlignmentType.RIGHT
													})
												],
												width: {
													size: 40,
													type: WidthType.PERCENTAGE
												}
											})
										]
									})
								],
								width: {
									size: 100,
									type: WidthType.PERCENTAGE
								},
								borders: {
									top: { style: BorderStyle.NONE },
									bottom: { style: BorderStyle.NONE },
									left: { style: BorderStyle.NONE },
									right: { style: BorderStyle.NONE },
									insideHorizontal: { style: BorderStyle.NONE },
									insideVertical: { style: BorderStyle.NONE }
								}
							}),
							new Paragraph({
								children: [
									new TextRun({
										text: edu.school,
										size: (22 / 2) * style.fontSize * 2,
										color: "666666",
										font: "Calibri"
									})
								],
								spacing: { after: 120 }
							}),
							...edu.notes.map(
								(note) =>
									new Paragraph({
										children: [
											new TextRun({
												text: `• ${note}`,
												size: (22 / 2) * style.fontSize * 2,
												font: "Calibri"
											})
										],
										spacing: { after: 60 },
										indent: { left: 360 }
									})
							)
						])
						.flat(),
					// Projects section
					new Paragraph({
						children: [
							new TextRun({
								text: "PROJECTS",
								size: (24 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						],
						spacing: { before: 120, after: 120 },
						border: {
							bottom: { style: BorderStyle.SINGLE, size: 6, color: "000000" }
						}
					}),
					...resume.projects
						.map((project) => {
							const elements: (Table | Paragraph)[] = [
								new Table({
									rows: [
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: project.projectName,
																	size: (22 / 2) * style.fontSize * 2,
																	bold: true,
																	font: "Calibri"
																})
															]
														})
													],
													width: {
														size: 60,
														type: WidthType.PERCENTAGE
													}
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: `${project?.startMonth || ""} ${project?.startYear || ""} - ${
																		project?.endMonth || ""
																	} ${project?.endYear || ""}`,
																	size: (22 / 2) * style.fontSize * 2,
																	color: "666666",
																	font: "Calibri"
																})
															],
															alignment: AlignmentType.RIGHT
														})
													],
													width: {
														size: 40,
														type: WidthType.PERCENTAGE
													}
												})
											]
										})
									],
									width: {
										size: 100,
										type: WidthType.PERCENTAGE
									},
									borders: {
										top: { style: BorderStyle.NONE },
										bottom: { style: BorderStyle.NONE },
										left: { style: BorderStyle.NONE },
										right: { style: BorderStyle.NONE },
										insideHorizontal: { style: BorderStyle.NONE },
										insideVertical: { style: BorderStyle.NONE }
									}
								})
							];

							if (project.description) {
								elements.push(
									new Paragraph({
										children: [
											new TextRun({
												text: project.description,
												size: (22 / 2) * style.fontSize * 2,
												color: "666666",
												font: "Calibri"
											})
										],
										spacing: { after: 120 }
									})
								);
							}

							elements.push(
								...project.notes.map(
									(note) =>
										new Paragraph({
											children: [
												new TextRun({
													text: `• ${note}`,
													size: (22 / 2) * style.fontSize * 2,
													font: "Calibri"
												})
											],
											spacing: { after: 60 },
											indent: { left: 360 }
										})
								)
							);

							return elements;
						})
						.flat(),

					// Extra Curricular section
					new Paragraph({
						children: [
							new TextRun({
								text: "EXTRA CURRICULAR",
								size: (24 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						],
						spacing: { before: 120, after: 120 },
						border: {
							bottom: { style: BorderStyle.SINGLE, size: 6, color: "000000" }
						}
					}),
					...resume.extraCurricular
						.map((activity) => {
							const elements: (Table | Paragraph)[] = [
								new Table({
									rows: [
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: activity.activity,
																	size: (22 / 2) * style.fontSize * 2,
																	bold: true,
																	font: "Calibri"
																})
															]
														})
													],
													width: {
														size: 60,
														type: WidthType.PERCENTAGE
													}
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: `${activity?.startMonth || ""} ${activity?.startYear || ""} - ${
																		activity?.endMonth || ""
																	} ${activity?.endYear || ""}`,
																	size: (22 / 2) * style.fontSize * 2,
																	color: "666666",
																	font: "Calibri"
																})
															],
															alignment: AlignmentType.RIGHT
														})
													],
													width: {
														size: 40,
														type: WidthType.PERCENTAGE
													}
												})
											]
										})
									],
									width: {
										size: 100,
										type: WidthType.PERCENTAGE
									},
									borders: {
										top: { style: BorderStyle.NONE },
										bottom: { style: BorderStyle.NONE },
										left: { style: BorderStyle.NONE },
										right: { style: BorderStyle.NONE },
										insideHorizontal: { style: BorderStyle.NONE },
										insideVertical: { style: BorderStyle.NONE }
									}
								})
							];

							if (activity.orgName) {
								elements.push(
									new Paragraph({
										children: [
											new TextRun({
												text: activity.orgName,
												size: (22 / 2) * style.fontSize * 2,
												color: "666666",
												font: "Calibri"
											})
										],
										spacing: { after: 120 }
									})
								);
							}

							elements.push(
								...activity.tasks.map(
									(task) =>
										new Paragraph({
											children: [
												new TextRun({
													text: `• ${task}`,
													size: (22 / 2) * style.fontSize * 2,
													font: "Calibri"
												})
											],
											spacing: { after: 60 },
											indent: { left: 360 }
										})
								)
							);

							return elements;
						})
						.flat(),
					// Skills section
					new Paragraph({
						children: [
							new TextRun({
								text: "SKILLS",
								size: (24 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						],
						spacing: { before: 120, after: 120 },
						border: {
							bottom: { style: BorderStyle.SINGLE, size: 6, color: "000000" }
						}
					}),
					new Table({
						rows: [
							new TableRow({
								children: [
									// Technical Skills Column
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: "Technical",
														size: (22 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { after: 60 }
											}),
											...resume.skills.technical.map(
												(skill) =>
													new Paragraph({
														children: [
															new TextRun({
																text: `• ${skill}`,
																size: (22 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 60 }
													})
											)
										],
										width: {
											size: 25,
											type: WidthType.PERCENTAGE
										}
									}),
									// Core Competencies Column
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: "Core Competencies",
														size: (22 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { after: 60 }
											}),
											...resume.skills.coreCompetencies.map(
												(skill) =>
													new Paragraph({
														children: [
															new TextRun({
																text: `• ${skill}`,
																size: (22 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 60 }
													})
											)
										],
										width: {
											size: 25,
											type: WidthType.PERCENTAGE
										}
									}),
									// Tools Column
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: "Tools",
														size: (22 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { after: 60 }
											}),
											...resume.skills.tools.map(
												(tool) =>
													new Paragraph({
														children: [
															new TextRun({
																text: `• ${tool}`,
																size: (22 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 60 }
													})
											)
										],
										width: {
											size: 25,
											type: WidthType.PERCENTAGE
										}
									}),
									// Languages Column
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: "Languages",
														size: (22 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { after: 60 }
											}),
											...resume.skills.languages.map(
												(lang) =>
													new Paragraph({
														children: [
															new TextRun({
																text: `• ${lang}`,
																size: (22 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 60 }
													})
											)
										],
										width: {
											size: 25,
											type: WidthType.PERCENTAGE
										}
									})
								]
							})
						],
						width: {
							size: 100,
							type: WidthType.PERCENTAGE
						},
						borders: {
							bottom: { style: BorderStyle.NONE },
							top: { style: BorderStyle.NONE },
							left: { style: BorderStyle.NONE },
							right: { style: BorderStyle.NONE },
							insideHorizontal: { style: BorderStyle.NONE },
							insideVertical: { style: BorderStyle.NONE }
						}
					})
				]
			}
		]
	});
};
