import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../../../shadcn-ui/Button";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogContent, DialogTitle, DialogClose } from "../../../shadcn-ui/Dialog";
import { AssignmentService } from "../../../../services/AssignmentService";
import { CompetencyView } from "../../../../types/CompetencyView";

// Define a dictionary type for competencies
type CompetencyViewDict = {
	[key: string]: CompetencyView[];
};

// Define props for the ReflectionSetup component
export interface ReflectionSetupProps {
	values: any;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onPrevious: () => void;
	onNext: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

const MAX_COMPETENCIES = 4;

// ReflectionSetup component for capturing basic assignment details
const ReflectionSetup: React.FC<ReflectionSetupProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onPrevious,
	onNext,
	isValid,
	dirty,
	isEditMode
}) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");
	const [selectedCompetencies, setSelectedCompetencies] = useState<string[]>(values.competencies || []);
	const [customCompetency, setCustomCompetency] = useState<{ name: string; description: string; examples: string }>({
		name: "",
		description: "",
		examples: ""
	});
	const [isAddingCustom, setIsAddingCustom] = useState<boolean>(false);
	const [selectedDescription, setSelectedDescription] = useState<{ name: string; description: string; examples: string } | null>(null);
	const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
	const [competenciesV2, setCompetenciesV2] = useState<CompetencyViewDict>({});

	// Fetch competencies from the server
	const fetchCompetencies = async () => {
		const competencies = await AssignmentService.getCompetenciesViewByClientId();
		console.log("competencies", competencies);

		const groupedCompetencies = competencies.reduce((acc: CompetencyViewDict, competency) => {
			const category = competency.category || "Uncategorized";
			if (!acc[category]) {
				acc[category] = [];
			}
			acc[category].push(competency);
			return acc;
		}, {});
		console.log("groupedCompetencies", groupedCompetencies);
		setCompetenciesV2(groupedCompetencies);
	};

	useEffect(() => {
		fetchCompetencies();
	}, []);

	// Handle competency selection
	const handleCompetencyToggle = (competency: string) => {
		setSelectedCompetencies((prev) => {
			let newCompetencies;
			if (prev.includes(competency)) {
				newCompetencies = prev.filter((c) => c !== competency);
			} else if (prev.length < MAX_COMPETENCIES) {
				newCompetencies = [...prev, competency];
			} else {
				newCompetencies = prev;
			}
			setFieldValue("competencies", newCompetencies);
			return newCompetencies;
		});
	};

	// Check if the form is valid
	const isFormValid = () => {
		const competencyValid = selectedCompetencies.length >= 1 && selectedCompetencies.length <= MAX_COMPETENCIES;
		return competencyValid;
	};

	// Handle custom competency submission
	const handleCustomSubmit = async () => {
		await AssignmentService.createCustomCompetency({
			name: customCompetency.name,
			description: customCompetency.description,
			examples: customCompetency.examples,
			clientId: values.clientId,
			category: "Custom"
		});
		await fetchCompetencies();

		// Reset the custom competency form and hide it
		setCustomCompetency({ name: "", description: "", examples: "" });
		setIsAddingCustom(false);
	};

	// Handle competency info display
	const handleCompetencyInfo = (name: string, description: string, examples: string) => {
		if (description) {
			setSelectedDescription({ name, description, examples });
			setIsDescriptionOpen(true);
		}
	};

	return (
		<div className="space-y-8">
			<Panel title="Competencies" hasCompetencies={selectedCompetencies.length > 0}>
				<p className="text-sm text-gray-600 mb-4">
					Select or Create a minimum of 1 and a maximum of 4 competencies {isAdminPath ? "for the student" : ""} to focus on
				</p>
				<div className="space-y-4">
					{Object.entries(competenciesV2).map(([category, competencies]) => (
						<div key={category}>
							<h4 className="font-medium text-gray-800 mb-2">{category}</h4>
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
								{competencies
									.sort((a, b) => (a.name === "Custom" ? 1 : b.name === "Custom" ? -1 : 0))
									.map((competency) => (
										<div key={competency.id} className="flex items-center">
											<div
												className={`flex justify-between items-center w-full px-4 py-2 rounded-md text-sm font-medium ${
													selectedCompetencies.includes(competency.name)
														? "bg-[#00a9af] text-white"
														: "bg-gray-100 text-gray-700 hover:bg-gray-200"
												} ${
													selectedCompetencies.length === MAX_COMPETENCIES &&
													!selectedCompetencies.includes(competency.name)
														? "opacity-50 cursor-not-allowed"
														: "cursor-pointer"
												}`}
												onClick={() => {
													if (
														!isEditMode &&
														(selectedCompetencies.length < MAX_COMPETENCIES ||
															selectedCompetencies.includes(competency.name))
													) {
														handleCompetencyToggle(competency.name);
													}
												}}
											>
												<span className="flex-grow">{competency.name}</span>
												<button
													type="button"
													onClick={(e) => {
														e.stopPropagation();
														handleCompetencyInfo(
															competency.name,
															competency.description || "",
															competency.examples || ""
														);
													}}
													className={`ml-2 ${
														selectedCompetencies.includes(competency.name) ? "text-white" : "text-gray-500"
													} hover:text-[#00a9af]`}
												>
													<InformationCircleIcon className="h-4 w-4" />
												</button>
											</div>
										</div>
									))}
							</div>
						</div>
					))}
					{/* Custom Competency Section */}
					{isAddingCustom ? (
						<div className="mt-4 p-4 border rounded-md">
							<label htmlFor="customName" className="block text-sm font-medium text-gray-700 mb-1">
								Custom Competency Name *
							</label>
							<input
								type="text"
								id="customName"
								placeholder="e.g., Team Leadership"
								value={customCompetency.name}
								onChange={(e) => setCustomCompetency({ ...customCompetency, name: e.target.value })}
								className="mb-2 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 p-2"
							/>
							<label htmlFor="customDescription" className="block text-sm font-medium text-gray-700 mb-1">
								Custom Competency Description *
							</label>
							<textarea
								id="customDescription"
								placeholder="e.g., Ability to lead and inspire a team towards common goals."
								value={customCompetency.description}
								onChange={(e) => setCustomCompetency({ ...customCompetency, description: e.target.value })}
								className="mb-2 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 p-2"
							/>
							<label htmlFor="customExamples" className="block text-sm font-medium text-gray-700 mb-1">
								Custom Competency Examples *
							</label>
							<textarea
								id="customExamples"
								placeholder="e.g., - Lead team meetings\n- Delegate tasks effectively\n- Provide constructive feedback"
								value={customCompetency.examples}
								onChange={(e) => setCustomCompetency({ ...customCompetency, examples: e.target.value })}
								className="mb-2 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 p-2"
							/>
							<Button
								type="button"
								onClick={handleCustomSubmit}
								variant="secondary"
								className="mr-2"
								disabled={!customCompetency.name || !customCompetency.description || !customCompetency.examples}
							>
								Add
							</Button>
							<Button type="button" onClick={() => setIsAddingCustom(false)} variant="secondary">
								Cancel
							</Button>
						</div>
					) : (
						<>
							{selectedCompetencies.length < MAX_COMPETENCIES && !isEditMode && (
								<Button type="button" onClick={() => setIsAddingCustom(true)} variant="secondary" size="sm" className="mt-2">
									Add Custom Competency
								</Button>
							)}
							{selectedCompetencies.length >= MAX_COMPETENCIES && (
								<Button
									type="button"
									onClick={() => setIsAddingCustom(true)}
									variant="secondary"
									size="sm"
									className="mt-2 opacity-50 cursor-not-allowed"
									disabled
								>
									Add Custom Competency
								</Button>
							)}
						</>
					)}
				</div>
			</Panel>

			{/* Description Modal */}
			<Dialog open={isDescriptionOpen} onOpenChange={setIsDescriptionOpen}>
				<DialogContent>
					<DialogTitle>{selectedDescription?.name} Description</DialogTitle>
					<div>
						<h4 className="font-semibold">Description</h4>
						<p>{selectedDescription?.description}</p>
						<h4 className="font-semibold mt-4">Examples</h4>
						<div className="max-h-40 overflow-y-auto">
							<ul className="list-disc list-inside">
								{selectedDescription?.examples.split("\n").map((example, index) => (
									<li key={index}>{example.replace("- ", "")}</li>
								))}
							</ul>
						</div>
					</div>
					<DialogClose asChild>
						<Button variant="secondary" className="mt-4">
							Close
						</Button>
					</DialogClose>
				</DialogContent>
			</Dialog>

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					type="button"
					variant="secondary"
					onClick={() => {
						onPrevious();
					}}
				>
					Previous
				</Button>
				<Button
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	hasCompetencies?: boolean;
}> = ({ title, children, hasCompetencies }) => (
	<div
		className={`bg-white border rounded-lg p-6 ${
			hasCompetencies === undefined ? "border-[#eaecf0]" : hasCompetencies ? "border-[#00a9af]" : "border-incomplete"
		}`}
	>
		<div className="flex justify-between items-center mb-4">
			<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
			{typeof hasCompetencies === "boolean" &&
				(hasCompetencies ? (
					<CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />
				) : (
					<CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />
				))}
		</div>
		<div className="space-y-4">{children}</div>
	</div>
);

export default ReflectionSetup;
