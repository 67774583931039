import { SchedulePayload } from "./SchedulePayload";
import { ExperienceType } from "../schemas/ExperienceType";

export enum StudentNotications {
	notifyEnrollment = "notifyEnrollment",
	notifyAvailabilityWindow = "notifyAvailabilityWindow",
	notify24HoursBefore = "notify24HoursBefore",
	notifyDueDate = "notifyDueDate",
	notifyReportCopy = "notifyReportCopy"
}

export enum EmailDeliveryOptions {
	csv = "csv",
	pdf = "pdf"
}

export class AssignmentPayload {
	title: string;

	description: string;

	courseName: string;

	courseCode: string;

	courseSection: string;

	client: string;

	clientId: string;

	adminFirstName: string;

	adminLastName: string;

	adminId: string;

	adminEmail: string;

	sessions: SchedulePayload[];

	recipientList: string;

	sendReminders: boolean;

	sendAvailabilityWindowReminders: boolean;

	sendReminderOnDueDate: boolean;

	assignmentCode: string;

	signupLink: string;

	reportFrequency: "afterEachDueDate" | "afterFinalDueDate" | "noReports";

	emailDeliveryOptions: string;

	studentNotifications: StudentNotications[];

	includeCSV: boolean;

	includePDF: boolean;

	additionalReportRecipients: string | null;

	isPublished: boolean;

	competencies: string[];

	competencyIds: string[];

	experienceType: ExperienceType;

	includeTranscript: boolean;

	includeAudio: boolean;

	includeUserIdentification: boolean;

	soloMode: boolean;

	gradingType: "none" | "passOrFail" | "numeric";

	gradingWeights: Record<string, number>;

	showGradesToStudents: boolean;

	constructor(init?: Partial<AssignmentPayload>, clientSettings?: any) {
		Object.assign(this, init);
		this.experienceType = init?.experienceType ?? ExperienceType.CO_OP_EXPERIENCE;
		this.title = init?.title ?? "";
		this.description = init?.description ?? "";
		this.courseName = init?.courseName ?? "";
		this.courseCode = init?.courseCode ?? "";
		this.courseSection = init?.courseSection ?? "";
		this.client = init?.client ?? "";
		this.clientId = init?.clientId ?? "";
		this.adminFirstName = init?.adminFirstName ?? "";
		this.adminLastName = init?.adminLastName ?? "";
		this.adminId = init?.adminId ?? "";
		this.adminEmail = init?.adminEmail ?? "";
		this.sessions = (init?.sessions ?? []).map((session: SchedulePayload) => new SchedulePayload(session));
		this.recipientList = init?.recipientList ?? "";
		this.sendReminders = init?.sendReminders ?? false;
		this.sendAvailabilityWindowReminders = init?.sendAvailabilityWindowReminders ?? false;
		this.sendReminderOnDueDate = init?.sendReminderOnDueDate ?? false;
		this.assignmentCode = init?.assignmentCode ?? "";
		this.signupLink = init?.signupLink ?? "";
		this.reportFrequency = init?.reportFrequency ?? (clientSettings?.allowEmailReports ? "afterEachDueDate" : "noReports");
		this.emailDeliveryOptions = init?.emailDeliveryOptions ?? "";
		this.includeCSV = init?.includeCSV ?? false;
		this.includePDF = init?.includePDF ?? false;
		this.additionalReportRecipients = init?.additionalReportRecipients ?? null;
		this.isPublished = init?.isPublished ?? false;
		this.competencies = init?.competencies ?? [];
		this.competencyIds = init?.competencyIds ?? [];
		this.includeTranscript = init?.includeTranscript ?? false;
		this.includeAudio = init?.includeAudio ?? false;
		this.includeUserIdentification =
			init?.includeUserIdentification !== undefined ? init.includeUserIdentification : clientSettings?.allowUserIdentification === true;
		this.gradingType = init?.gradingType ?? "none";
		this.gradingWeights = init?.gradingWeights ?? this.getDefaultGradingWeights(init?.experienceType);
		this.showGradesToStudents = init?.showGradesToStudents ?? false;
		this.studentNotifications = [StudentNotications.notifyEnrollment, StudentNotications.notifyReportCopy];
		if (init?.studentNotifications && init.studentNotifications.length > 0) {
			this.studentNotifications = init.studentNotifications;
		} else {
			if (clientSettings?.allowNotifyAvailabilityWindow && init?.sendAvailabilityWindowReminders) {
				this.studentNotifications.push(StudentNotications.notifyAvailabilityWindow);
			}
			if (clientSettings?.allowNotify24HoursBefore && init?.sendReminders) {
				this.studentNotifications.push(StudentNotications.notify24HoursBefore);
			}
			if (clientSettings?.allowNotifyDueDate && init?.sendReminderOnDueDate) {
				this.studentNotifications.push(StudentNotications.notifyDueDate);
			}
		}
		this.soloMode = init?.soloMode ?? false;
	}

	private getDefaultGradingWeights(experienceType?: ExperienceType): Record<string, number> {
		// For now, only frontline assignments have numeric grading
		if (experienceType === ExperienceType.FRONTLINE) {
			return {
				professionalism: 50,
				checklist: 50
			};
		}
		// Return empty object for other types since they don't use numeric grading yet
		return {};
	}

	hasNotification(notification: StudentNotications): boolean {
		return this.studentNotifications.includes(notification);
	}
}
