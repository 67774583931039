import { NavigateFunction, NavigateOptions } from "react-router-dom";
import { ExperienceType } from "../schemas/ExperienceType";

export class NavigateService {
	private static navigate: NavigateFunction | null = null;

	static setNavigate(navigateFunction: NavigateFunction): void {
		this.navigate = navigateFunction;
	}

	static navToStartSession(assignmentId: string, userId: string, sessionId: string) {
		window.location.href = `/user/assignment/${assignmentId}/user/${userId}/session/${sessionId}`;
		// this.navigateTo(`/user/assignment/${assignmentId}/user/${userId}/session/${sessionId}`);
	}

	static navToUserAssignment(assignmentId: string, userId: string, scheduleId?: string) {
		this.navigateTo(this.getNavToUserAssignmentUrl(assignmentId, userId, scheduleId), true);
	}

	static getNavToUserAssignmentUrl(assignmentId: string, userId: string, scheduleId?: string) {
		if (!scheduleId) {
			return `/user/assignment/${assignmentId}/user/${userId}`;
		} else {
			return `/user/assignment/${assignmentId}/user/${userId}/schedule/${scheduleId}`;
		}
	}

	static navToUserReport(assignmentId: string, userId: string) {
		this.navigateTo(`/user/assignment/${assignmentId}/user/${userId}`);
	}

	static navToEditAssignment(assignmentType: string, assignmentId: string) {
		this.navigateTo(`/admin/edit-${assignmentType}/${assignmentId}`, true);
	}

	static navToEditAssignmentStep(assignmentType: string, assignmentId: string, step: number) {
		const urlAssignmentType = assignmentType === "jobSearch" ? "job-search" : assignmentType === "checkInSim" ? "check-in-sim" : assignmentType;
		this.navigateTo(`/admin/edit-${urlAssignmentType}/${assignmentId}/${step}`, false, { replace: true });
	}

	static navToCreateAssignmentStep(assignmentType: string, step: number, isAdmin: boolean = true) {
		const prefix = isAdmin ? "admin" : "user";
		const urlAssignmentType = assignmentType === "jobSearch" ? "job-search" : assignmentType === "checkInSim" ? "check-in-sim" : assignmentType;
		this.navigateTo(`/${prefix}/create-${urlAssignmentType}/${step}`, false, { replace: true });
	}

	static navToAdminSettings() {
		this.navigateTo(`/admin/settings`);
	}

	static navToAdminAssignment(assignmentId: string) {
		this.navigateTo(`/admin/assignment/${assignmentId}`);
	}

	static navToOnboarding() {
		this.navigateTo("/onboarding");
	}

	static navToAdmin() {
		this.navigateTo("/admin");
	}

	static navToUser() {
		this.navigateTo("/user");
	}

	static navToPhoneCall(assignmentId?: string, scheduleId?: string) {
		this.navigateTo(this.getNavToPhoneCallUrl(assignmentId, scheduleId));
	}

	static getNavToPhoneCallUrl(assignmentId?: string, scheduleId?: string) {
		if (assignmentId && scheduleId) {
			return `/user/call-me/${assignmentId}/${scheduleId}`;
		} else {
			return "/user/call-me";
		}
	}

	static navToPhoneCallSession(sessionId: string) {
		this.navigateTo(`/user/call-me/session/${sessionId}`);
	}

	static navToOnboardingSession(userId: string, sessionId: string) {
		window.location.href = `/onboarding/user/${userId}/session/${sessionId}`;
	}

	static navToSessionSummary(assignmentId: string, userId: string, scheduleId: string) {
		this.navigateTo(`/user/assignment/${assignmentId}/user/${userId}/schedule/${scheduleId}`);
	}

	static navToQueuePage(assignmentId: string, userId: string, sessionId: string) {
		this.navigateTo(this.getNavToQueuePageUrl(assignmentId, userId, sessionId), true);
	}

	static getNavToQueuePageUrl(assignmentId: string, userId: string, sessionId: string) {
		return `/user/assignment/${assignmentId}/user/${userId}/session/${sessionId}/queue`;
	}

	static navToScheduleCall(assignedSessionId: string) {
		this.navigateTo(this.getNavToScheduleCallUrl(assignedSessionId), true);
	}

	static getNavToScheduleCallUrl(assignedSessionId: string) {
		return `/user/schedule-call/${assignedSessionId}`;
	}

	static navToUserEmailLaunch(token: string) {
		this.navigateTo(`/user/email-launch/${token}`, true);
	}

	static navToLogout() {
		this.navigateTo("/account/logout", true);
	}

	static navToNoAccess() {
		this.navigateTo("/no-access", true);
	}

	/**
	 * Navigates to the email confirmation page for a given assignment
	 * @param assignmentCode - The unique code identifying the assignment
	 * @param email - The email address to be confirmed
	 * @param redirectUrl - Optional URL to redirect to after confirmation
	 */
	static navToEmailConfirm(assignmentCode: string, email: string, redirectUrl?: string) {
		this.navigateTo(this.getNavToEmailConfirmUrl(assignmentCode, email, redirectUrl), true);
	}

	/**
	 * Generates the URL for the email confirmation page
	 * @param assignmentCode - The unique code identifying the assignment
	 * @param email - The email address to be confirmed
	 * @param redirectUrl - Optional URL to redirect to after confirmation
	 * @returns The formatted URL string for the email confirmation page
	 */
	static getNavToEmailConfirmUrl(assignmentCode: string, email: string, redirectUrl?: string) {
		const encodedRedirectUrl = encodeURIComponent(redirectUrl || "");
		return `/account/emailConfirm/${assignmentCode}?email=${encodeURIComponent(email)}${redirectUrl ? `&redirectUrl=${encodedRedirectUrl}` : ""}`;
	}

	static navToManageBooking(bookingId: string, seatId: string) {
		window.location.href = `/booking/${bookingId}/seat/${seatId}`;
	}

	static navToEditAssignmentByExperienceType(assignmentId: string, experienceType: ExperienceType) {
		const typeMap: Record<ExperienceType, string> = {
			[ExperienceType.INTERVIEW]: "interview",
			[ExperienceType.FRONTLINE]: "frontline",
			[ExperienceType.JOB_SEARCH_EMPLOYMENT]: "job-search",
			[ExperienceType.JOB_SEARCH_COOP]: "job-search",
			[ExperienceType.JOB_SEARCH_INTERNSHIP]: "job-search",
			[ExperienceType.JOB_SEARCH_OTHER]: "job-search",
			[ExperienceType.CO_OP_EXPERIENCE]: "reflection",
			[ExperienceType.INTERNSHIP]: "reflection",
			[ExperienceType.FIELD_PLACEMENT]: "reflection",
			[ExperienceType.CAPSTONE_PROJECT]: "reflection",
			[ExperienceType.WEEKLY_SPRINT]: "reflection",
			[ExperienceType.MONTHLY_PROJECT]: "reflection",
			[ExperienceType.COURSE]: "reflection",
			[ExperienceType.CONFERENCE]: "reflection",
			[ExperienceType.DEMO]: "reflection",
			[ExperienceType.CHECK_IN]: "check-in-sim"
		};
		const type = typeMap[experienceType];
		this.navToEditAssignment(type, assignmentId);
	}

	// interface NavigateOptions {
	// 	replace?: boolean;
	//   reloadDocument: true
	// 	state?: any;
	// 	preventScrollReset?: boolean;
	// 	relative?: RelativeRoutingType;
	// 	unstable_flushSync?: boolean;
	// 	unstable_viewTransition?: boolean;
	// }

	private static navigateTo(path: string, reload: boolean = false, options?: NavigateOptions): void {
		if (this.navigate) {
			if (reload) {
				window.location.href = path;
			} else {
				this.navigate(path, options);
			}
		} else {
			console.error("Navigate function is not set");
		}
	}
}
