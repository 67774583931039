import React, { useState } from "react";
import { ResumeView } from "../../types/ResumeView";
import { ResumeStyleProps } from "../UserPages/ResumeAssist";
// import { ResumeService } from "../../services/ResumeService";

// Types
interface ResumeTemplateProps {
	resume: ResumeView;
	style?: ResumeStyleProps;
	onUpdate?: (updatedResume: ResumeView) => void;
	template?: "single" | "minimal";
}

// Main Component
const ResumeTemplate: React.FC<ResumeTemplateProps> = ({ resume, style, onUpdate, template = "single" }) => {
	return (
		<div className="h-full overflow-y-auto custom-scrollbar">
			{/* <style>{templateStyles}</style>
			<TemplateComponent resume={resume} style={style} onUpdate={onUpdate} /> */}
		</div>
	);
};

export default ResumeTemplate;
