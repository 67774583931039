import React, { useEffect, useState } from "react";
import {
	XMarkIcon,
	ChatBubbleLeftIcon,
	PencilIcon,
	ArrowsPointingOutIcon,
	PlusIcon,
	DocumentTextIcon,
	ArrowsPointingInIcon
} from "@heroicons/react/24/outline";
import { ResumeView } from "../../types/ResumeView";
import { FeedbackNew, ResumeService, RewriteType } from "../../services/ResumeService";

interface RewriteSectionPopupProps {
	isOpen: boolean;
	onClose: () => void;
	onFeedback?: () => void;
	onRewrite?: () => void;
	onJobPost?: () => void;
	resume: ResumeView;
	content: string;
	setResume: (resume: ResumeView) => void;
}

enum Tabs {
	rewrite = "rewrite",
	feedback = "feedback",
	jobPost = "jobPost"
}

const RewriteSectionPopup: React.FC<RewriteSectionPopupProps> = ({
	isOpen,
	onClose,
	onFeedback,
	onRewrite,
	onJobPost,
	resume,
	content,
	setResume
}) => {
	const [updatedResume, setUpdatedResume] = useState(resume);
	const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.rewrite);
	const [activeRewrite, setActiveRewrite] = useState<RewriteType | null>(null);

	useEffect(() => {
		console.log("set resume: ", resume);
	}, [resume]);

	if (!isOpen) return null;
	if (!resume) return null;

	const rewriteSection = async (sectionName: string, rewriteType: RewriteType) => {
		setActiveRewrite(rewriteType);
		try {
			const updatedResume = await ResumeService.rewriteSection(sectionName, rewriteType, resume.id);
			const sectionData = updatedResume[sectionName as keyof ResumeView];
			console.log("section data: ", sectionData);
			setUpdatedResume(updatedResume);
			setResume({
				...resume,
				[sectionName]: sectionData
			});
		} finally {
			setActiveRewrite(null);
		}
	};

	const formatContent = (section: string, data: any) => {
		// Handle array-based sections with dates
		if (["experience", "education", "extraCurricular", "projects", "certifications"].includes(section) && Array.isArray(data)) {
			return (
				<div className="space-y-4">
					{data.map((item: any, index: number) => (
						<div key={index} className="border-b border-gray-200 pb-3 last:border-b-0">
							<div className="flex justify-between items-start mb-1">
								<h3 className="font-medium text-gray-900 text-sm">
									{item.jobTitle || item.degree || item.activity || item.projectName || item.certificationName}
								</h3>
								<span className="text-xs text-gray-500">
									{item.startMonth} {item.startYear} - {item.endMonth} {item.endYear}
								</span>
							</div>
							<div className="text-xs text-gray-700 mb-1">
								{item.companyName || item.school || item.orgName || item.issuingOrganization || ""}
								{item.city && item.stateOrProv ? ` • ${item.city}, ${item.stateOrProv}` : ""}
							</div>
							{item.program && <div className="text-xs text-gray-600">{item.program}</div>}
							{item.description && <div className="text-xs text-gray-600 mt-1">{item.description}</div>}
							{(item.roles || item.notes || item.tasks) && (
								<ul className="list-disc list-inside text-xs text-gray-600 space-y-1 ml-4 mt-2">
									{(item.roles || item.notes || item.tasks)?.map((text: string, idx: number) => (
										<li key={idx}>{text}</li>
									))}
								</ul>
							)}
						</div>
					))}
				</div>
			);
		}

		// Handle contact information
		if (section === "contact" && typeof data === "object") {
			return (
				<div className="space-y-2">
					{Object.entries(data).map(([key, value]) => (
						<div key={key} className="flex text-xs">
							<span className="font-medium text-gray-700 w-24">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
							<span className="text-gray-600">{value as string}</span>
						</div>
					))}
				</div>
			);
		}

		// Handle skills section
		if (section === "skills" && typeof data === "object") {
			return (
				<div className="space-y-4">
					{Object.entries(data).map(([category, skills]) => (
						<div key={category}>
							<h3 className="font-medium text-gray-900 text-sm mb-2">{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
							<div className="flex flex-wrap gap-1">
								{(skills as string[]).map((skill, idx) => (
									<span key={idx} className="bg-gray-100 px-2 py-0.5 rounded text-xs text-gray-700">
										{skill}
									</span>
								))}
							</div>
						</div>
					))}
				</div>
			);
		}

		// Handle simple array sections (hobbies, interests)
		if (Array.isArray(data)) {
			return (
				<div className="flex flex-wrap gap-1">
					{data.map((item, idx) => (
						<span key={idx} className="bg-gray-100 px-2 py-0.5 rounded text-xs text-gray-700">
							{item}
						</span>
					))}
				</div>
			);
		}

		// Handle simple string sections (summary, title, jobTitle, jobDescription)
		if (typeof data === "string") {
			return <p className="text-xs text-gray-700 whitespace-pre-wrap">{data}</p>;
		}

		// Default fallback for any other data types
		return <p className="text-xs text-gray-700 whitespace-pre-wrap">{JSON.stringify(data, null, 2)}</p>;
	};

	return (
		<div className="bg-white rounded-lg shadow-lg p-4 w-full">
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-lg font-semibold text-gray-800 capitalize">{content}</h2>
				<button onClick={onClose} className="text-gray-500 hover:text-gray-700 focus:outline-none">
					<XMarkIcon className="h-5 w-5" />
				</button>
			</div>

			<div className="mb-4">
				<div className="flex gap-2 mb-4">
					<button
						onClick={() => {
							setSelectedTab(Tabs.feedback);
							onFeedback && onFeedback();
						}}
						className={`inline-flex items-center px-3 py-1.5 border rounded-md text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 ${
							selectedTab === Tabs.feedback
								? "border-transparent bg-[#00a9af] text-white"
								: "border-gray-300 text-gray-700 bg-white hover:bg-gray-50"
						}`}
					>
						<ChatBubbleLeftIcon className="mr-1.5 h-4 w-4" />
						Feedback
					</button>
					<button
						onClick={() => {
							setSelectedTab(Tabs.rewrite);
							onRewrite && onRewrite();
						}}
						className={`inline-flex items-center px-3 py-1.5 border rounded-md text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 ${
							selectedTab === Tabs.rewrite
								? "border-transparent bg-[#00a9af] text-white"
								: "border-gray-300 text-gray-700 bg-white hover:bg-gray-50"
						}`}
					>
						<PencilIcon className="mr-1.5 h-4 w-4" />
						Rewrite
					</button>
					<button
						onClick={() => {
							setSelectedTab(Tabs.jobPost);
							onJobPost && onJobPost();
						}}
						className={`inline-flex items-center px-3 py-1.5 border rounded-md text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 ${
							selectedTab === Tabs.jobPost
								? "border-transparent bg-[#00a9af] text-white"
								: "border-gray-300 text-gray-700 bg-white hover:bg-gray-50"
						}`}
					>
						<ArrowsPointingOutIcon className="mr-1.5 h-4 w-4" />
						Job Post
					</button>
				</div>
				{selectedTab === Tabs.rewrite && (
					<>
						<div className="bg-gray-50 rounded-lg p-4">
							{formatContent(content, updatedResume[content as keyof ResumeView] || resume[content as keyof ResumeView])}
						</div>

						<div className="mt-3 text-xs text-gray-500 italic">Note: Rewrites are generated by AI and require human review.</div>
					</>
				)}
				{selectedTab === Tabs.feedback && (
					<>
						{content === "overall" ? (
							<>
								<h2 className="text-lg font-semibold text-gray-800">Positive Feedback</h2>
								<h4 className="text-sm text-gray-500 whitespace-pre-wrap">{resume.feedbackNew.overall.positiveFeedback}</h4>
								<h2 className="text-lg font-semibold text-gray-800 mt-4">Areas to Improve</h2>
								<h4 className="text-sm text-gray-500 whitespace-pre-wrap">{resume.feedbackNew.overall.areasToImprove}</h4>
							</>
						) : (
							<>
								<h2 className="text-lg font-semibold text-gray-800">Feedback</h2>
								<h4 className="text-sm text-gray-500 whitespace-pre-wrap">
									{resume.feedbackNew[content as keyof Omit<FeedbackNew, "overall">].feedback}
								</h4>
							</>
						)}
					</>
				)}
				{selectedTab === Tabs.jobPost && (
					<>
						<h2 className="text-lg font-semibold text-gray-800">{resume.jobTitle}</h2>
						<h4 className="text-sm text-gray-500">{resume.jobDescription}</h4>
					</>
				)}
				{selectedTab === Tabs.rewrite && (
					<div className="mt-3 flex gap-2">
						<button
							onClick={() => rewriteSection(content, RewriteType.formalize)}
							disabled={activeRewrite !== null}
							className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 disabled:opacity-50"
						>
							{activeRewrite === RewriteType.formalize ? (
								<>
									<svg className="animate-spin h-4 w-4 mr-1.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
									<span>Formalizing...</span>
								</>
							) : (
								<>
									<PlusIcon className="mr-1.5 h-4 w-4" />
									<span>Formalize</span>
								</>
							)}
						</button>
						<button
							onClick={() => rewriteSection(content, RewriteType.elaborate)}
							disabled={activeRewrite !== null}
							className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 disabled:opacity-50"
						>
							{activeRewrite === RewriteType.elaborate ? (
								<>
									<svg className="animate-spin h-4 w-4 mr-1.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
									<span>Elaborating...</span>
								</>
							) : (
								<>
									<DocumentTextIcon className="mr-1.5 h-4 w-4" />
									<span>Elaborate</span>
								</>
							)}
						</button>
						<button
							onClick={() => rewriteSection(content, RewriteType.shorten)}
							disabled={activeRewrite !== null}
							className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 disabled:opacity-50"
						>
							{activeRewrite === RewriteType.shorten ? (
								<>
									<svg className="animate-spin h-4 w-4 mr-1.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
									<span>Shortening...</span>
								</>
							) : (
								<>
									<ArrowsPointingInIcon className="mr-1.5 h-4 w-4" />
									<span>Shorten</span>
								</>
							)}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default RewriteSectionPopup;
