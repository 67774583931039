import React, { useState, useEffect } from "react";
import BackButton from "../Utility/BackButton";
import { Button } from "../shadcn-ui/Button";
import { Checkbox } from "../shadcn-ui/Checkbox";
import CustomTooltip from "../Utility/CustomTooltip";
import { Textarea } from "../shadcn-ui/Textarea";
import { Input } from "../shadcn-ui/Input";
import { ClientService } from "../../services/ClientService";
import Spinner from "../Utility/Spinner";
import { useToast } from "../../hooks/useToast";

const clampValue = (value: string, min: number, max: number): string => {
	const numValue = parseInt(value) || 0;
	return Math.min(Math.max(numValue, min), max).toString();
};

interface ClientSettings {
	allowEmailReports: boolean;
	allowUserIdentification: boolean;
	allowTranscript: boolean;
	allowAudio: boolean;
	allowNotifyAvailabilityWindow: boolean;
	allowNotify24HoursBefore: boolean;
	allowNotifyDueDate: boolean;
	audioStorageLength: string;
	assignmentCallTimeLimit: string;
	selfServeCallTimeLimit: string;
	highPriorityDef: string;
	mediumPriorityDef: string;
	lowPriorityDef: string;
	highPriorityContactInfo: string;
	jobSearchResources: string;
}

const AdminSettings: React.FC = () => {
	const { toast } = useToast();
	const [isLoading, setIsLoading] = useState(true);
	const [initialSettings, setInitialSettings] = useState<ClientSettings | null>(null);
	const [allowEmailReports, setAllowEmailReports] = useState(true);
	const [allowUserIdentification, setAllowUserIdentification] = useState(true);
	const [allowTranscript, setAllowTranscript] = useState(true);
	const [allowAudio, setAllowAudio] = useState(true);
	const [allowNotifyAvailabilityWindow, setAllowNotifyAvailabilityWindow] = useState(true);
	const [allowNotify24HoursBefore, setAllowNotify24HoursBefore] = useState(true);
	const [allowNotifyDueDate, setAllowNotifyDueDate] = useState(true);
	const [audioStorageLength, setAudioStorageLength] = useState("365");
	const [assignmentCallTimeLimit, setAssignmentCallTimeLimit] = useState("60");
	const [selfServeCallTimeLimit, setSelfServeCallTimeLimit] = useState("60");
	const [highPriorityDef, setHighPriorityDef] = useState("");
	const [mediumPriorityDef, setMediumPriorityDef] = useState("");
	const [lowPriorityDef, setLowPriorityDef] = useState("");
	const [highPriorityContactInfo, setHighPriorityContactInfo] = useState("");
	const [jobSearchResources, setJobSearchResources] = useState("");

	// Helper function to get current settings
	const getCurrentSettings = (): ClientSettings => ({
		allowEmailReports,
		allowUserIdentification,
		allowTranscript,
		allowAudio,
		allowNotifyAvailabilityWindow,
		allowNotify24HoursBefore,
		allowNotifyDueDate,
		audioStorageLength,
		assignmentCallTimeLimit,
		selfServeCallTimeLimit,
		highPriorityDef,
		mediumPriorityDef,
		lowPriorityDef,
		highPriorityContactInfo,
		jobSearchResources
	});

	// Helper function to check if settings have changed
	const hasSettingsChanged = (): boolean => {
		if (!initialSettings) return false;
		const currentSettings = getCurrentSettings();
		return Object.keys(initialSettings).some(
			(key) => initialSettings[key as keyof ClientSettings] !== currentSettings[key as keyof ClientSettings]
		);
	};

	useEffect(() => {
		setIsLoading(true);
		ClientService.getClient()
			.then((client) => {
				const settings = {
					allowEmailReports: client.allowEmailReports,
					allowUserIdentification: client.allowUserIdentification,
					allowTranscript: client.allowTranscript,
					allowAudio: client.allowAudio,
					allowNotifyAvailabilityWindow: client.allowNotifyAvailabilityWindow,
					allowNotify24HoursBefore: client.allowNotify24HoursBefore,
					allowNotifyDueDate: client.allowNotifyDueDate,
					audioStorageLength: client.audioStorageLength.toString(),
					assignmentCallTimeLimit: client.assignmentCallTimeLimit.toString(),
					selfServeCallTimeLimit: client.selfServeCallTimeLimit.toString(),
					highPriorityDef: client.highPriorityDef,
					mediumPriorityDef: client.mediumPriorityDef,
					lowPriorityDef: client.lowPriorityDef,
					highPriorityContactInfo: client.highPriorityContactInfo,
					jobSearchResources: client.jobSearchResources
				};

				// Set initial settings
				setInitialSettings(settings);

				// Set all state values
				setAllowEmailReports(settings.allowEmailReports);
				setAllowUserIdentification(settings.allowUserIdentification);
				setAllowTranscript(settings.allowTranscript);
				setAllowAudio(settings.allowAudio);
				setAllowNotifyAvailabilityWindow(settings.allowNotifyAvailabilityWindow);
				setAllowNotify24HoursBefore(settings.allowNotify24HoursBefore);
				setAllowNotifyDueDate(settings.allowNotifyDueDate);
				setAudioStorageLength(settings.audioStorageLength);
				setAssignmentCallTimeLimit(settings.assignmentCallTimeLimit);
				setSelfServeCallTimeLimit(settings.selfServeCallTimeLimit);
				setHighPriorityDef(settings.highPriorityDef);
				setMediumPriorityDef(settings.mediumPriorityDef);
				setLowPriorityDef(settings.lowPriorityDef);
				setHighPriorityContactInfo(settings.highPriorityContactInfo);
				setJobSearchResources(settings.jobSearchResources);
			})
			.catch((error) => {
				console.error("Failed to load client settings:", error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const handleSave = async () => {
		try {
			const settings = {
				allowEmailReports,
				allowUserIdentification,
				allowTranscript,
				allowAudio,
				allowNotifyAvailabilityWindow,
				allowNotify24HoursBefore,
				allowNotifyDueDate,
				audioStorageLength: parseInt(audioStorageLength),
				assignmentCallTimeLimit: parseInt(assignmentCallTimeLimit),
				selfServeCallTimeLimit: parseInt(selfServeCallTimeLimit),
				highPriorityDef,
				mediumPriorityDef,
				lowPriorityDef,
				highPriorityContactInfo,
				jobSearchResources
			};

			await ClientService.updateClient(settings);

			setInitialSettings(getCurrentSettings());

			toast({
				title: "Settings saved",
				description: "Your changes have been successfully saved.",
				variant: "default"
			});
		} catch (error) {
			console.error("Failed to save settings:", error);
			toast({
				title: "Error",
				description: "Failed to save settings. Please try again.",
				variant: "destructive"
			});
		}
	};

	const handleStorageChange = (value: string, setter: (value: string) => void) => {
		const sanitizedValue = value.replace(/\D/g, ""); // Remove non-digits
		setter(sanitizedValue);
	};

	const handleStorageBlur = (value: string, setter: (value: string) => void) => {
		const clampedValue = clampValue(value, 1, 365);
		setter(clampedValue);
	};

	const handleCallTimeLimitChange = (value: string, setter: (value: string) => void) => {
		const sanitizedValue = value.replace(/\D/g, ""); // Remove non-digits
		setter(sanitizedValue);
	};

	const handleCallTimeLimitBlur = (value: string, setter: (value: string) => void) => {
		const clampedValue = clampValue(value, 30, 1000);
		setter(clampedValue);
		if (parseInt(value) < 30) {
			toast({
				title: "Invalid Duration",
				description: "Call time limit cannot be less than 30 minutes",
				variant: "destructive"
			});
		}
		if (parseInt(value) > 1000) {
			toast({
				title: "Invalid Duration",
				description: "Call time limit cannot be greater than 1000 minutes",
				variant: "destructive"
			});
		}
	};

	return (
		<div className="h-full pb-16">
			<BackButton />
			<div className="flex justify-between items-center mb-6">
				<div className="flex items-center">
					<h2 className="text-2xl md:text-3xl font-bold text-[#16013e] mr-3">Admin Settings</h2>
				</div>
			</div>

			{isLoading ? (
				<div className="h-full flex items-center justify-center">
					<Spinner />
				</div>
			) : (
				<div className="space-y-8">
					<Panel title="Email Report Settings">
						<p className="text-sm text-gray-600 mb-6">
							These are reports sent to the assignment creator containing an aggregated summary of student activity for each assigned
							call.
						</p>
						<label className="flex items-center">
							<input
								type="radio"
								name="allowEmailReports"
								checked={allowEmailReports}
								onChange={() => setAllowEmailReports(true)}
								className="form-radio h-4 w-4 text-[#00a9af] focus:ring-[#00a9af]"
							/>
							<span className="ml-2 text-sm text-gray-700">Allow email reports</span>
						</label>
						<label className="flex items-center">
							<input
								type="radio"
								name="allowEmailReports"
								checked={!allowEmailReports}
								onChange={() => setAllowEmailReports(false)}
								className="form-radio h-4 w-4 text-[#00a9af] focus:ring-[#00a9af]"
							/>
							<span className="ml-2 text-sm text-gray-700">Do not allow email reports</span>
						</label>
					</Panel>

					<Panel title="Summary Content Permissions">
						<p className="text-sm text-gray-600 mb-6">
							Configure which <span className="font-medium">student data</span> assignment creators are allowed to enable/disable.
						</p>
						<div className="space-y-4">
							<label className="flex items-center">
								<Checkbox
									id="allowUserIdentification"
									checked={allowUserIdentification}
									onCheckedChange={(checked: boolean) => setAllowUserIdentification(checked)}
								/>
								<span className="ml-2 text-sm text-gray-700">Allow inclusion of student Name and Email</span>
							</label>
							<label className="flex items-center">
								<Checkbox
									id="allowTranscript"
									checked={allowTranscript}
									onCheckedChange={(checked: boolean) => setAllowTranscript(checked)}
								/>
								<span className="ml-2 text-sm text-gray-700">Allow inclusion of student transcript</span>
							</label>
							<label className="flex items-center">
								<Checkbox id="allowAudio" checked={allowAudio} onCheckedChange={(checked: boolean) => setAllowAudio(checked)} />
								<span className="ml-2 text-sm text-gray-700">Allow inclusion of student audio recording</span>
							</label>
						</div>
						<div className="mt-4 p-3 bg-gray-50 rounded-md">
							<p className="text-sm text-gray-600">
								<span className="font-medium">Note:</span> Unchecking any option here will disable it completely when creating
								assignments.
							</p>
						</div>
					</Panel>

					<Panel title="Student Notification Settings">
						<p className="text-sm text-gray-600 mb-6">
							Configure which <span className="font-medium">email notifications</span> assignment creators are allowed to
							enable/disable.
						</p>

						<div className="space-y-6">
							<div className="flex items-center">
								<label className="flex items-center">
									<Checkbox
										id="allowNotifyAvailabilityWindow"
										checked={allowNotifyAvailabilityWindow}
										onCheckedChange={(checked: boolean) => setAllowNotifyAvailabilityWindow(checked)}
									/>
									<span className="ml-2 text-sm text-gray-700">Allow call availability window open email</span>
									<CustomTooltip
										content="This email is sent to students when the availability window for the call opens so that students know when they can start."
										image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionCallNowAvailablePNG.JPG"
									/>
								</label>
							</div>

							<div className="flex items-center">
								<label className="flex items-center">
									<Checkbox
										id="allowNotify24HoursBefore"
										checked={allowNotify24HoursBefore}
										onCheckedChange={(checked: boolean) => setAllowNotify24HoursBefore(checked)}
									/>
									<span className="ml-2 text-sm text-gray-700">Allow 24-hour reminder email</span>
									<CustomTooltip
										content="This email is sent to students 24 hours before the due date of the assignment to remind them that the assignment is due soon."
										image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionDueTomorrowPNG.JPG"
									/>
								</label>
							</div>

							<div className="flex items-center">
								<label className="flex items-center">
									<Checkbox
										id="allowNotifyDueDate"
										checked={allowNotifyDueDate}
										onCheckedChange={(checked: boolean) => setAllowNotifyDueDate(checked)}
									/>
									<span className="ml-2 text-sm text-gray-700">Allow due date reminder email</span>
									<CustomTooltip
										content="This email is sent to students the day the assignment is due to remind them that the assignment is due soon."
										image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionDueTodayPNG.JPG"
									/>
								</label>
							</div>
						</div>
						<div className="mt-4 p-3 bg-gray-50 rounded-md">
							<p className="text-sm text-gray-600">
								<span className="font-medium">Note:</span> Unchecking any option here will disable it completely when creating
								assignments.
							</p>
						</div>
					</Panel>

					<Panel title="Data Storage Limits">
						<div className="space-y-4">
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">Audio File Storage Length (days)</label>
								<Input
									type="number"
									value={audioStorageLength}
									onChange={(e) => handleStorageChange(e.target.value, setAudioStorageLength)}
									className="w-32"
									min="14"
									onBlur={() => handleStorageBlur(audioStorageLength, setAudioStorageLength)}
								/>
							</div>
						</div>
					</Panel>

					<Panel title="Call Time Limits">
						<div className="space-y-4">
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">Call Time Limit (minutes)</label>
								<Input
									type="number"
									value={assignmentCallTimeLimit}
									onChange={(e) => handleCallTimeLimitChange(e.target.value, setAssignmentCallTimeLimit)}
									className="w-32"
									min="30"
									onBlur={() => handleCallTimeLimitBlur(assignmentCallTimeLimit, setAssignmentCallTimeLimit)}
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">
									Per Student Call Time Limit for Self-Serve Mode (minutes/year)
								</label>
								<Input
									type="number"
									value={selfServeCallTimeLimit}
									onChange={(e) => handleCallTimeLimitChange(e.target.value, setSelfServeCallTimeLimit)}
									className="w-32"
									min="30"
									onBlur={() => handleCallTimeLimitBlur(selfServeCallTimeLimit, setSelfServeCallTimeLimit)}
								/>
							</div>
						</div>
					</Panel>

					<Panel title="Priority Comments Settings">
						<p className="text-sm text-gray-600 mb-6">
							These definitions will be used to improve the detection of priority comments by students during their calls with the AI
							assistants. Priority comments will be highlighted in the assignment summary reports.
						</p>
						<div className="space-y-4">
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">High Priority Definition</label>
								<Textarea
									value={highPriorityDef}
									onChange={(e) => setHighPriorityDef(e.target.value)}
									placeholder="Define what constitutes a high priority comment..."
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">High Priority Contact Instructions</label>
								<Textarea
									value={highPriorityContactInfo}
									onChange={(e) => setHighPriorityContactInfo(e.target.value)}
									placeholder="Provide contact instructions for high priority comments..."
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">Medium Priority Definition</label>
								<Textarea
									value={mediumPriorityDef}
									onChange={(e) => setMediumPriorityDef(e.target.value)}
									placeholder="Define what constitutes a medium priority comment..."
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">Low Priority Definition</label>
								<Textarea
									value={lowPriorityDef}
									onChange={(e) => setLowPriorityDef(e.target.value)}
									placeholder="Define what constitutes a low priority comment..."
								/>
							</div>
						</div>
					</Panel>

					<Panel title="Job Search Resources">
						<div>
							<p className="text-sm text-gray-600 mb-6">
								Provide information about available resources to help students with their co-op/internship search (e.g., career
								services, job boards, resume workshops).
							</p>
							<p className="text-sm text-gray-600 mb-6">
								This information will be used to guide the AI assistant during job search calls with students.
							</p>
							<Textarea
								value={jobSearchResources}
								onChange={(e) => setJobSearchResources(e.target.value)}
								placeholder="List available job search resources and support services..."
								className="min-h-[150px]"
							/>
						</div>
					</Panel>

					{/* Sticky footer */}
					<div className="fixed bottom-0 left-0 right-0 py-6 bg-white border-t border-gray-200">
						<div className="flex items-center mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
							<Button
								onClick={handleSave}
								disabled={!hasSettingsChanged()}
								className={!hasSettingsChanged() ? "opacity-50 cursor-not-allowed" : ""}
							>
								Save Settings
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const Panel: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
	<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
		<h3 className="text-lg font-semibold text-gray-800 mb-6">{title}</h3>
		<div className="space-y-4">{children}</div>
	</div>
);

export default AdminSettings;
