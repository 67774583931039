import axios from "axios";
import { plainToInstance } from "class-transformer";
import { LogAxiosError } from "./AxiosUtility";
import { ClientView } from "../types/ClientView";

interface ClientSettings {
	allowEmailReports: boolean;
	allowUserIdentification: boolean;
	allowTranscript: boolean;
	allowAudio: boolean;
	allowNotifyAvailabilityWindow: boolean;
	allowNotify24HoursBefore: boolean;
	allowNotifyDueDate: boolean;
	audioStorageLength: number;
	assignmentCallTimeLimit: number;
	selfServeCallTimeLimit: number;
	highPriorityDef: string;
	mediumPriorityDef: string;
	lowPriorityDef: string;
	highPriorityContactInfo: string;
	jobSearchResources: string;
}

export class ClientService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/clients`;

	static async getClient(): Promise<ClientView> {
		try {
			const response = await axios.get(`${this.baseUrl}/`);
			return plainToInstance(ClientView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get client`);
			throw new Error(errorMessage);
		}
	}

	static async updateClient(settings: ClientSettings): Promise<ClientView> {
		try {
			const response = await axios.put(`${this.baseUrl}/`, settings);
			return plainToInstance(ClientView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update client settings`);
			throw new Error(errorMessage);
		}
	}
}
