import React, { useEffect } from "react";
import { ProfileView, Experience, Education, ExtraCurricular, Project, Certification, Skills } from "../../types/ProfileView";
import { Input } from "../shadcn-ui/Input";
import { Textarea } from "../shadcn-ui/Textarea";
import { Button } from "../shadcn-ui/Button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";

interface ProfileEditFormProps {
	editingSection: string;
	editedData: any;
	setEditedData: (data: any) => void;
}

const RequiredLabel: React.FC<{ text: string }> = ({ text }) => (
	<label className="text-sm font-medium flex items-center gap-1">
		{text}
		<span className="text-red-500">*</span>
	</label>
);

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => (currentYear - i).toString());

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({ editingSection, editedData, setEditedData }) => {
	const handleArrayChange = (index: number, value: string, field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: editedData[parentField]?.[field]?.map((item: string, i: number) => (i === index ? value : item)) || []
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: editedData[field]?.map((item: string, i: number) => (i === index ? value : item)) || []
			});
		}
	};

	const handleObjectArrayChange = (index: number, field: string, value: any) => {
		setEditedData(editedData?.map((item: any, i: number) => (i === index ? { ...item, [field]: value } : item)) || []);
	};

	const addObjectArrayItem = () => {
		const newItem = { id: Date.now() }; // Temporary ID for new items
		setEditedData([...(editedData || []), newItem]);
	};

	const removeObjectArrayItem = (index: number) => {
		// Create a new array without the removed item
		const updatedData = editedData?.filter((_: any, i: number) => i !== index) || [];
		// Update the edited data immediately
		setEditedData(updatedData);
	};

	const addArrayItem = (field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: [...(editedData[parentField]?.[field] || []), ""]
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: [...(editedData[field] || []), ""]
			});
		}
	};

	const removeArrayItem = (index: number, field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: editedData[parentField]?.[field]?.filter((_: string, i: number) => i !== index) || []
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: editedData[field]?.filter((_: string, i: number) => i !== index) || []
			});
		}
	};

	const renderArrayInput = (field: string, label: string, parentField?: string) => {
		let items: string[] = [];
		if (parentField) {
			// For skills section, editedData is already the parent object
			if (editingSection === "skills") {
				items = editedData?.[field] || [];
				if (!editedData?.[field]) {
					setEditedData({
						...editedData,
						[field]: []
					});
				}
			} else {
				// Initialize the parent field if it doesn't exist
				if (!editedData?.[parentField]) {
					setEditedData({
						...editedData,
						[parentField]: {}
					});
				}
				// Initialize the array if it doesn't exist
				items = editedData?.[parentField]?.[field] || [];
				if (!editedData?.[parentField]?.[field]) {
					setEditedData({
						...editedData,
						[parentField]: {
							...editedData[parentField],
							[field]: []
						}
					});
				}
			}
		} else {
			// For hobbies and interests, initialize as array if needed
			if (editingSection === "hobbies" || editingSection === "interests") {
				items = editedData || [];
				if (!editedData) {
					setEditedData([]);
				}
			} else {
				// Initialize the array if it doesn't exist
				items = editedData?.[field] || [];
				if (!editedData?.[field]) {
					setEditedData({
						...editedData,
						[field]: []
					});
				}
			}
		}

		return (
			<div className="space-y-4">
				<div className="block">
					<div className="mb-2">
						<RequiredLabel text={label} />
					</div>
					<div className="space-y-2">
						{items.map((item: string, index: number) => (
							<div key={index} className="flex gap-2">
								<Input
									value={item}
									onChange={(e) => {
										if (editingSection === "skills") {
											// For skills, update the array directly in editedData
											const newItems = [...items];
											newItems[index] = e.target.value;
											setEditedData({
												...editedData,
												[field]: newItems
											});
										} else if (editingSection === "hobbies" || editingSection === "interests") {
											// For hobbies and interests, update the array directly
											const newItems = [...items];
											newItems[index] = e.target.value;
											setEditedData(newItems);
										} else {
											handleArrayChange(index, e.target.value, field, parentField);
										}
									}}
									className="flex-1"
									required
								/>
								<Button
									variant="outline"
									size="sm"
									onClick={() => {
										if (editingSection === "skills") {
											// For skills, remove directly from editedData
											const newItems = items.filter((_, i) => i !== index);
											setEditedData({
												...editedData,
												[field]: newItems
											});
										} else if (editingSection === "hobbies" || editingSection === "interests") {
											// For hobbies and interests, remove directly from array
											const newItems = items.filter((_, i) => i !== index);
											setEditedData(newItems);
										} else {
											removeArrayItem(index, field, parentField);
										}
									}}
								>
									Remove
								</Button>
							</div>
						))}
					</div>
					<div className="mt-2">
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								if (editingSection === "skills") {
									// For skills, add directly to editedData
									setEditedData({
										...editedData,
										[field]: [...items, ""]
									});
								} else if (editingSection === "hobbies" || editingSection === "interests") {
									// For hobbies and interests, add directly to array
									setEditedData([...items, ""]);
								} else {
									addArrayItem(field, parentField);
								}
							}}
						>
							Add {label}
						</Button>
					</div>
				</div>
			</div>
		);
	};

	switch (editingSection) {
		case "summary":
			return (
				<div className="space-y-4">
					<div>
						<RequiredLabel text="Summary" />
						<Textarea
							value={editedData || ""}
							onChange={(e) => setEditedData(e.target.value)}
							placeholder="Add a professional summary"
							className="mt-1 p-2"
							rows={10}
							required
						/>
					</div>
				</div>
			);

		case "contact":
			// Ensure editedData is initialized as an object with default values
			if (!editedData || typeof editedData !== "object") {
				setEditedData({
					firstName: "",
					lastName: "",
					email: "",
					phone: "",
					address: "",
					linkedinURL: "",
					website: ""
				});
				return null; // Return null for initial render until state is updated
			}

			return (
				<div className="space-y-4">
					<div className="grid grid-cols-2 gap-4">
						<div>
							<RequiredLabel text="First Name" />
							<Input
								value={editedData?.firstName || ""}
								onChange={(e) => setEditedData({ ...editedData, firstName: e.target.value })}
								placeholder="First Name"
								className="mt-1"
								required
							/>
						</div>
						<div>
							<RequiredLabel text="Last Name" />
							<Input
								value={editedData?.lastName || ""}
								onChange={(e) => setEditedData({ ...editedData, lastName: e.target.value })}
								placeholder="Last Name"
								className="mt-1"
								required
							/>
						</div>
					</div>
					<div>
						<RequiredLabel text="Email" />
						<Input
							value={editedData?.email || ""}
							onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
							placeholder="Email"
							type="email"
							className="mt-1"
							required
						/>
					</div>
					<div>
						<RequiredLabel text="Phone" />
						<Input
							value={editedData?.phone || ""}
							onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
							placeholder="Phone"
							className="mt-1"
							required
						/>
					</div>
					<div>
						<label className="text-sm font-medium">Address</label>
						<Input
							value={editedData?.address || ""}
							onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}
							placeholder="Address"
							className="mt-1"
						/>
					</div>
					<div>
						<label className="text-sm font-medium">LinkedIn URL</label>
						<Input
							value={editedData?.linkedinURL || ""}
							onChange={(e) => setEditedData({ ...editedData, linkedinURL: e.target.value })}
							placeholder="LinkedIn URL"
							className="mt-1"
						/>
					</div>
					<div>
						<label className="text-sm font-medium">Website</label>
						<Input
							value={editedData?.website || ""}
							onChange={(e) => setEditedData({ ...editedData, website: e.target.value })}
							placeholder="Website"
							className="mt-1"
						/>
					</div>
				</div>
			);

		case "experience":
			return (
				<div className="space-y-8">
					{(editedData || []).map((exp: Experience, index: number) => (
						<div key={exp.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">Experience {index + 1}</h3>
								<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
									Remove Experience
								</Button>
							</div>
							<div>
								<RequiredLabel text="Job Title" />
								<Input
									value={exp.jobTitle || ""}
									onChange={(e) => handleObjectArrayChange(index, "jobTitle", e.target.value)}
									placeholder="Job Title"
									className="mt-1"
									required
								/>
							</div>
							<div>
								<RequiredLabel text="Company Name" />
								<Input
									value={exp.companyName || ""}
									onChange={(e) => handleObjectArrayChange(index, "companyName", e.target.value)}
									placeholder="Company Name"
									className="mt-1"
									required
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="City" />
									<Input
										value={exp.city || ""}
										onChange={(e) => handleObjectArrayChange(index, "city", e.target.value)}
										placeholder="City"
										className="mt-1"
										required
									/>
								</div>
								<div>
									<RequiredLabel text="State/Province" />
									<Input
										value={exp.stateOrProv || ""}
										onChange={(e) => handleObjectArrayChange(index, "stateOrProv", e.target.value)}
										placeholder="State/Province"
										className="mt-1"
										required
									/>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="Start Month" />
									<Select
										value={exp.startMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text="Start Year" />
									<Select
										value={exp.startYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select year" />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="End Month" />
									<Select
										value={exp.endMonth || ""}
										onValueChange={(value) => {
											if (value === "Present") {
												handleObjectArrayChange(index, "endYear", "");
											}
											handleObjectArrayChange(index, "endMonth", value);
										}}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">Present</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								{exp.endMonth && exp.endMonth !== "Present" && (
									<div>
										<RequiredLabel text="End Year" />
										<Select
											value={exp.endYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											required
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select year" />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								)}
							</div>
							<div className="space-y-2">
								<RequiredLabel text="Roles" />
								{(exp.roles || []).map((role: string, roleIndex: number) => (
									<div key={roleIndex} className="flex gap-2">
										<Input
											value={role}
											onChange={(e) => {
												const newRoles = [...(exp.roles || [])];
												newRoles[roleIndex] = e.target.value;
												handleObjectArrayChange(index, "roles", newRoles);
											}}
											className="flex-1"
											required
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newRoles = exp.roles.filter((_, i) => i !== roleIndex);
												handleObjectArrayChange(index, "roles", newRoles);
											}}
										>
											Remove
										</Button>
									</div>
								))}
								<Button
									className="ml-2"
									variant="outline"
									size="sm"
									onClick={() => {
										const newRoles = [...(exp.roles || []), ""];
										handleObjectArrayChange(index, "roles", newRoles);
									}}
								>
									Add Role
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem}>
						Add Experience
					</Button>
				</div>
			);

		case "education":
			return (
				<div className="space-y-8">
					{(editedData || []).map((edu: Education, index: number) => (
						<div key={edu.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">Education {index + 1}</h3>
								<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
									Remove Education
								</Button>
							</div>
							<div>
								<RequiredLabel text="School" />
								<Input
									value={edu.school || ""}
									onChange={(e) => handleObjectArrayChange(index, "school", e.target.value)}
									placeholder="School Name"
									className="mt-1"
									required
								/>
							</div>
							<div>
								<RequiredLabel text="Degree" />
								<Input
									value={edu.degree || ""}
									onChange={(e) => handleObjectArrayChange(index, "degree", e.target.value)}
									placeholder="Degree"
									className="mt-1"
									required
								/>
							</div>
							<div>
								<RequiredLabel text="Program" />
								<Input
									value={edu.program || ""}
									onChange={(e) => handleObjectArrayChange(index, "program", e.target.value)}
									placeholder="Program"
									className="mt-1"
									required
								/>
							</div>
							<div>
								<RequiredLabel text="Graduating Year" />
								<Select
									value={edu.graduatingYear || ""}
									onValueChange={(value) => handleObjectArrayChange(index, "graduatingYear", value)}
									required
								>
									<SelectTrigger className="w-[180px] bg-white">
										<SelectValue placeholder="Select year" />
									</SelectTrigger>
									<SelectContent>
										{years.map((year) => (
											<SelectItem key={year} value={year}>
												{year}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							<div className="space-y-2">
								<label className="text-sm font-medium">Notes</label>
								{(edu.notes || []).map((note: string, noteIndex: number) => (
									<div key={noteIndex} className="flex gap-2">
										<Input
											value={note}
											onChange={(e) => {
												const newNotes = [...(edu.notes || [])];
												newNotes[noteIndex] = e.target.value;
												handleObjectArrayChange(index, "notes", newNotes);
											}}
											className="flex-1"
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newNotes = edu.notes.filter((_, i) => i !== noteIndex);
												handleObjectArrayChange(index, "notes", newNotes);
											}}
										>
											Remove
										</Button>
									</div>
								))}
								<Button
									className="ml-2"
									variant="outline"
									size="sm"
									onClick={() => {
										const newNotes = [...(edu.notes || []), ""];
										handleObjectArrayChange(index, "notes", newNotes);
									}}
								>
									Add Note
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem}>
						Add Education
					</Button>
				</div>
			);

		case "projects":
			return (
				<div className="space-y-8">
					{(editedData || []).map((project: Project, index: number) => (
						<div key={project.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">Project {index + 1}</h3>
								<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
									Remove Project
								</Button>
							</div>
							<div>
								<RequiredLabel text="Project Name" />
								<Input
									value={project.projectName || ""}
									onChange={(e) => handleObjectArrayChange(index, "projectName", e.target.value)}
									placeholder="Project Name"
									className="mt-1"
									required
								/>
							</div>
							<div>
								<RequiredLabel text="Description" />
								<Textarea
									value={project.description || ""}
									onChange={(e) => handleObjectArrayChange(index, "description", e.target.value)}
									placeholder="Project Description"
									className="mt-1"
									rows={4}
									required
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="Start Month" />
									<Select
										value={project.startMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text="Start Year" />
									<Select
										value={project.startYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select year" />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="End Month" />
									<Select
										value={project.endMonth || ""}
										onValueChange={(value) => {
											if (value === "Present") {
												handleObjectArrayChange(index, "endYear", "");
											}
											handleObjectArrayChange(index, "endMonth", value);
										}}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">Present</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								{project.endMonth && project.endMonth !== "Present" && (
									<div>
										<RequiredLabel text="End Year" />
										<Select
											value={project.endYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											required
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select year" />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								)}
							</div>
							<div>
								<label className="text-sm font-medium">Project URL</label>
								<Input
									value={project.projectURL || ""}
									onChange={(e) => handleObjectArrayChange(index, "projectURL", e.target.value)}
									placeholder="Project URL"
									className="mt-1"
								/>
							</div>
							<div className="space-y-2">
								<RequiredLabel text="Notes" />
								{(project.notes || []).map((note: string, noteIndex: number) => (
									<div key={noteIndex} className="flex gap-2">
										<Input
											value={note}
											onChange={(e) => {
												const newNotes = [...(project.notes || [])];
												newNotes[noteIndex] = e.target.value;
												handleObjectArrayChange(index, "notes", newNotes);
											}}
											className="flex-1"
											required
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newNotes = project.notes.filter((_, i) => i !== noteIndex);
												handleObjectArrayChange(index, "notes", newNotes);
											}}
										>
											Remove
										</Button>
									</div>
								))}
								<Button
									className="ml-2"
									variant="outline"
									size="sm"
									onClick={() => {
										const newNotes = [...(project.notes || []), ""];
										handleObjectArrayChange(index, "notes", newNotes);
									}}
								>
									Add Note
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem}>
						Add Project
					</Button>
				</div>
			);

		case "certifications":
			return (
				<div className="space-y-8">
					{(editedData || []).map((cert: Certification, index: number) => (
						<div key={cert.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">Certification {index + 1}</h3>
								<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
									Remove Certification
								</Button>
							</div>
							<div>
								<RequiredLabel text="Certification Name" />
								<Input
									value={cert.certificationName || ""}
									onChange={(e) => handleObjectArrayChange(index, "certificationName", e.target.value)}
									placeholder="Certification Name"
									className="mt-1"
									required
								/>
							</div>
							<div>
								<RequiredLabel text="Issuing Organization" />
								<Input
									value={cert.issuingOrganization || ""}
									onChange={(e) => handleObjectArrayChange(index, "issuingOrganization", e.target.value)}
									placeholder="Issuing Organization"
									className="mt-1"
									required
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="Issue Month" />
									<Select
										value={cert.issueMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "issueMonth", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text="Issue Year" />
									<Select
										value={cert.issueYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "issueYear", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select year" />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<label className="text-sm font-medium">Expiration Month</label>
									<Select
										value={cert.expirationMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "expirationMonth", value)}
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<label className="text-sm font-medium">Expiration Year</label>
									<Select
										value={cert.expirationYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "expirationYear", value)}
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select year" />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem}>
						Add Certification
					</Button>
				</div>
			);

		case "extraCurricular":
			return (
				<div className="space-y-8">
					{(editedData || []).map((activity: ExtraCurricular, index: number) => (
						<div key={activity.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">Activity {index + 1}</h3>
								<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
									Remove Activity
								</Button>
							</div>
							<div>
								<RequiredLabel text="Activity" />
								<Input
									value={activity.activity || ""}
									onChange={(e) => handleObjectArrayChange(index, "activity", e.target.value)}
									placeholder="Activity"
									className="mt-1"
									required
								/>
							</div>
							<div>
								<RequiredLabel text="Organization Name" />
								<Input
									value={activity.orgName || ""}
									onChange={(e) => handleObjectArrayChange(index, "orgName", e.target.value)}
									placeholder="Organization Name"
									className="mt-1"
									required
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="Start Month" />
									<Select
										value={activity.startMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text="Start Year" />
									<Select
										value={activity.startYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select year" />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text="End Month" />
									<Select
										value={activity.endMonth || ""}
										onValueChange={(value) => {
											if (value === "Present") {
												handleObjectArrayChange(index, "endYear", "");
											}
											handleObjectArrayChange(index, "endMonth", value);
										}}
										required
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select month" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">Present</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								{activity.endMonth && activity.endMonth !== "Present" && (
									<div>
										<RequiredLabel text="End Year" />
										<Select
											value={activity.endYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											required
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select year" />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								)}
							</div>
							<div className="space-y-2">
								<RequiredLabel text="Tasks" />
								{(activity.tasks || []).map((task: string, taskIndex: number) => (
									<div key={taskIndex} className="flex gap-2">
										<Input
											value={task}
											onChange={(e) => {
												const newTasks = [...(activity.tasks || [])];
												newTasks[taskIndex] = e.target.value;
												handleObjectArrayChange(index, "tasks", newTasks);
											}}
											className="flex-1"
											required
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newTasks = activity.tasks.filter((_, i) => i !== taskIndex);
												handleObjectArrayChange(index, "tasks", newTasks);
											}}
										>
											Remove
										</Button>
									</div>
								))}
								<Button
									variant="outline"
									size="sm"
									onClick={() => {
										const newTasks = [...(activity.tasks || []), ""];
										handleObjectArrayChange(index, "tasks", newTasks);
									}}
								>
									Add Task
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem}>
						Add Activity
					</Button>
				</div>
			);

		case "skills":
			return (
				<div className="space-y-4">
					{renderArrayInput("technical", "Technical Skills", "skills")}
					{renderArrayInput("coreCompetencies", "Core Competencies", "skills")}
					{renderArrayInput("languages", "Languages", "skills")}
					{renderArrayInput("tools", "Tools", "skills")}
					{renderArrayInput("frameworks", "Frameworks", "skills")}
				</div>
			);

		case "interests":
			return <div className="space-y-4">{renderArrayInput("interests", "Interests")}</div>;

		case "hobbies":
			return <div className="space-y-4">{renderArrayInput("hobbies", "Hobbies")}</div>;

		default:
			return null;
	}
};

export default ProfileEditForm;
