import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ChatSection from "../ResumeAssist/ChatSection";
import ResumeSelector from "../ResumeAssist/ResumeSelector";
import { ChatMessage, ResumeService } from "../../services/ResumeService";
import { plainToInstance } from "class-transformer";
import { ResumeView } from "../../types/ResumeView";
import TemplateViewer from "../ResumeAssist/TemplateViewer";
import { useNavigate } from "react-router-dom";
import { ProfileView } from "../../types/ProfileView";
import { ProfileService } from "../../services/ProfileService";
import ProfileInfoBox from "../ResumeAssist/ProfileInfoBox";
import ProfileProgressBar from "../ResumeAssist/ProfileProgressBar";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import BackButton from "../Utility/BackButton";
import { Button } from "../shadcn-ui/Button";
import ProfileSectionNav from "../ResumeAssist/ProfileSectionNav";
import { ResumeUpload } from "../ResumeAssist/Resumeupload";
import { TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn-ui/Tooltip";
import { Tooltip } from "../shadcn-ui/Tooltip";
import { InfoIcon, PencilIcon } from "lucide-react";
import { Dialog, DialogHeader, DialogTitle, DialogContent } from "../shadcn-ui/Dialog";
import { LockIcon, ShieldCheckIcon, UserIcon } from "lucide-react";

const ProfileCreator: React.FC = () => {
	const [message, setMessage] = useState("");
	const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
	const [isWaitingForAI, setIsWaitingForAI] = useState(false);
	const { profileId } = useParams<{ profileId?: string }>();
	const { userId } = useParams<{ userId?: string }>();
	const [previousProfile, setPreviousProfile] = useState<ProfileView | null>(null);
	const [profile, setProfile] = useState<ProfileView | null>(null);
	const [documentUrl, setDocumentUrl] = useState<string | null>(null);
	const [initialized, setInitialized] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedSection, setSelectedSection] = useState<string | null>("contact");
	const [unconfirmedSections, setUnconfirmedSections] = useState<string[]>([]);
	const [isEditing, setIsEditing] = useState(false);
	const [editedData, setEditedData] = useState<Partial<ProfileView>>({});
	const [isSaving, setIsSaving] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);

	useEffect(() => {
		if (profile) {
			setIsOpen(!profile.hasAgreedToPrivacy);
		}
	}, [profile]);

	const validateRequiredFields = () => {
		const errors: string[] = [];

		if (editedData.contact) {
			const { firstName, lastName, email, phone } = editedData.contact;
			if (!firstName) errors.push("First Name is required");
			if (!lastName) errors.push("Last Name is required");
			if (!email) errors.push("Email is required");
			if (!phone) errors.push("Phone is required");
		}

		if (editedData.summary === "") {
			errors.push("Summary is required");
		}

		if (editedData.experience) {
			editedData.experience.forEach((exp, index) => {
				if (!exp.jobTitle) errors.push(`Experience ${index + 1}: Job Title is required`);
				if (!exp.companyName) errors.push(`Experience ${index + 1}: Company Name is required`);
				if (!exp.city) errors.push(`Experience ${index + 1}: City is required`);
				if (!exp.stateOrProv) errors.push(`Experience ${index + 1}: State/Province is required`);
				if (!exp.startMonth) errors.push(`Experience ${index + 1}: Start Month is required`);
				if (!exp.startYear) errors.push(`Experience ${index + 1}: Start Year is required`);
				if (!exp.endMonth) errors.push(`Experience ${index + 1}: End Month is required`);
				if (exp.endMonth !== "Present" && !exp.endYear) errors.push(`Experience ${index + 1}: End Year is required`);
				if (!exp.roles?.length) errors.push(`Experience ${index + 1}: At least one role is required`);
			});
		}

		if (editedData.education) {
			editedData.education.forEach((edu, index) => {
				if (!edu.school) errors.push(`Education ${index + 1}: School is required`);
				if (!edu.degree) errors.push(`Education ${index + 1}: Degree is required`);
				if (!edu.program) errors.push(`Education ${index + 1}: Program is required`);
				if (!edu.graduatingYear) errors.push(`Education ${index + 1}: Graduating Year is required`);
			});
		}

		if (editedData.projects) {
			editedData.projects.forEach((proj, index) => {
				if (!proj.projectName) errors.push(`Project ${index + 1}: Project Name is required`);
				if (!proj.description) errors.push(`Project ${index + 1}: Description is required`);
				if (!proj.startMonth) errors.push(`Project ${index + 1}: Start Month is required`);
				if (!proj.startYear) errors.push(`Project ${index + 1}: Start Year is required`);
				if (!proj.endMonth) errors.push(`Project ${index + 1}: End Month is required`);
				if (proj.endMonth !== "Present" && !proj.endYear) errors.push(`Project ${index + 1}: End Year is required`);
				if (!proj.notes?.length) errors.push(`Project ${index + 1}: At least one note is required`);
			});
		}

		if (editedData.certifications) {
			editedData.certifications.forEach((cert, index) => {
				if (!cert.certificationName) errors.push(`Certification ${index + 1}: Name is required`);
				if (!cert.issuingOrganization) errors.push(`Certification ${index + 1}: Issuing Organization is required`);
				if (!cert.issueMonth) errors.push(`Certification ${index + 1}: Issue Month is required`);
				if (!cert.issueYear) errors.push(`Certification ${index + 1}: Issue Year is required`);
			});
		}

		if (editedData.extraCurricular) {
			editedData.extraCurricular.forEach((activity, index) => {
				if (!activity.activity) errors.push(`Activity ${index + 1}: Activity is required`);
				if (!activity.orgName) errors.push(`Activity ${index + 1}: Organization Name is required`);
				if (!activity.startMonth) errors.push(`Activity ${index + 1}: Start Month is required`);
				if (!activity.startYear) errors.push(`Activity ${index + 1}: Start Year is required`);
				if (!activity.endMonth) errors.push(`Activity ${index + 1}: End Month is required`);
				if (activity.endMonth !== "Present" && !activity.endYear) errors.push(`Activity ${index + 1}: End Year is required`);
				if (!activity.tasks?.length) errors.push(`Activity ${index + 1}: At least one task is required`);
			});
		}

		setValidationErrors(errors);
		return errors.length === 0;
	};

	const handleConfirm = async () => {
		if (!profile || !editedData) return;

		if (!validateRequiredFields()) {
			// Show validation errors in a dialog
			setIsOpen(true);
			return;
		}

		setIsSaving(true);

		try {
			// Save each modified section
			const sections = Object.keys(editedData) as Array<
				keyof Omit<ProfileView, "id" | "userId" | "userEmail" | "chatHistory" | "isPublic" | "createdAt" | "updatedAt">
			>;
			for (const section of sections) {
				await ProfileService.updateProfileSection(profile.id, section, editedData[section]);
			}

			// Update the profile state with the new data
			setProfile({ ...profile, ...editedData });
			setEditedData({});
			setIsEditing(false);
		} catch (error) {
			console.error("Error saving profile:", error);
		} finally {
			setIsSaving(false);
		}
	};

	const updateProfileSection = (sectionName: string, sectionData: any) => {
		console.log("updateProfileSection: ", sectionName, sectionData);
		if (profile) {
			setPreviousProfile(profile);
			const updatedProfile = { ...profile, [sectionName]: sectionData };
			setProfile(updatedProfile);
		}
	};

	useEffect(() => {
		if (!profile || !previousProfile) {
			return;
		}
		const profileSections = [
			"contact",
			"summary",
			"experience",
			"education",
			"extraCurricular",
			"skills",
			"projects",
			"certifications",
			"interests",
			"hobbies"
		];
		if (previousProfile && profile) {
			profileSections.forEach((section) => {
				if (profile[section as keyof ProfileView] !== previousProfile[section as keyof ProfileView]) {
					setUnconfirmedSections((prev) => [...prev, section]);
				}
			});
		}
	}, [previousProfile, profile]);

	const getAISelectedSectionResponse = async (message: string, chatHistory: string[]) => {
		// Artificial delay
		try {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/get-ai-selected-section`, {
				message,
				chatHistory,
				sectionName: selectedSection
			});
			console.log("response.data: ", response.data.AiSelectedSection);
			if (response.data.AiSelectedSection) {
				setSelectedSection(response.data.AiSelectedSection);
			}
			return response.data.AiSelectedSection;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const getAIResponse = async (message: string, section: string) => {
		try {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/send-profile-chat`, {
				profileId: profileId,
				message,
				sectionName: section // Use the passed section instead of selectedSection state
			});
			console.log("response.data: ", response.data[`${section}`]);
			if (section) {
				updateProfileSection(section, response.data[`${section}`]);
			}
			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (message.trim()) {
			// Add user message to chat
			const userMessage: ChatMessage = {
				role: "User",
				content: message,
				timestamp: new Date()
			};
			const updatedHistory = [...chatHistory, userMessage];
			setChatHistory(updatedHistory);
			setIsWaitingForAI(true);

			if (profileId) {
				try {
					console.log("updatingChatHistory ProfileId: ", profileId, userMessage);
					await ProfileService.updateChatHistory(profileId, userMessage);
				} catch (error) {
					console.log(error);
					throw error;
				}
			}
			// try {
			// 	await getAISelectedSectionResponse(
			// 		message,
			// 		updatedHistory.map((msg) => `${msg.role}: ${msg.content}`)
			// 	);
			// 	// setSelectedSection(aiDeterminedSection.AiSelectedSection);
			// } catch (error) {
			// 	console.log(error);
			// }

			try {
				const aiSelectedSummary = await getAISelectedSectionResponse(
					message,
					updatedHistory.map((msg) => `${msg.role}: ${msg.content}`)
				);
				console.log("aiSelectedSummary", aiSelectedSummary);

				// Get AI response using the newly selected section directly from the response
				const aiResponse = await getAIResponse(
					message,
					aiSelectedSummary // Pass the selected section directly
				);
				const assistantMessage: ChatMessage = {
					role: "Assistant",
					content: aiResponse.message,
					timestamp: new Date()
				};
				setChatHistory([...updatedHistory, assistantMessage]);

				if (profileId) {
					try {
						await ProfileService.updateChatHistory(profileId, assistantMessage);
					} catch (error) {
						console.log(error);
						throw error;
					}
				}
			} catch (error) {
				console.error("Error getting AI response:", error);
			} finally {
				setIsWaitingForAI(false);
			}

			setMessage("");
		}
	};

	const handleFileSelect = (file: File) => {
		if (file.size > 5 * 1024 * 1024) {
			alert("File size should be less than 5MB");
			return;
		}

		const userMessage: ChatMessage = {
			role: "User",
			content: `Selected resume: ${file.name}`,
			timestamp: new Date()
		};
		const assistantMessage: ChatMessage = {
			role: "Assistant",
			content: `I see you've selected ${file.name}. Would you like me to analyze this resume for you?`,
			timestamp: new Date()
		};
		setChatHistory([...chatHistory, userMessage, assistantMessage]);
	};

	useEffect(() => {
		const fetchProfile = async () => {
			if (profileId) {
				const fetchedProfile = await ProfileService.fetchProfile(profileId);
				setProfile(fetchedProfile);
				setChatHistory(fetchedProfile?.chatHistory || []);
				setInitialized(true);
			}
		};
		if (profileId) {
			fetchProfile();
		}
	}, [profileId]);

	useEffect(() => {
		const initializeChat = async () => {
			const initialMessage: ChatMessage = {
				role: "Assistant",
				content:
					"Hi there, I see that you need some help creating or refining your resume. First, Can you please either upload your existing resume or start by providing some basic information about yourself such as your name?",
				timestamp: new Date()
			};
			setChatHistory([initialMessage]);
			setIsLoading(false);

			if (profileId) {
				try {
					await ProfileService.updateChatHistory(profileId, initialMessage);
				} catch (error) {
					console.log(error);
					throw error;
				}
			}
		};

		if (chatHistory.length === 0 && profileId && initialized) {
			initializeChat();
		}
	}, [chatHistory, profileId, initialized]);

	const AgreeToPrivacy = async () => {
		console.log("AgreeToPrivacy");
		if (!profileId) {
			console.log("No profileId");
			return;
		}
		await ProfileService.agreeToPrivacy(profileId);
		setIsOpen(false);
		if (profile) {
			setProfile({ ...profile, hasAgreedToPrivacy: true });
		}
	};

	const ProfileDisclaimer: React.FC = () => {
		const navigate = useNavigate();

		return (
			<>
				<Dialog open={isOpen} onOpenChange={setIsOpen}>
					<DialogContent className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle className="flex items-center gap-2 text-xl">
								<LockIcon className="h-6 w-6 text-gray-500" />
								Profile Information
							</DialogTitle>
						</DialogHeader>
						<div className="py-4 space-y-6">
							<div>
								<h4 className="text-sm font-medium mb-2 flex items-center gap-2">
									<InfoIcon className="h-4 w-4 text-gray-500" />
									Data Storage
								</h4>
								<p className="text-sm text-gray-600">
									Your privacy matters. Any information you provide is encrypted and securely stored in Canada.
								</p>
							</div>
							<div>
								<h4 className="text-sm font-medium mb-2 flex items-center gap-2">
									<ShieldCheckIcon className="h-4 w-4 text-gray-500" />
									Data Access
								</h4>
								<p className="text-sm text-gray-600">
									Your data will only be visible to you and authorized InStage support staff who may need access to assist you.
								</p>
							</div>
							<div>
								<h4 className="text-sm font-medium mb-2 flex items-center gap-2">
									<UserIcon className="h-4 w-4 text-gray-500" />
									Data Sharing
								</h4>
								<p className="text-sm text-gray-600">
									We never sell or share your personal information with non-critical or threatening third parties.
								</p>
							</div>
							<div className="flex gap-3 justify-start pt-2">
								<Button
									variant="outline"
									onClick={() => navigate("/user/resume-assist")}
									className="border-[#00a9af] text-[#00a9af] hover:bg-transparent hover:text-[#00a9af]/90 hover:border-[#00a9af]/90"
								>
									Close
								</Button>
								<Button onClick={() => AgreeToPrivacy()} className="bg-[#00a9af] hover:bg-[#00a9af]/90 text-white border-none">
									Agree
								</Button>
							</div>
						</div>
					</DialogContent>
				</Dialog>
			</>
		);
	};

	return (
		<>
			{profileId && (
				<div className="h-[calc(75vh)] mt-0">
					{!profile?.hasAgreedToPrivacy && <ProfileDisclaimer />}
					{validationErrors.length > 0 && (
						<Dialog open={isOpen} onOpenChange={setIsOpen}>
							<DialogContent className="sm:max-w-[425px]">
								<DialogHeader>
									<DialogTitle className="text-xl text-red-600">Required Fields Missing</DialogTitle>
								</DialogHeader>
								<div className="py-4">
									<div className="max-h-[300px] overflow-y-auto">
										<ul className="list-disc pl-5 space-y-2">
											{validationErrors.map((error, index) => (
												<li key={index} className="text-sm text-gray-600">
													{error}
												</li>
											))}
										</ul>
									</div>
									<div className="flex justify-end mt-4">
										<Button onClick={() => setIsOpen(false)}>Close</Button>
									</div>
								</div>
							</DialogContent>
						</Dialog>
					)}
					<div className="container mx-auto px-6 py-2 flex flex-col h-full">
						<div className="flex items-center gap-2 mb-4">
							<h1 className="text-2xl font-bold text-[#16013e]">Profile Creator</h1>
							<TooltipProvider delayDuration={0}>
								<Tooltip>
									<TooltipTrigger asChild onClick={(e) => e.preventDefault()}>
										<button>
											<InfoIcon className="w-4 h-4 text-[#16013e] hover:text-[#00a9af] transition-colors cursor-pointer" />
										</button>
									</TooltipTrigger>
									<TooltipContent side="right" align="center">
										<p className="whitespace-pre-wrap max-w-[300px]">
											Your profile holds all of your resume related information which will be used to create/ tailor your
											resumes easily.
										</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
						<div className="flex justify-between items-start w-full">
							<BackButton label="Back to Resume Assist" />
							<div className="flex gap-2 items-start">
								{isEditing ? (
									<>
										<Button
											variant="outline"
											onClick={() => {
												setEditedData({});
												setIsEditing(false);
											}}
											disabled={isSaving}
										>
											Cancel
										</Button>
										<Button onClick={handleConfirm} disabled={isSaving}>
											{isSaving ? (
												<div className="flex items-center gap-2">
													<div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
													Saving...
												</div>
											) : (
												"Save Changes"
											)}
										</Button>
									</>
								) : (
									<Button
										onClick={() => {
											// Initialize all form data when entering edit mode
											if (profile) {
												const defaultContact = {
													firstName: "",
													lastName: "",
													email: "",
													phone: "",
													address: "",
													linkedinURL: "",
													website: ""
												};

												setEditedData({
													contact: profile.contact ? { ...defaultContact, ...profile.contact } : defaultContact,
													summary: profile.summary || "",
													experience: profile.experience || [],
													education: profile.education || [],
													skills: profile.skills || {
														technical: [],
														coreCompetencies: [],
														languages: [],
														tools: [],
														frameworks: []
													},
													projects: profile.projects || [],
													certifications: profile.certifications || [],
													extraCurricular: profile.extraCurricular || [],
													interests: profile.interests || [],
													hobbies: profile.hobbies || []
												});
											}
											setIsEditing(true);
										}}
									>
										<PencilIcon className="w-4 h-4 mr-2" />
										Edit
									</Button>
								)}
							</div>
						</div>
						<div className="flex items-center gap-4">
							<div className="flex-1">
								<ProfileProgressBar profile={profile} setSelectedSection={setSelectedSection} />
							</div>
						</div>
						<div className="flex gap-8 h-full">
							<div className="hidden lg:block flex-col w-64">
								<ResumeUpload profileId={profileId} setProfile={setProfile} profile={profile} />
								<ProfileSectionNav
									selectedSection={selectedSection || "contact"}
									setSelectedSection={setSelectedSection}
									profile={profile}
								/>
							</div>
							<div className="flex-1 h-full overflow-y-auto">
								{profile && (
									<ProfileInfoBox
										profile={profile}
										setSelectedSection={setSelectedSection}
										selectedSection={selectedSection || "contact"}
										unconfirmedSections={unconfirmedSections}
										setUnconfirmedSections={setUnconfirmedSections}
										setProfile={setProfile}
										isEditing={isEditing}
										editedData={editedData}
										setEditedData={setEditedData}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ProfileCreator;
