import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SessionService, SessionStats } from "../../services/SessionService";
import { SessionView } from "../../types/SessionView";
import { ColumnDef, SortingState, ColumnFiltersState, PaginationState } from "@tanstack/react-table";

import "react-datepicker/dist/react-datepicker.css";

import BackButton from "../Utility/BackButton";

import { Button } from "../../components/shadcn-ui/Button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../components/shadcn-ui/Select";
import { useAppContext } from "../../contexts/AppContext";
import { SentEmailService } from "../../services/SentEmailService";
import { Filter, DataTable } from "../../components/shadcn-ui/DataTable";
import { ExtendedColumnDef } from "../shadcn-ui/DataTable";
import { NavigateService } from "../../services/NavigateService";

const SessionLog: React.FC = () => {
	const { assignmentId } = useParams<{ assignmentId: string }>();
	const [sessions, setSessions] = useState<SessionView[]>([]);
	const [loading, setLoading] = useState(true);
	const [sorting, setSorting] = useState<SortingState>([]);
	const { debugMode } = useAppContext();
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [dateRange, setDateRange] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
	const [startDate, endDate] = dateRange;
	const [selectedClient, setSelectedClient] = useState<string>("");
 	const [sessionStats, setSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});

	const [paginationState, setPaginationState] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10
	});
	const [pageCount, setPageCount] = React.useState<number>(-1); // Initialize with -1
	const [clientFilters, setClientFilters] = useState<{ clientId: string; clientName: string }[]>([]);
	useEffect(() => {
		const fetchSessions = async () => {
			try {
				setLoading(true);
				let fetchedSessions: SessionView[];
				if (assignmentId) {
					console.log(`fetching sessions for assignment ${assignmentId}`);
					const { sessions } = await SessionService.getAllSessionsByAssignmentId(assignmentId);
					fetchedSessions = sessions;
				} else {
					if (debugMode) {

						if(clientFilters.length === 0) {
							const updatedClientFilters = await SessionService.getSessionClientFilters();
							console.log(`clientFilters`, updatedClientFilters);
							setClientFilters(updatedClientFilters);
						}
						console.log(`fetching sessions in debug mode, selectedClient: ${selectedClient}`);
						const clientIdForRequest = selectedClient === 'all' ? undefined : selectedClient;
						const { sessions, totalPages    } = await SessionService.getAllSessionsDebug(
							paginationState.pageIndex,
							paginationState.pageSize,
							clientIdForRequest
						);
						setPageCount(totalPages);
						fetchedSessions = sessions;
						console.log(`fetchedSessions`, fetchedSessions);
						setSessions(fetchedSessions);
						const stats = await SessionService.getAllSessionsDebugStats(clientIdForRequest);
						setSessionStats(stats);
					} else {
						console.log(`fetching sessions for current client`);
						const { sessions, totalPages   } = await SessionService.getAllSessionsByClientId(
							paginationState.pageIndex,
							paginationState.pageSize
						);
						fetchedSessions = sessions;
						setPageCount(totalPages);
						const stats = await SessionService.getAllSessionsStatsByClientId();
						setSessionStats(stats);
						setSessions(fetchedSessions);
					}
				}
			} catch (error) {
				console.error("Error fetching sessions:", error);
				// Handle error (e.g., show error message to user)
			} finally {
				setLoading(false);
			}
		};
		console.log("fetchSessions", paginationState);

		fetchSessions();
	}, [assignmentId, debugMode, paginationState, selectedClient]);

	const columns = useMemo<ExtendedColumnDef<SessionView>[]>(
		() => [
			{
				header: "Session ID",
				accessorKey: "sessionId",
				initiallyHidden: true
			},
			{
				header: "Client",
				accessorKey: "clientName",
				initiallyHidden: true
			},

			{
				header: "User ID",
				accessorKey: "userId",
				initiallyHidden: true
			},
			{
				header: "Assignment ID",
				accessorKey: "assignmentId",
				initiallyHidden: true
			},
			{
				header: "Start Time",
				accessorKey: "createdAt",
				initiallyHidden: true,

				cell: ({ getValue }) => {
					const date = new Date(getValue() as string);
					return date
						? date.toLocaleString("en-US", {
								weekday: "short",
								year: "numeric",
								month: "short",
								day: "numeric",
								hour: "2-digit",
								minute: "2-digit"
						  })
						: "n/a";
				}
			},
			{
				header: "End Time",
				accessorKey: "submittedAt",
				initiallyHidden: true,
				cell: ({ getValue }) => {
					const date = new Date(getValue() as string);
					return date.getTime() === 0 ? "n/a" : date.toLocaleString();
				}
			},
			{
				header: "scheduleDescription",
				accessorKey: "scheduleDescription"
			},

			{
				header: "Student Name",
				accessorKey: "firstName"
			},
			{
				header: "Student Email",
				accessorKey: "email"
			},
			{
				header: "Date",
				accessorKey: "scheduleDate",
				cell: ({ getValue }) => (getValue() as Date)?.toLocaleDateString()
			},
			{
				header: "Duration",
				accessorKey: "minutesUsed",
				cell: ({ getValue }) => {
					const totalMinutes = getValue() as number;
					if (!totalMinutes) return "n/a";
					const minutes = Math.floor(totalMinutes);
					const seconds = (totalMinutes - minutes) * 60;
					return seconds > 0 ? `${minutes}m ${seconds.toFixed(0)}s` : `${minutes}m`;
				}
			},
			{
				header: "Report",
				cell: ({ row }) => (
					<div className="flex gap-2">
						{new Date(row.original.submittedAt).getTime() !== 0 && (
							<Button variant="link" onClick={() => NavigateService.navToUserReport(row.original.assignmentId, row.original.userId)}>
								View Report
							</Button>
						)}
					</div>
				)
			}
		],
		[]
	);

	const downloadCSV = () => {
		// Implement CSV download functionality here
		console.log("Downloading CSV...");
	};
	

	const renderMetrics = () => (
		<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
			{[
				{ label: "Usage", value: sessionStats?.totalDuration || "0h 0m"},
				{ label: "Avg Duration", value: sessionStats?.avgDuration || "0h 0m" },
				{ label: "Sessions Started", value: sessionStats?.sessionsStarted || "0" },
				{ label: "Sessions Submitted", value: sessionStats?.sessionsSubmitted || "0" },
				{ label: "Unique Students", value: sessionStats?.uniqueStudents || "0" }
			].map((metric, index) => (
				<div key={index} className="bg-white p-4 border rounded-lg border-gray-200">
					<p className="text-sm font-medium text-gray-500 mb-1">{metric.label}</p>
					<p className="text-2xl font-bold text-[#16013e]">{metric.value}</p>
				</div>
			))}
		</div>
	);

	return (
		<div>
			<BackButton />

			<h1 className="text-3xl font-bold text-[#16013e] mb-6">Session Log {assignmentId ? `for Assignment ${assignmentId}` : ""}</h1>

			{renderMetrics()}

			{debugMode && (
				<div className="flex justify-between items-center mb-4">
				<Select onValueChange={(value: string) => {
					setSelectedClient(value);
					
  				}
					}>
					<SelectTrigger className="w-[200px]">
						<SelectValue placeholder="Select Client" />
					</SelectTrigger>
					<SelectContent>
						<SelectGroup>
							<SelectLabel>Clients</SelectLabel>
							<SelectItem value="all">All Clients</SelectItem>
							{clientFilters?.map((client) => (
								<SelectItem key={client.clientId} value={client.clientId}>
									{client.clientName}
								</SelectItem>
							))}
						</SelectGroup>
					</SelectContent>
				</Select>
			</div>
			)}

			{loading ? (
				<p>Loading sessions...</p>
			) : (
				<DataTable
					pageCount={pageCount}
					paginationState={paginationState}
					onPaginationChange={setPaginationState}
					columns={columns}
					data={sessions}
				/>
			)}
		</div>
	);
};

export default SessionLog;
