import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Button } from "../../components/shadcn-ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../components/shadcn-ui/Dialog";

const BackButton: React.FC<{ onPrevious?: () => void; currentStep?: number; label?: string }> = ({ onPrevious, currentStep, label }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showDialog, setShowDialog] = useState(false);

	const isAssignmentCreator = location.pathname.includes("/create") || location.pathname.includes("/edit");
	const shouldUsePrevious = isAssignmentCreator && currentStep && currentStep > 0;
	const isFirstStep = isAssignmentCreator && currentStep === 0;

	const handleClick = () => {
		if (shouldUsePrevious && onPrevious) {
			onPrevious();
		} else if (isFirstStep) {
			setShowDialog(true);
		} else {
			navigate(-1);
		}
	};

	const handleConfirmNavigation = () => {
		setShowDialog(false);
		navigate(-1);
	};

	return (
		<>
			<Button variant="ghost" className="text-gray-600 p-0 mb-4" onClick={handleClick}>
				<ArrowLeftIcon className="h-5 w-5 mr-2" />
				<span>{label || "Back"}</span>
			</Button>

			<Dialog open={showDialog} onOpenChange={setShowDialog}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Are you sure you want to leave?</DialogTitle>
						<DialogDescription>Your progress will be lost if you exit this page.</DialogDescription>
					</DialogHeader>
					<DialogFooter className="flex gap-2">
						<Button variant="outline" onClick={() => setShowDialog(false)}>
							Cancel
						</Button>
						<Button variant="destructive" onClick={handleConfirmNavigation}>
							Leave
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default BackButton;
