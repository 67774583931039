import React, { useRef, useEffect } from "react";
import { ProfileView } from "../../types/ProfileView";
import { Button } from "../shadcn-ui/Button";
import ProfileEditForm from "./ProfileEditForm";
import { ProfileService } from "../../services/ProfileService";

type ProfileSection = keyof Omit<ProfileView, "id" | "userId" | "userEmail" | "chatHistory" | "isPublic" | "createdAt" | "updatedAt">;

interface ProfileInfoBoxProps {
	profile: ProfileView;
	setSelectedSection: (section: string) => void;
	selectedSection: string;
	unconfirmedSections: string[];
	setUnconfirmedSections: (sections: string[]) => void;
	setProfile?: (profile: ProfileView) => void;
	isEditing: boolean;
	editedData: Partial<ProfileView>;
	setEditedData: React.Dispatch<React.SetStateAction<Partial<ProfileView>>>;
}

type SectionRefs = {
	[key: string]: React.RefObject<HTMLElement>;
};

const ProfileInfoBox: React.FC<ProfileInfoBoxProps> = ({
	profile,
	setSelectedSection,
	selectedSection,
	unconfirmedSections,
	setUnconfirmedSections,
	setProfile,
	isEditing,
	editedData,
	setEditedData
}) => {
	const sectionRefs: SectionRefs = {
		contact: useRef<HTMLElement>(null),
		summary: useRef<HTMLElement>(null),
		experience: useRef<HTMLElement>(null),
		education: useRef<HTMLElement>(null),
		skills: useRef<HTMLElement>(null),
		projects: useRef<HTMLElement>(null),
		certifications: useRef<HTMLElement>(null),
		extraCurricular: useRef<HTMLElement>(null),
		interests: useRef<HTMLElement>(null),
		hobbies: useRef<HTMLElement>(null)
	};

	useEffect(() => {
		if (selectedSection && sectionRefs[selectedSection]?.current) {
			sectionRefs[selectedSection].current?.scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		}
	}, [selectedSection]);

	const handleConfirmSection = async (sectionName: ProfileSection) => {
		try {
			const currentData = profile[sectionName];
			await ProfileService.updateProfileSection(profile.id, sectionName, currentData);
			setUnconfirmedSections(unconfirmedSections.filter((section) => section !== sectionName));
		} catch (error) {
			console.error("Error confirming section:", error);
		}
	};

	const getSectionClassName = (sectionName: string) => {
		return `mb-8 p-4 rounded-lg shadow-md cursor-pointer transition-all duration-200 scroll-mt-16
			${
				selectedSection === sectionName
					? "border-[#00a9af] border-2 bg-[#00a9af]/5 shadow-lg transform scale-[1.00]"
					: "border-gray-200 border bg-white hover:border-[#00a9af]/50"
			}`;
	};

	const handleSectionClick = (sectionName: string) => {
		setSelectedSection(sectionName);
	};

	const renderSection = (sectionName: ProfileSection, title: string, content: React.ReactNode) => {
		return (
			<section ref={sectionRefs[sectionName]} className={getSectionClassName(sectionName)} onClick={() => handleSectionClick(sectionName)}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">{title}</h2>
				</div>
				{isEditing ? (
					<div onClick={(e) => e.stopPropagation()}>
						<ProfileEditForm
							editingSection={sectionName}
							editedData={editedData[sectionName]}
							setEditedData={(newData: any) => {
								setEditedData((prev) => ({
									...prev,
									[sectionName]: newData
								}));
							}}
						/>
					</div>
				) : (
					content
				)}
			</section>
		);
	};

	return (
		<div className="max-w-7xl mx-auto overflow-hidden px-0">
			{renderSection(
				"contact",
				"Contact Information",
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
					<div className="p-2">
						<strong>Name:</strong> {profile.contact?.firstName || <span className="text-gray-400 italic">First Name</span>}{" "}
						{profile.contact?.lastName || <span className="text-gray-400 italic">Last Name</span>}
					</div>
					<div className="p-2">
						<strong>Email:</strong> {profile.contact?.email || <span className="text-gray-400 italic">Email Address</span>}
					</div>
					<div className="p-2">
						<strong>Phone:</strong> {profile.contact?.phone || <span className="text-gray-400 italic">Phone Number</span>}
					</div>
					<div className="p-2">
						<strong>Address:</strong> {profile.contact?.address || <span className="text-gray-400 italic">Address</span>}
					</div>
					{profile.contact?.linkedinURL && (
						<div className="p-2">
							<strong>LinkedIn:</strong>{" "}
							<a
								href={profile.contact.linkedinURL}
								target="_blank"
								rel="noopener noreferrer"
								className="text-[#00a9af] hover:underline"
							>
								Profile
							</a>
						</div>
					)}
					{profile.contact?.website && (
						<div className="p-2">
							<strong>Website:</strong>{" "}
							<a href={profile.contact.website} target="_blank" rel="noopener noreferrer" className="text-[#00a9af] hover:underline">
								{profile.contact.website}
							</a>
						</div>
					)}
				</div>
			)}

			{renderSection(
				"summary",
				"Summary",
				<p className="text-gray-700">
					{profile.summary || (
						<span className="text-gray-400 italic">
							Add a professional summary highlighting your key qualifications and career objectives
						</span>
					)}
				</p>
			)}

			{renderSection(
				"experience",
				"Experience",
				<div>
					{profile.experience?.map((exp) => (
						<div key={exp.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
							<h3 className="text-xl font-semibold">{exp.jobTitle || <span className="text-gray-400 italic">Job Title</span>}</h3>
							<p className="text-gray-600 text-sm">
								{exp.companyName || <span className="text-gray-400 italic">Company Name</span>} |{" "}
								{exp.city || <span className="text-gray-400 italic">City</span>},{" "}
								{exp.stateOrProv || <span className="text-gray-400 italic">State/Province</span>}
							</p>
							<p className="text-gray-600 text-sm">
								{exp.startMonth || <span className="text-gray-400 italic">Start Month</span>}{" "}
								{exp.startYear || <span className="text-gray-400 italic">Start Year</span>} -{" "}
								{exp.endMonth || <span className="text-gray-400 italic">End Month</span>}{" "}
								{exp.endYear || <span className="text-gray-400 italic">End Year</span>}
							</p>
							<ul className="list-disc pl-6 mt-2">
								{exp.roles?.length ? (
									exp.roles.map((role, index) => (
										<li key={index} className="text-gray-700 mb-1">
											{role}
										</li>
									))
								) : (
									<li className="text-gray-400 italic">Add role descriptions</li>
								)}
							</ul>
						</div>
					))}
				</div>
			)}

			{renderSection(
				"education",
				"Education",
				<div>
					{profile.education?.map((edu) => (
						<div key={edu.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
							<h3 className="text-xl font-semibold">{edu.school || <span className="text-gray-400 italic">School Name</span>}</h3>
							<p className="text-gray-700">
								{edu.degree || <span className="text-gray-400 italic">Degree</span>} in{" "}
								{edu.program || <span className="text-gray-400 italic">Program</span>}
							</p>
							<p className="text-gray-600">
								Graduating Year: {edu.graduatingYear || <span className="text-gray-400 italic">Year</span>}
							</p>
							{edu.notes?.length ? (
								<ul className="list-disc pl-6 mt-2">
									{edu.notes.map((note, index) => (
										<li key={index} className="text-gray-700 mb-1">
											{note}
										</li>
									))}
								</ul>
							) : (
								<ul className="list-disc pl-6 mt-2">
									<li className="text-gray-400 italic">Add educational achievements or highlights</li>
								</ul>
							)}
						</div>
					))}
				</div>
			)}

			{renderSection(
				"skills",
				"Skills",
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Technical</h3>
						<div className="flex flex-wrap gap-2">
							{profile.skills?.technical?.length ? (
								profile.skills.technical.map((skill, index) => (
									<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
										{skill}
									</span>
								))
							) : (
								<span className="text-gray-400 italic">Add technical skills</span>
							)}
						</div>
					</div>
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Competencies</h3>
						<div className="flex flex-wrap gap-2">
							{profile.skills?.coreCompetencies?.length ? (
								profile.skills.coreCompetencies.map((skill, index) => (
									<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
										{skill}
									</span>
								))
							) : (
								<span className="text-gray-400 italic">Add competencies</span>
							)}
						</div>
					</div>
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Languages</h3>
						<div className="flex flex-wrap gap-2">
							{profile.skills?.languages?.length ? (
								profile.skills.languages.map((lang, index) => (
									<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
										{lang}
									</span>
								))
							) : (
								<span className="text-gray-400 italic">Add languages</span>
							)}
						</div>
					</div>
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Tools & Frameworks</h3>
						<div className="flex flex-wrap gap-2">
							{[...(profile.skills?.tools || []), ...(profile.skills?.frameworks || [])]?.length ? (
								[...(profile.skills?.tools || []), ...(profile.skills?.frameworks || [])].map((item, index) => (
									<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
										{item}
									</span>
								))
							) : (
								<span className="text-gray-400 italic">Add tools and frameworks</span>
							)}
						</div>
					</div>
				</div>
			)}

			{renderSection(
				"projects",
				"Projects",
				<div>
					{profile.projects?.map((project) => (
						<div key={project.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
							<h3 className="text-xl font-semibold">
								{project.projectName || <span className="text-gray-400 italic">Project Name</span>}
							</h3>
							<p className="text-gray-600 text-sm">
								{project.startMonth || <span className="text-gray-400 italic">Start Month</span>}{" "}
								{project.startYear || <span className="text-gray-400 italic">Start Year</span>} -{" "}
								{project.endMonth || <span className="text-gray-400 italic">End Month</span>}{" "}
								{project.endYear || <span className="text-gray-400 italic">End Year</span>}
							</p>
							<p className="text-gray-700 mt-2">
								{project.description || <span className="text-gray-400 italic">Project Description</span>}
							</p>
							{project.projectURL ? (
								<p className="mt-2">
									<a href={project.projectURL} target="_blank" rel="noopener noreferrer" className="text-[#00a9af] hover:underline">
										Project Link
									</a>
								</p>
							) : (
								<p className="mt-2 text-gray-400 italic">Add project URL</p>
							)}
							{project.notes?.length ? (
								<ul className="list-disc pl-6 mt-2">
									{project.notes.map((note, index) => (
										<li key={index} className="text-gray-700 mb-1">
											{note}
										</li>
									))}
								</ul>
							) : (
								<ul className="list-disc pl-6 mt-2">
									<li className="text-gray-400 italic">Add project highlights or achievements</li>
								</ul>
							)}
						</div>
					))}
				</div>
			)}

			{renderSection(
				"certifications",
				"Certifications",
				<div>
					{profile.certifications?.map((cert) => (
						<div key={cert.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
							<h3 className="text-xl font-semibold">
								{cert.certificationName || <span className="text-gray-400 italic">Certification Name</span>}
							</h3>
							<p className="text-gray-700">
								{cert.issuingOrganization || <span className="text-gray-400 italic">Issuing Organization</span>}
							</p>
							<p className="text-gray-600 text-sm">
								Issued: {cert.issueMonth || <span className="text-gray-400 italic">Month</span>}{" "}
								{cert.issueYear || <span className="text-gray-400 italic">Year</span>}
								{(cert.expirationMonth || cert.expirationYear) && (
									<span>
										{" "}
										| Expires: {cert.expirationMonth || <span className="text-gray-400 italic">Month</span>}{" "}
										{cert.expirationYear || <span className="text-gray-400 italic">Year</span>}
									</span>
								)}
							</p>
						</div>
					))}
				</div>
			)}

			{renderSection(
				"extraCurricular",
				"Extra-Curricular Activities",
				<div>
					{profile.extraCurricular?.map((activity) => (
						<div key={activity.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
							<h3 className="text-xl font-semibold">
								{activity.activity || <span className="text-gray-400 italic">Activity Name</span>}
							</h3>
							<p className="text-gray-700">{activity.orgName || <span className="text-gray-400 italic">Organization Name</span>}</p>
							<p className="text-gray-600 text-sm">
								{activity.startMonth || <span className="text-gray-400 italic">Start Month</span>}{" "}
								{activity.startYear || <span className="text-gray-400 italic">Start Year</span>} -{" "}
								{activity.endMonth || <span className="text-gray-400 italic">End Month</span>}{" "}
								{activity.endYear || <span className="text-gray-400 italic">End Year</span>}
							</p>
							{activity.tasks?.length ? (
								<ul className="list-disc pl-6 mt-2">
									{activity.tasks.map((task, index) => (
										<li key={index} className="text-gray-700 mb-1">
											{task}
										</li>
									))}
								</ul>
							) : (
								<ul className="list-disc pl-6 mt-2">
									<li className="text-gray-400 italic">Add activity responsibilities or achievements</li>
								</ul>
							)}
						</div>
					))}
				</div>
			)}

			{renderSection(
				"interests",
				"Interests",
				<div>
					<ul className="list-disc pl-6">
						{profile.interests?.length ? (
							profile.interests.map((interest, index) => (
								<li key={index} className="text-gray-700 mb-1">
									{interest}
								</li>
							))
						) : (
							<li className="text-gray-400 italic">Add your interests</li>
						)}
					</ul>
				</div>
			)}

			{renderSection(
				"hobbies",
				"Hobbies",
				<div>
					<ul className="list-disc pl-6">
						{profile.hobbies?.length ? (
							profile.hobbies.map((hobby, index) => (
								<li key={index} className="text-gray-700 mb-1">
									{hobby}
								</li>
							))
						) : (
							<li className="text-gray-400 italic">Add your hobbies</li>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ProfileInfoBox;
