import React, { useEffect, useState } from "react";
import { Document, Packer, Paragraph, TextRun, Table, TableCell, TableRow, HeadingLevel, VerticalAlign, AlignmentType, BorderStyle } from "docx";
import { ResumeView } from "../../../../types/ResumeView";
import ResumeEditForm from "../../ResumeEditForm";
import { SectionHeader } from "../../SectionHeader";
import { ResumeStyleProps } from "../../../UserPages/ResumeAssist";

export const ModernProfessionalTemplateHTML = (
	resume: ResumeView,
	style: ResumeStyleProps,
	handleSectionClick: (section: string) => void,
	{
		editingSection,
		editedData,
		setEditedData,
		isEditFormOpen,
		setIsEditFormOpen
	}: {
		editingSection: string;
		editedData: any;
		setEditedData: (data: any) => void;
		isEditFormOpen: boolean;
		setIsEditFormOpen: (open: boolean) => void;
	}
) => {
	const singleColumnStyles = (
		<style>{`
       
         .resume-section {
           border: 1px solid transparent;
		   border-radius: 8px;
		   padding: 8px;
           position: relative;  /* Add this */
        }
        .resume-section:hover {
            border-radius: 8px;
            padding: 8px;
            margin: 0px;
            background-color: rgba(0, 169, 175, 0.1);
            border: 1px solid #00a9af;
        }

        .score-button {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 4px;
            padding: 4px 8px;
            border-radius: 4px;
            transition: all 0.2s ease;
            border: 1px solid rgba(0, 169, 175, 0.3);
            background-color: rgba(0, 169, 175, 0.05);
            cursor: pointer;
            position: absolute;  
            top: 0;             
            right: 0;           
        }
        
        .score-button:hover {
            background-color: rgba(0, 169, 175, 0.1);
            
            border-color: rgba(0, 169, 175, 0.5);
        }
        
    `}</style>
	);
	return (
		<>
			{singleColumnStyles}
			<div
				style={{
					fontFamily: "Calibri, sans-serif",
					paddingLeft: "0px",
					paddingRight: "0px",
					paddingBottom: "60px",
					paddingTop: "0px",
					maxWidth: "100%",
					fontSize: `${16 * style.fontSize}px`,
					lineHeight: 1.5
				}}
			>
				<div
					style={{
						background: `${style.accentColor}`,
						width: "100vw",
						position: "relative",
						left: "50%",
						right: "50%",
						marginLeft: "-50vw",
						marginRight: "-50vw",
						padding: "40px calc((100vw - 1000px) / 2)",
						marginBottom: "40px",
						color: "white"
					}}
				>
					<div
						style={{
							maxWidth: "1000px",
							margin: "0 auto"
						}}
					>
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
							<h1
								className="resume-section"
								style={{
									fontSize: `${34 * style.fontSize}px`,
									fontWeight: "bold",
									lineHeight: 1.2,
									cursor: "pointer",
									margin: 0,
									color: "white"
								}}
								onClick={() => handleSectionClick("contact")}
							>
								{resume.contact.firstName} {resume.contact.lastName}
							</h1>
						</div>
						<p
							style={{
								fontSize: `${22 * style.fontSize}px`,
								marginBottom: "0",
								color: "white",
								lineHeight: 1.2,
								fontStyle: "italic"
							}}
						>
							{resume.jobTitle || "GRAPHIC DESIGNER"}
						</p>
					</div>
				</div>

				<div style={{ maxWidth: "1000px", margin: "0 auto" }}>
					<div style={{ display: "flex", gap: "30px" }}>
						{/* Left column */}
						<div style={{ flex: "1 1 25%", paddingRight: "24px" }}>
							<div className="resume-section" onClick={() => handleSectionClick("contact")} style={{ cursor: "pointer" }}>
								<SectionHeader resume={resume} title="CONTACT" section="contact" style={style} />
								<p style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>{resume.contact.phone}</p>
								<p style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>{resume.contact.email}</p>
								<p style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>{resume.contact.address}</p>
								<p style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "20px", lineHeight: 1.5 }}>
									{resume.contact.website}
								</p>
							</div>

							<div className="resume-section" onClick={() => handleSectionClick("skills")} style={{ cursor: "pointer" }}>
								<SectionHeader resume={resume} title="TECHNICAL" section="skills" style={style} />
								{resume.skills.technical.map((skill, i) => (
									<p key={i} style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>
										{skill}
									</p>
								))}

								<h2
									style={{
										fontSize: `${19 * style.fontSize}px`,
										fontWeight: "bold",
										marginTop: "20px",
										marginBottom: "20px",
										lineHeight: 1.2
									}}
								>
									COMPETENCIES
								</h2>
								{resume.skills.coreCompetencies.map((skill, i) => (
									<p key={i} style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>
										{skill}
									</p>
								))}

								<h2
									style={{
										fontSize: `${19 * style.fontSize}px`,
										fontWeight: "bold",
										marginTop: "20px",
										marginBottom: "20px",
										lineHeight: 1.2
									}}
								>
									TOOLS
								</h2>
								{resume.skills.tools.map((tool, i) => (
									<p key={i} style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>
										{tool}
									</p>
								))}

								<h2
									style={{
										fontSize: `${19 * style.fontSize}px`,
										fontWeight: "bold",
										marginTop: "20px",
										marginBottom: "20px",
										lineHeight: 1.2
									}}
								>
									LANGUAGES
								</h2>
								{resume.skills.languages.map((lang, i) => (
									<p key={i} style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>
										{lang}
									</p>
								))}
							</div>
						</div>

						{/* Right column */}
						<div style={{ flex: "1 1 75%", paddingLeft: "24px" }}>
							<div className="resume-section" onClick={() => handleSectionClick("summary")} style={{ cursor: "pointer" }}>
								<SectionHeader resume={resume} title="PROFILE" section="summary" style={style} />
								<p style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "20px", lineHeight: 1.5 }}>{resume.summary}</p>
							</div>
							<div className="resume-section" onClick={() => handleSectionClick("experience")} style={{ cursor: "pointer" }}>
								<SectionHeader resume={resume} title="WORK EXPERIENCE" section="experience" style={style} />
								{resume.experience.map((exp, i) => (
									<div key={i} style={{ marginBottom: "20px" }}>
										<div
											style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "8px" }}
										>
											<h3 style={{ fontSize: `${17.5 * style.fontSize}px`, fontWeight: "bold", lineHeight: 1.2 }}>
												{exp.jobTitle}
											</h3>
											<p style={{ fontSize: `${16 * style.fontSize}px`, color: "#666666", lineHeight: 1.5 }}>
												{exp.startMonth} {exp.startYear} - {exp.endMonth} {exp.endYear}
											</p>
										</div>
										<p style={{ fontSize: `${16 * style.fontSize}px`, color: "#666666", marginBottom: "8px", lineHeight: 1.5 }}>
											{exp.companyName}
										</p>
										{exp.roles.map((role, j) => (
											<p
												key={j}
												style={{
													fontSize: `${16 * style.fontSize}px`,
													marginBottom: "8px",
													marginLeft: "12px",
													lineHeight: 1.5
												}}
											>
												• {role}
											</p>
										))}
									</div>
								))}
							</div>

							<div className="resume-section" onClick={() => handleSectionClick("projects")} style={{ cursor: "pointer" }}>
								<SectionHeader resume={resume} title="PROJECTS" section="projects" style={style} />
								{resume.projects.map((project, i) => (
									<div key={i} style={{ marginBottom: "20px" }}>
										<div
											style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "8px" }}
										>
											<h3 style={{ fontSize: `${17.5 * style.fontSize}px`, fontWeight: "bold", lineHeight: 1.2 }}>
												{project.projectName}
											</h3>
											<p style={{ fontSize: `${16 * style.fontSize}px`, color: "#666666", lineHeight: 1.5 }}>
												{project.startMonth} {project.startYear} - {project.endMonth} {project.endYear}
											</p>
										</div>
										<p style={{ fontSize: `${16 * style.fontSize}px`, marginBottom: "8px", lineHeight: 1.5 }}>
											{project.description}
										</p>
										{project.notes.map((note, j) => (
											<p
												key={j}
												style={{
													fontSize: `${18 * style.fontSize}px`,
													marginBottom: "8px",
													marginLeft: "12px",
													lineHeight: 1.5
												}}
											>
												• {note}
											</p>
										))}
									</div>
								))}
							</div>

							<div className="resume-section" onClick={() => handleSectionClick("extraCurricular")} style={{ cursor: "pointer" }}>
								<SectionHeader resume={resume} title="EXTRA CURRICULAR" section="extraCurricular" style={style} />
								{resume.extraCurricular.map((activity, i) => (
									<div key={i} style={{ marginBottom: "20px" }}>
										<div
											style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "8px" }}
										>
											<h3 style={{ fontSize: `${17.5 * style.fontSize}px`, fontWeight: "bold", lineHeight: 1.2 }}>
												{activity.activity}
											</h3>
											<p style={{ fontSize: `${16 * style.fontSize}px`, color: "#666666", lineHeight: 1.5 }}>
												{activity.startMonth} {activity.startYear} - {activity.endMonth} {activity.endYear}
											</p>
										</div>
										<p style={{ fontSize: `${16 * style.fontSize}px`, color: "#666666", marginBottom: "8px", lineHeight: 1.5 }}>
											{activity.orgName}
										</p>
										{activity.tasks.map((task, j) => (
											<p
												key={j}
												style={{
													fontSize: `${16 * style.fontSize}px`,
													marginBottom: "8px",
													marginLeft: "12px",
													lineHeight: 1.5
												}}
											>
												• {task}
											</p>
										))}
									</div>
								))}
							</div>
							<div className="resume-section" onClick={() => handleSectionClick("education")} style={{ cursor: "pointer" }}>
								<SectionHeader resume={resume} title="EDUCATION" section="education" style={style} />
								{resume.education.map((edu, i) => (
									<div key={i} style={{ marginBottom: "20px" }}>
										<div
											style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "8px" }}
										>
											<h3 style={{ fontSize: `${17.5 * style.fontSize}px`, fontWeight: "bold", lineHeight: 1.2 }}>
												{edu.degree}
											</h3>
											<p style={{ fontSize: `${16 * style.fontSize}px`, color: "#666666", lineHeight: 1.5 }}>
												Graduating Year: {edu.graduatingYear}
											</p>
										</div>
										<p style={{ fontSize: `${16 * style.fontSize}px`, color: "#666666", marginBottom: "8px", lineHeight: 1.5 }}>
											{edu.school}
										</p>
										{edu.notes.map((note, j) => (
											<p
												key={j}
												style={{
													fontSize: `${16 * style.fontSize}px`,
													marginBottom: "8px",
													marginLeft: "12px",
													lineHeight: 1.5
												}}
											>
												• {note}
											</p>
										))}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ResumeEditForm
				editingSection={editingSection}
				editedData={editedData}
				setEditedData={setEditedData}
				isOpen={isEditFormOpen}
				setIsOpen={setIsEditFormOpen}
				resumeId={resume.id}
				resume={resume}
			/>
		</>
	);
};

export const ModernProfessionalTemplateDocX = (resume: ResumeView, style: ResumeStyleProps) => {
	return new Document({
		styles: {
			paragraphStyles: [
				{
					id: "Normal",
					name: "Normal",
					run: {
						font: "Calibri",
						size: 24
					},
					paragraph: {
						spacing: {
							after: 100,
							line: 300
						}
					}
				}
			]
		},
		sections: [
			{
				properties: {
					page: {
						margin: {
							top: 0,
							right: 720,
							bottom: 720,
							left: 720
						}
					}
				},
				children: [
					new Table({
						width: {
							size: 115,
							type: "pct"
						},
						float: {
							horizontalAnchor: "page",
							absoluteHorizontalPosition: -720
						},
						borders: {
							top: { style: BorderStyle.NONE },
							bottom: { style: BorderStyle.NONE },
							left: { style: BorderStyle.NONE },
							right: { style: BorderStyle.NONE }
						},
						rows: [
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 13680,
											type: "dxa"
										},
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: `${resume.contact.firstName} ${resume.contact.lastName}`,
														size: (48 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri",
														color: "FFFFFF"
													})
												],
												indent: { left: 720 },
												spacing: { before: 300, after: 0 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: resume.jobTitle || "[Job Title]",
														size: (32 / 2) * style.fontSize * 2,
														color: "FFFFFF",
														font: "Calibri",
														italics: true
													})
												],
												indent: { left: 720 },
												spacing: { before: 0, after: 300 }
											})
										],
										shading: {
											fill: `${style.accentColor}`,
											type: "clear",
											color: "auto"
										}
									})
								]
							})
						]
					}),

					// Add some spacing after the header
					new Paragraph({
						children: [new TextRun({ text: "" })],
						spacing: { before: 100, after: 100 }
					}),

					// Two column layout using table
					new Table({
						width: {
							size: 100,
							type: "pct"
						},
						borders: {
							top: { style: BorderStyle.NONE },
							bottom: { style: BorderStyle.NONE },
							left: { style: BorderStyle.NONE },
							right: { style: BorderStyle.NONE },
							insideHorizontal: { style: BorderStyle.NONE },
							insideVertical: { style: BorderStyle.NONE }
						},
						columnWidths: [2500, 7500],
						rows: [
							new TableRow({
								children: [
									// Left column (25%)
									new TableCell({
										width: {
											size: 2500,
											type: "dxa"
										},
										children: [
											// Contact section
											new Paragraph({
												children: [
													new TextRun({
														text: "CONTACT",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { after: 180 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: resume.contact.phone || "",
														size: (24 / 2) * style.fontSize * 2,
														font: "Calibri"
													})
												],
												spacing: { after: 120 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: resume.contact.email || "",
														size: (24 / 2) * style.fontSize * 2,
														font: "Calibri"
													})
												],
												spacing: { after: 120 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: resume.contact.address || "",
														size: (24 / 2) * style.fontSize * 2,
														font: "Calibri"
													})
												],
												spacing: { after: 120 }
											}),
											// new Paragraph({
											// 	children: [
											// 		new TextRun({
											// 			text: resume.contact.website || "",
											// 			size: 24,
											// 			font: "Calibri"
											// 		})
											// 	],
											// 	spacing: { after: 180 }
											// }),

											// Technical Skills section
											new Paragraph({
												children: [
													new TextRun({
														text: "TECHNICAL",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 180, after: 180 }
											}),
											...resume.skills.technical.map(
												(skill) =>
													new Paragraph({
														children: [
															new TextRun({
																text: skill,
																size: (24 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													})
											),

											// Core Competencies section
											new Paragraph({
												children: [
													new TextRun({
														text: "COMPETENCIES",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 180, after: 180 }
											}),
											...resume.skills.coreCompetencies.map(
												(skill) =>
													new Paragraph({
														children: [
															new TextRun({
																text: skill,
																size: (24 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													})
											),

											// Tools section
											new Paragraph({
												children: [
													new TextRun({
														text: "TOOLS",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 180, after: 180 }
											}),
											...resume.skills.tools.map(
												(tool) =>
													new Paragraph({
														children: [
															new TextRun({
																text: tool,
																size: (24 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													})
											),

											// Languages section
											new Paragraph({
												children: [
													new TextRun({
														text: "LANGUAGES",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 180, after: 180 }
											}),
											...resume.skills.languages.map(
												(lang) =>
													new Paragraph({
														children: [
															new TextRun({
																text: lang,
																size: (24 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													})
											)
										],
										margins: {
											right: 360
										}
									}),

									// Right column (75%)
									new TableCell({
										width: {
											size: 7500,
											type: "dxa"
										},
										children: [
											// Profile section
											new Paragraph({
												children: [
													new TextRun({
														text: "PROFILE",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { after: 180 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: resume.summary,
														size: (22 / 2) * style.fontSize * 2,
														font: "Calibri"
													})
												],
												spacing: { after: 180 }
											}),

											// Work Experience section
											new Paragraph({
												children: [
													new TextRun({
														text: "WORK EXPERIENCE",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 180, after: 180 }
											}),
											...resume.experience
												.map((exp) => [
													new Table({
														width: { size: 100, type: "pct" },
														borders: {
															top: { style: BorderStyle.NONE },
															bottom: { style: BorderStyle.NONE },
															left: { style: BorderStyle.NONE },
															right: { style: BorderStyle.NONE },
															insideVertical: { style: BorderStyle.NONE },
															insideHorizontal: { style: BorderStyle.NONE }
														},
														columnWidths: [5500, 4500],
														rows: [
															new TableRow({
																children: [
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: exp.jobTitle,
																						size: (24 / 2) * style.fontSize * 2,
																						bold: true,
																						font: "Calibri"
																					})
																				]
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	}),
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: `${exp.startMonth} ${exp.startYear} - ${exp.endMonth} ${exp.endYear}`,
																						size: (22 / 2) * style.fontSize * 2,
																						color: "666666",
																						font: "Calibri"
																					})
																				],
																				alignment: AlignmentType.RIGHT
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	})
																]
															})
														]
													}),
													new Paragraph({
														children: [
															new TextRun({
																text: exp.companyName,
																size: (22 / 2) * style.fontSize * 2,
																color: "666666",
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													}),
													...exp.roles.map(
														(role) =>
															new Paragraph({
																children: [
																	new TextRun({
																		text: `• ${role}`,
																		size: (22 / 2) * style.fontSize * 2,
																		font: "Calibri"
																	})
																],
																spacing: { before: 120, after: 120 }
															})
													)
												])
												.flat(),

											// Projects section
											new Paragraph({
												children: [
													new TextRun({
														text: "PROJECTS",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 200, after: 180 }
											}),
											...resume.projects
												.map((project) => [
													new Table({
														width: { size: 100, type: "pct" },
														borders: {
															top: { style: BorderStyle.NONE },
															bottom: { style: BorderStyle.NONE },
															left: { style: BorderStyle.NONE },
															right: { style: BorderStyle.NONE },
															insideVertical: { style: BorderStyle.NONE },
															insideHorizontal: { style: BorderStyle.NONE }
														},
														columnWidths: [5500, 4500],
														rows: [
															new TableRow({
																children: [
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: project.projectName,
																						size: (24 / 2) * style.fontSize * 2,
																						bold: true,
																						font: "Calibri"
																					})
																				]
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	}),
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: `${project.startMonth} ${project.startYear} - ${project.endMonth} ${project.endYear}`,
																						size: (22 / 2) * style.fontSize * 2,
																						color: "666666",
																						font: "Calibri"
																					})
																				],
																				alignment: AlignmentType.RIGHT
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	})
																]
															})
														]
													}),
													new Paragraph({
														children: [
															new TextRun({
																text: project.description,
																size: (22 / 2) * style.fontSize * 2,
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													}),
													...project.notes.map(
														(note) =>
															new Paragraph({
																children: [
																	new TextRun({
																		text: `• ${note}`,
																		size: (22 / 2) * style.fontSize * 2,
																		font: "Calibri"
																	})
																],
																spacing: { before: 120, after: 120 }
															})
													)
												])
												.flat(),

											// Extra Curricular section
											new Paragraph({
												children: [
													new TextRun({
														text: "EXTRA CURRICULAR",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 180, after: 180 }
											}),
											...resume.extraCurricular
												.map((activity) => [
													new Table({
														width: { size: 100, type: "pct" },
														borders: {
															top: { style: BorderStyle.NONE },
															bottom: { style: BorderStyle.NONE },
															left: { style: BorderStyle.NONE },
															right: { style: BorderStyle.NONE },
															insideVertical: { style: BorderStyle.NONE },
															insideHorizontal: { style: BorderStyle.NONE }
														},
														rows: [
															new TableRow({
																children: [
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: activity.activity,
																						size: (24 / 2) * style.fontSize * 2,
																						bold: true,
																						font: "Calibri"
																					})
																				]
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	}),
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: `${activity.startMonth} ${activity.startYear} - ${activity.endMonth} ${activity.endYear}`,
																						size: (22 / 2) * style.fontSize * 2,
																						color: "666666",
																						font: "Calibri"
																					})
																				],
																				alignment: AlignmentType.RIGHT
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	})
																]
															})
														]
													}),
													new Paragraph({
														children: [
															new TextRun({
																text: activity.orgName,
																size: (22 / 2) * style.fontSize * 2,
																color: "666666",
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													}),
													...activity.tasks.map(
														(task) =>
															new Paragraph({
																children: [
																	new TextRun({
																		text: `• ${task}`,
																		size: (22 / 2) * style.fontSize * 2,
																		font: "Calibri"
																	})
																],
																spacing: { before: 120, after: 120 }
															})
													)
												])
												.flat(),

											// Education section
											new Paragraph({
												children: [
													new TextRun({
														text: "EDUCATION",
														size: (28 / 2) * style.fontSize * 2,
														bold: true,
														font: "Calibri"
													})
												],
												spacing: { before: 180, after: 180 }
											}),
											...resume.education
												.map((edu) => [
													new Table({
														width: { size: 100, type: "pct" },
														borders: {
															top: { style: BorderStyle.NONE },
															bottom: { style: BorderStyle.NONE },
															left: { style: BorderStyle.NONE },
															right: { style: BorderStyle.NONE },
															insideVertical: { style: BorderStyle.NONE },
															insideHorizontal: { style: BorderStyle.NONE }
														},
														rows: [
															new TableRow({
																children: [
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: edu.degree,
																						size: (24 / 2) * style.fontSize * 2,
																						bold: true,
																						font: "Calibri"
																					})
																				]
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	}),
																	new TableCell({
																		children: [
																			new Paragraph({
																				children: [
																					new TextRun({
																						text: `Graduating Year: ${edu.graduatingYear}`,
																						size: (22 / 2) * style.fontSize * 2,
																						color: "666666",
																						font: "Calibri"
																					})
																				],
																				alignment: AlignmentType.RIGHT
																			})
																		],
																		borders: {
																			top: { style: BorderStyle.NONE },
																			bottom: { style: BorderStyle.NONE },
																			left: { style: BorderStyle.NONE },
																			right: { style: BorderStyle.NONE }
																		}
																	})
																]
															})
														]
													}),
													new Paragraph({
														children: [
															new TextRun({
																text: edu.school,
																size: (22 / 2) * style.fontSize * 2,
																color: "666666",
																font: "Calibri"
															})
														],
														spacing: { after: 120 }
													}),
													...edu.notes.map(
														(note) =>
															new Paragraph({
																children: [
																	new TextRun({
																		text: `• ${note}`,
																		size: (22 / 2) * style.fontSize * 2,
																		font: "Calibri"
																	})
																],
																spacing: { before: 120, after: 120 }
															})
													)
												])
												.flat()
										],
										margins: {
											left: 360
										}
									})
								]
							})
						]
					})
				]
			}
		]
	});
};
