import React, { useEffect, useState, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { SessionView } from "../../types/SessionView";
import { SessionService, VapiCallType } from "../../services/SessionService";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { ChevronDownIcon, ChevronUpIcon, VideoCameraIcon, PhoneIcon, CalendarIcon, PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import { ActivityType } from "../../schemas/ActivityType";
import BackButton from "../Utility/BackButton";
import HorizontalTabs from "../Utility/HorizontalTabs";
import { useAppContext } from "../../contexts/AppContext";
import { Button } from "../shadcn-ui/Button";
import { NavigateService } from "../../services/NavigateService";
import { UserAssignmentView } from "../../types/UserAssignmentView";
import { AssignmentService, JobPosting } from "../../services/AssignmentService";
import JobTitleSelect from "../AdminPages/CreateAssignment/Steps_Interview/JobTitleSelect";
import JobPostings from "../AdminPages/CreateAssignment/Steps_Interview/JobPostings";
import { normalizeCompetencyName } from "../Utility/reportUtility";
import { cn } from "../../lib/utils";
import { assistants } from "../../constants/Assistants";
import { Loader2 } from "lucide-react";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { InfoIcon, LockIcon, FileTextIcon, MicIcon, CheckCircleIcon, XCircleIcon, UserIcon } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../shadcn-ui/Dialog";
import { format } from "date-fns";
import { Report } from "../Utility/Report";
import posthog from "posthog-js";
import { UserCircle } from "lucide-react";
import { isValidJobDetails } from "../../types/InterviewPayload";
import Spinner from "../Utility/Spinner";
import UserInterviewPlan from "./UserInterviewPlan";
import { TabData } from "../Utility/HorizontalTabs";
import { Select, SelectContent, SelectItem, SelectValue, SelectTrigger } from "../shadcn-ui/Select";
import { ResumeView } from "../../types/ResumeView";
import { ResumeService } from "../../services/ResumeService";
import { ExperienceType } from "../../schemas/ExperienceType";

const UserSessionPage: React.FC = () => {
	const { assignmentId, userId, scheduleId } = useParams<{ assignmentId: string; userId: string; scheduleId: string }>();
	const { debugMode, userId: loggedInUserId, checkIfAvailable, activeAssistant, setActiveAssistant, instageUser } = useAppContext();
	const [sessions, setSessions] = useState<SessionView[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isStartingSession, setIsStartingSession] = useState(false);
	const [assignedSessions, setAssignedSessions] = useState<AssignedSessionView[]>([]);
	const [selectedScheduleId, setSelectedScheduleId] = useState<string>(scheduleId || "");
	const [assignment, setAssignment] = useState<UserAssignmentView | null>(null);
	const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
	const [showResumeOptions, setShowResumeOptions] = useState<boolean>(false);
	const [startNewSession, setStartNewSession] = useState<boolean>(true);
	const [isAssistantDropdownOpen, setIsAssistantDropdownOpen] = useState<boolean>(false);
	const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
	const [showConfidentialityDialog, setShowConfidentialityDialog] = useState(false);
	const [pendingAction, setPendingAction] = useState<"web" | "phone" | "schedule" | null>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [showJobPost, setShowJobPost] = useState(false);
	const [jobTitle, setJobTitle] = useState<string>("");
	const [jobDescription, setJobDescription] = useState<string>("");
	const [isGeneratingJob, setIsGeneratingJob] = useState<boolean>(false);
	const [jobPostings, setJobPostings] = useState<JobPosting[]>([]);
	const [isLoadingJobPosting, setIsLoadingJobPosting] = useState(false);
	const [resumes, setResumes] = useState<ResumeView[]>([]);
	const [selectedResumeId, setSelectedResumeId] = useState<string>("");

	const resumeAssistFeatureFlag = useFeatureFlagEnabled("resume-assist");

	const navigate = useNavigate();

	const audioRef = useRef<HTMLAudioElement | null>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const audioElement = audioRef.current;
		const handleEnded = () => setIsPlaying(false);

		if (audioElement) {
			audioElement.addEventListener("ended", handleEnded);
		}

		return () => {
			if (audioElement) {
				audioElement.removeEventListener("ended", handleEnded);
			}
		};
	}, []);

	function getUserName(assignedSessions: AssignedSessionView) {
		if (assignedSessions.firstName && assignedSessions.firstName !== "") {
			return assignedSessions.lastName ? `${assignedSessions.firstName} ${assignedSessions.lastName}` : assignedSessions.firstName;
		}
		return assignedSessions.email?.split("@")[0] || "student"; // TODO FIND WHY IT CAN BE NULL
	}

	const generateReportScoreData = (sessions: SessionView[], currentScheduleId: string) => {
		// TODO move to backend
		// Sort sessions by submittedAt ascending (oldest first)
		const sortedSessions = [...sessions].sort((a, b) => new Date(a.submittedAt).getTime() - new Date(b.submittedAt).getTime());

		// Find the index of the current session
		const currentSessionIndex = sortedSessions.findIndex((session) => session.scheduleId === currentScheduleId);

		if (currentSessionIndex === -1) return { satisfactionData: [], competencyData: {} };

		// Include all sessions up to and including the current one
		const relevantSessions = sortedSessions.slice(0, currentSessionIndex + 1);

		// Extract satisfaction scores
		const satisfactionData = relevantSessions
			.filter((session) => session.satisfaction && typeof session.satisfaction.score === "number")
			.map((session) => session.satisfaction!.score);

		// Extract all unique competency names (case-insensitive and ignore hyphens)
		const competencyNames = Array.from(
			new Set(
				relevantSessions.flatMap((session) =>
					session.competencyProgress && Array.isArray(session.competencyProgress)
						? session.competencyProgress.map((competency) => normalizeCompetencyName(competency.name))
						: []
				)
			)
		);

		// For each competency, extract assessment scores
		const competencyData: { [key: string]: number[] } = {};
		competencyNames.forEach((name) => {
			competencyData[name] = relevantSessions
				.map((session) => {
					const competency = session.competencyProgress?.find((c) => normalizeCompetencyName(c.name) === name);
					return competency && typeof competency.assessment === "number" ? Number(competency.assessment) : null;
				})
				.filter((score): score is number => score !== null);
		});

		return { satisfactionData, competencyData };
	};

	const { satisfactionData, competencyData } = useMemo(() => generateReportScoreData(sessions, selectedScheduleId), [sessions, selectedScheduleId]);

	useEffect(() => {
		if (assignmentId && userId) {
			SessionService.getUserSessionsViewByUserIdAndAssignmentId(userId, assignmentId)
				.then((sessions) => {
					setSessions(sessions);
					// console.log("sessionsViews", sessions);
					if (debugMode) {
						console.log("sessionsViews", sessions);
					}
					setIsLoading(false);
				})
				.catch((error) => {
					console.error("Error getting user session view:", error);
					setIsLoading(false);
				});
		}
		if (assignmentId && userId) {
			AssignedSessionService.getByAssignmentIdAndUserId(assignmentId, userId)
				.then((assignedSessions) => {
					setAssignedSessions(assignedSessions);
					// 	console.log("assignedSessions", assignedSessions);
					// Set the selectedScheduleId to the first session's scheduleId if not set
					if (!scheduleId && assignedSessions.length > 0) {
						setSelectedScheduleId(assignedSessions[0].scheduleId);
					}
					if (debugMode) {
						console.log("assignedSessions", assignedSessions);
					}
				})
				.catch((error) => {
					console.error("Error getting user assigned sessions:", error);
				});
		}
		if (assignmentId && userId) {
			// console.log("assignmentId", assignmentId);
			// console.log("userId", userId);
			AssignmentService.getUserViewById(assignmentId)
				.then((assignment) => {
					if (debugMode) {
						console.log("assignment", assignment);
					}

					setAssignment(assignment);
				})
				.catch((error) => {
					console.error("Error getting assignment:", error);
				});
		}
	}, [assignmentId, userId, scheduleId]);

	useEffect(() => {
		// Reset resume options when selected schedule changes
		setShowResumeOptions(false);
	}, [selectedScheduleId]);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsAssistantDropdownOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const getOverwrittenStatus = (session: AssignedSessionView, index: number): AssignedSessionStatus => {
		const prevSubmitted = index > 0 ? assignedSessions[index - 1].status === AssignedSessionStatus.SUBMITTED : true;
		let overwriteStatus = session.status;
		if (session.status === AssignedSessionStatus.AVAILABLE && !prevSubmitted) {
			// 	overwriteStatus = AssignedSessionStatus.NOT_AVAILABLE;
		}
		return overwriteStatus;
	};

	const tabs: TabData[] = assignedSessions
		?.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((session, index) => {
			const overwriteStatus = getOverwrittenStatus(session, index);

			return {
				label: session.scheduleDescription,
				status: overwriteStatus,
				date: session.status === AssignedSessionStatus.SUBMITTED ? session.submittedAt : session.date,
				scheduleId: session.scheduleId,
				activityType: session.activityType,
				interviewStageOrderNumber: session.interviewStageOrderNumber ?? undefined,
				interviewStageType: session.interviewStageType ?? undefined,
				frontlineStageOrderNumber: session.frontlineStageOrderNumber ?? undefined,
				frontlineStageType: session.frontlineStageType ?? undefined
			};
		});

	// Find the index of the selected scheduleId in the assignedSessions array
	const selectedTab = assignedSessions.findIndex((session) => session.scheduleId === selectedScheduleId) || 0;

	// Assigned session data
	const selectedAssignedSession = assignedSessions.find((session) => session.scheduleId === selectedScheduleId);

	// Attempted session data
	const selectedUserSession = sessions.find((session) => session.scheduleId === selectedScheduleId) || null;

	const isJobGenerated = selectedAssignedSession ? isValidJobDetails(selectedAssignedSession) : false;

	useEffect(() => {
		if (selectedAssignedSession && debugMode) {
			console.log("selectedAssignedSession", selectedAssignedSession);
		}
	}, [selectedAssignedSession]); // Log only when selectedAssignedSession changes

	useEffect(() => {
		if (selectedUserSession && debugMode) {
			console.log("selectedUserSession", selectedUserSession);
		}
	}, [selectedUserSession]);

	const handleGenerateJobDetails = async () => {
		if (!assignmentId || !jobTitle.trim() || !jobDescription.trim()) {
			return;
		}

		setIsGeneratingJob(true);
		try {
			await AssignmentService.generateJobDetails(assignmentId, {
				jobTitle: jobTitle.trim(),
				jobDescription: jobDescription.trim()
			});
			// Refresh the assignment data
			const updatedAssignment = await AssignmentService.getUserViewById(assignmentId!);
			setAssignment(updatedAssignment);

			// Refresh assigned sessions to get updated job details
			const updatedAssignedSessions = await AssignedSessionService.getByAssignmentIdAndUserId(assignmentId!, userId!);
			setAssignedSessions(updatedAssignedSessions);
		} catch (error) {
			console.error("Error generating job details:", error);
		} finally {
			setIsGeneratingJob(false);
		}
	};

	const searchJobs = async (title: string) => {
		setJobPostings([]);
		try {
			const jobPostingList = await AssignmentService.searchJobPostings(title);
			setJobPostings(jobPostingList);
		} catch (error) {
			console.error("Error fetching job postings:", error);
			setJobPostings([]);
		}
	};

	const handleJobSelection = async (option: JobPosting) => {
		setJobTitle(option.title);

		if (option.postingId === "custom") {
			setJobDescription(option.summary || "");
		} else {
			try {
				setIsLoadingJobPosting(true);
				const fullJobDetails = await AssignmentService.getJobPosting(option.postingId);
				const description = htmlToPlainText(fullJobDetails.jobBody || "") || option.summary || "";
				setJobDescription(description);
			} catch (error) {
				console.error("Error fetching full job details:", error);
				setJobDescription(option.summary || "");
			} finally {
				setIsLoadingJobPosting(false);
			}
		}
	};

	const htmlToPlainText = (html: string) => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = html;

		const text = tempElement.innerHTML
			.replace(/&nbsp;/g, " ")
			.replace(/<\/p>/g, "\n\n")
			.replace(/<\/li>/g, "\n")
			.replace(/<li>/g, "- ")
			.replace(/<br>/g, "\n")
			.replace(/<strong>/g, "*")
			.replace(/<\/strong>/g, "*")
			.replace(/\n\s*\n/g, "\n\n")
			.replace(/&amp;/g, "&")
			.replace(/<\/?[^>]+(>|$)/g, "");
		return text.trim();
	};

	useEffect(() => {
		// fetch user resumes for the select drop down if the assignment type is interview
		const fetchUserResumes = async () => {
			if (!userId) return;
			try {
				const response = await ResumeService.fetchUserResumes();
				setResumes(response);
			} catch (error) {
				console.error("Error fetching user resumes:", error);
			}
		};
		if (assignment?.experienceType === ExperienceType.INTERVIEW) {
			fetchUserResumes();
		}
	}, [userId, assignment?.experienceType]);

	if (isLoading || assignedSessions.length === 0) {
		return <div>Loading...</div>;
	}

	async function initiateSession(mode: "phone" | "web" | "schedule") {
		setShowConfidentialityDialog(true);
		setPendingAction(mode);
		checkMicrophonePermission();
	}

	async function checkMicrophonePermission() {
		const permission = await navigator.permissions.query({ name: "microphone" as PermissionName });

		if (permission.state === "granted") {
			return true;
		} else if (permission.state === "prompt") {
			// Request microphone access
			try {
				await navigator.mediaDevices.getUserMedia({ audio: true });
				// console.log("Microphone access granted");
				posthog.capture("MINOR: Microphone Permission Granted", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
				return true; // Access granted
			} catch (error) {
				// console.error("Microphone access denied:", error);
				posthog.capture("MINOR: Microphone Permission Denied", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
				return false; // Access denied
			}
		}
		console.error("Microphone access denied:");
		return false; // Access denied or not granted
	}

	async function startSession() {
		setShowConfidentialityDialog(false);

		if (pendingAction === "phone") {
			try {
				posthog.capture("MAJOR_EVENT: Starting Phone Call Event", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MAJOR_EVENT: Starting Phone Call Event':", err);
			}
			await startPhoneCall();
		} else if (pendingAction === "web") {
			try {
				console.log("Capturing Starting Web Call Event");
				posthog.capture("MAJOR_EVENT: Starting Web Call Event", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MAJOR_EVENT: Starting Web Call':", err);
			}
			await startWebCall();
		} else if (pendingAction === "schedule") {
			if (!selectedAssignedSession?.assignedSessionId) return;
			NavigateService.navToScheduleCall(selectedAssignedSession?.assignedSessionId);
		}

		setPendingAction(null);
	}

	async function startWebCall() {
		setIsStartingSession(true);
		setShowConfidentialityDialog(false);

		try {
			if (!loggedInUserId || !assignmentId) return;

			// // Check if the user needs onboarding
			// if (needsOnboarding) {
			// 	setShowOnboardingDialog(true);
			// 	return;
			// }
			let sessionId = null;
			let session: SessionView | null = null;

			if (!startNewSession && selectedAssignedSession?.sessionId) {
				sessionId = selectedAssignedSession?.sessionId;
			} else {
				const result = await SessionService.startSession(
					loggedInUserId,
					VapiCallType.WebCall,
					assignmentId,
					selectedScheduleId,
					activeAssistant.voice_provider,
					activeAssistant.voice_id,
					activeAssistant.name
				);
				sessionId = result.sessionId;
				session = result;
			}

			const isAvailable = await checkIfAvailable(loggedInUserId);
			console.log("isAvailable", isAvailable);
			if (isAvailable) {
				NavigateService.navToStartSession(assignmentId, loggedInUserId, sessionId);
				// try {
				// 	if (!session || !session.clientId) {
				// 		return;
				// 	}
				// 	const client = await UserService.getClientById(session.clientId);
				// } catch (error) {
				// 	console.error("Error fetching client by ID:", error);
				// }
				try {
					// console.log("Capturing Started Web Call Event");
					// console.log("instageUser", instageUser);
					// console.log("instageUser?.fronteggId", instageUser?.fronteggId);
					// console.log("instageUser?.tenantId", instageUser?.tenantId);
					const printObject = {
						fronteggId: instageUser?.fronteggId || "", // need frontegg id
						userId: instageUser?.id || "",
						tenantId: instageUser?.tenantId || "", // need tenant id
						clientId: instageUser?.clientId || "",
						timestamp: new Date().toISOString(),
						sessionId: session?.sessionId || "",
						assignmentId: session?.assignmentId || "",
						activityType: session?.activityType || "",
						plGroupId: session?.plGroupId?.toString() || "",
						scheduleId: session?.scheduleId || "",
						sessionType: session?.sessionType || "",
						experienceType: session?.experienceType || ""
					};
					// console.log("capture data: ", JSON.stringify(printObject, null, 2));
					posthog.capture("MAJOR_EVENT: Started Web Call", printObject);
				} catch (err) {
					console.error("Error capturing event 'M.E. Started Web Call':", err);
				}
			} else {
				try {
					posthog.capture("MAJOR_EVENT: Web Call Full. Added To Queue", {
						userId: instageUser?.fronteggId,
						timestamp: new Date().toISOString(),
						sessionId
					});
				} catch (err) {
					console.error("Error capturing event 'M.E. Web Call Full. Added To Queue':", err);
				}
				NavigateService.navToQueuePage(assignmentId, loggedInUserId, sessionId);
			}
		} catch (error) {
			console.error("Failed to start session:", error);
			// Handle error (e.g., show an error message to the user)
		} finally {
			setIsStartingSession(false);
		}
	}
	async function startPhoneCall() {
		// if (needsOnboarding) {
		// 	setShowOnboardingDialog(true);
		// 	return;
		// } else {
		if (!loggedInUserId || !assignmentId) return;
		let sessionId = null;
		let session: SessionView | null = null;
		console.log("startPhoneCall");

		if (!startNewSession && selectedAssignedSession?.sessionId) {
			sessionId = selectedAssignedSession?.sessionId;
		} else {
			const result = await SessionService.startSession(
				loggedInUserId,
				VapiCallType.OutboundPhoneCall,
				assignmentId,
				selectedScheduleId,
				activeAssistant.voice_provider,
				activeAssistant.voice_id,
				activeAssistant.name
			);
			sessionId = result.sessionId;
			session = result;
		}

		try {
			// console.log("Capturing Started Web Call Event");
			// console.log("instageUser", instageUser);
			// console.log("instageUser?.fronteggId", instageUser?.fronteggId);
			// console.log("instageUser?.tenantId", instageUser?.tenantId);
			const printObject = {
				fronteggId: instageUser?.fronteggId || "", // need frontegg id
				userId: instageUser?.id || "",
				tenantId: instageUser?.tenantId || "", // need tenant id
				clientId: instageUser?.clientId || "",
				timestamp: new Date().toISOString(),
				sessionId: session?.sessionId || "",
				assignmentId: session?.assignmentId || "",
				activityType: session?.activityType || "",
				plGroupId: session?.plGroupId?.toString() || "",
				scheduleId: session?.scheduleId || "",
				sessionType: session?.sessionType || "",
				experienceType: session?.experienceType || ""
			};
			// console.log("capture data: ", JSON.stringify(printObject, null, 2));
			posthog.capture("MAJOR_EVENT: Started Phone Call", printObject);
		} catch (err) {
			console.error("Error capturing event 'M.E. Started Phone Call':", err);
		}

		NavigateService.navToPhoneCallSession(sessionId);
		// }
	}

	async function regenerateSummary() {
		const currentSelectedSession = sessions.find((session) => session.scheduleId === selectedScheduleId);
		if (!currentSelectedSession) return;

		setIsRegenerating(true);
		try {
			const updatedSession = await SessionService.regenerateSummary(currentSelectedSession.sessionId);

			// Update the sessions array with the new session data
			setSessions((prevSessions) => prevSessions.map((session) => (session.sessionId === updatedSession.sessionId ? updatedSession : session)));
		} catch (error) {
			console.error("Error regenerating summary:", error);
			// Handle error (e.g., show an error message to the user)
		} finally {
			setIsRegenerating(false);
		}
	}

	const handleAssistantChange = (value: string) => {
		const assistant = assistants.find((a) => a.id === value);
		if (assistant) {
			setActiveAssistant(assistant);
			localStorage.setItem("selectedAssistantId", assistant.id);

			// Play the assistant's audio
			if (audioRef.current) {
				audioRef.current.src = assistant.audio;
				audioRef.current.play().catch((error) => console.error("Error playing audio:", error));
			}
		}
	};

	function renderSessionMessage(status: AssignedSessionStatus) {
		if (debugMode && (status === AssignedSessionStatus.PAST_DUE || status === AssignedSessionStatus.PENDING)) {
			return "";
		}
		switch (status) {
			case AssignedSessionStatus.PAST_DUE:
				return "Sorry, this call is past due. Please contact your instructor to see if you can get an extension.";
			case AssignedSessionStatus.AVAILABLE:
				return "";
			case AssignedSessionStatus.NOT_ATTEMPTED:
				return "This call is past due. You can still complete it, but it will be marked as late.";
			// case "pending":
			// 	return "This call is not available yet. Please come back later.";
			default:
				return "No data available for this call";
		}
	}

	function checkMinutesAvailability(soloMode: boolean) {
		if (instageUser && soloMode) {
			// Check if the user has used 3x their self-practice call time limit
			if (instageUser.selfServeMinutesUsed >= instageUser.selfServeCallTimeLimit * 3) {
				return (
					<div className="flex flex-col items-center justify-center py-12 rounded-lg border-gray-300 border bg-gray-50">
						<div className="max-w-md text-center space-y-3">
							<h3 className="text-xl font-semibold text-warning">Self-Practice Time Limit Reached</h3>
							<p className="text-gray-600">You have used all your available self-practice call minutes.</p>
							<p className="text-gray-600">Please contact your instructor or administrator to request additional time.</p>
						</div>
					</div>
				);
			}
		}
		// Check if the user has used 3x their assignment call time limit
		else if (instageUser && instageUser.assignmentMinutesUsed >= instageUser.assignmentCallTimeLimit * 3) {
			return (
				<div className="flex flex-col items-center justify-center py-12 rounded-lg border-gray-300 border bg-gray-50">
					<div className="max-w-md text-center space-y-3">
						<h3 className="text-xl font-semibold text-warning">Assignment Time Limit Reached</h3>
						<p className="text-gray-600">You have used all your available assignment call minutes.</p>
						<p className="text-gray-600">Please contact your instructor or administrator to request additional time.</p>
					</div>
				</div>
			);
		}
		return null;
	}

	function renderStartOptions(selectedAssignedSession: AssignedSessionView) {
		// For interviews, if job details aren't generated yet, don't show the start button
		if (selectedAssignedSession.interviewStageType !== null && !isJobGenerated) {
			return null;
		}

		// Check minutes availability
		const minutesCheck = checkMinutesAvailability(selectedAssignedSession.soloMode);
		if (minutesCheck) return minutesCheck;

		return (
			<div className="flex flex-col items-center justify-center py-12 rounded-lg border-gray-300 border">
				{(selectedAssignedSession?.status === AssignedSessionStatus.AVAILABLE ||
					selectedAssignedSession?.status === AssignedSessionStatus.NOT_ATTEMPTED ||
					debugMode) && (
					<div className="text-center mb-2 text-[#16013E] text-xl md:text-2xl font-bold inline-flex items-center relative">
						<span>Start call with</span>
						<div className="relative inline-block">
							<button
								onClick={() => setIsAssistantDropdownOpen(!isAssistantDropdownOpen)}
								className="ml-2 text-[#00a9af] underline inline-flex items-center"
							>
								{activeAssistant.name}
								<ChevronDownIcon className="h-4 w-4 ml-1" />
							</button>
							{isAssistantDropdownOpen && (
								<div ref={dropdownRef} className="absolute left-0 mt-2 py-0 w-48 bg-white rounded-md shadow-xl z-20 max-w-24">
									{assistants.map((assistant) => (
										<button
											key={assistant.id}
											className={cn(
												"block w-full text-left px-4 py-2 text-base", // Changed from text-sm to text-base
												activeAssistant.id === assistant.id
													? "bg-[#00a9af] text-white font-semibold hover:bg-[#00a9af] hover:text-white"
													: "text-gray-700 hover:bg-gray-100"
											)}
											onClick={() => {
												handleAssistantChange(assistant.id);
												setIsAssistantDropdownOpen(false);
											}}
										>
											{assistant.name}
										</button>
									))}
								</div>
							)}
						</div>
						<button
							onClick={playAssistantAudio}
							className="h-6 w-6 rounded-full ml-2 flex items-center justify-center"
							style={{ background: "linear-gradient(90deg, #DB4F86 -19.25%, #00A9AF 152.17%)" }}
							aria-label={isPlaying ? "Pause assistant audio" : "Play assistant audio"}
						>
							{isPlaying ? <PauseIcon className="h-3 w-3 text-white" /> : <PlayIcon className="h-3 w-3 text-white" />}
						</button>
					</div>
				)}
				{selectedAssignedSession.frontlineStudentRole && selectedAssignedSession.frontlineStudentRole !== "" && (
					<p className="text-lg text-gray-600 mb-4 text-center">
						You're playing the role of {selectedAssignedSession.frontlineStudentRole} and {activeAssistant.name} is a{" "}
						{selectedAssignedSession.frontlineAssistantRole}.
					</p>
				)}
				{selectedAssignedSession?.status !== AssignedSessionStatus.PENDING && (
					<p className="text-lg text-gray-600 mb-4 text-center w-11/12 sm:w-1/2 max-w-sm">
						{renderSessionMessage(selectedAssignedSession.status)}
					</p>
				)}
				{(selectedAssignedSession?.status === AssignedSessionStatus.AVAILABLE ||
					selectedAssignedSession?.status === AssignedSessionStatus.NOT_ATTEMPTED ||
					debugMode) && (
					<div className="flex flex-col gap-4 w-11/12 sm:w-1/2 max-w-sm">
						<Button className="h-12" variant="default" size="lg" onClick={() => initiateSession("web")} disabled={isStartingSession}>
							{isStartingSession ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <VideoCameraIcon className="mr-2 h-5 w-5" />}
							{isStartingSession ? "Starting..." : "Start web call now"}
						</Button>
						<Button className="h-12" variant="outline" size="lg" onClick={() => initiateSession("phone")}>
							<PhoneIcon className="mr-2 h-5 w-5" /> Call me now
						</Button>
					</div>
				)}
				{(selectedAssignedSession?.status === AssignedSessionStatus.AVAILABLE ||
					selectedAssignedSession?.status === AssignedSessionStatus.PENDING ||
					selectedAssignedSession?.status === AssignedSessionStatus.NOT_ATTEMPTED ||
					debugMode) && (
					<>
						{selectedAssignedSession.scheduledCallBookingId && selectedAssignedSession.scheduledCallTime ? (
							<div className="flex flex-col gap-4 w-11/12 sm:w-1/2 max-w-sm">
								<p className="text-center text-[#16013E] text-xl md:text-2xl font-bold mt-12">Scheduled call</p>
								<div className="space-y-4">
									<div className="h-12 flex items-center justify-center bg-gray-100 rounded-md px-4">
										<CalendarIcon className="mr-2 h-5 w-5 text-gray-600" />
										<span className="text-lg text-gray-800">
											{format(new Date(selectedAssignedSession.scheduledCallTime), "MMM d 'at' h:mm a")}
										</span>
									</div>
									{selectedAssignedSession.attendeeSeatId && (
										<Button
											className="w-full h-12"
											variant="outline"
											size="lg"
											onClick={() => {
												NavigateService.navToManageBooking(
													selectedAssignedSession.scheduledCallBookingId!,
													selectedAssignedSession.attendeeSeatId!
												);
												const printObject = {
													fronteggId: instageUser?.fronteggId || "",
													userId: instageUser?.id || "",
													tenantId: instageUser?.tenantId || "",
													clientId: instageUser?.clientId || "",
													timestamp: new Date().toISOString(),
													sessionId: selectedAssignedSession?.sessionId || "",
													assignmentId: selectedAssignedSession?.assignmentId || "",
													activityType: selectedAssignedSession?.activityType || "",
													scheduleId: selectedAssignedSession?.scheduleId || ""
												};
												posthog.capture("MAJOR_EVENT: Manage Booking", printObject);
											}}
										>
											<span>Manage Booking</span>
										</Button>
									)}
								</div>
							</div>
						) : (
							<Button
								className="w-11/12 sm:w-1/2 max-w-sm h-12 mt-4"
								variant={selectedAssignedSession?.status === "available" ? "outline" : "default"}
								size="lg"
								onClick={() => initiateSession("schedule")}
							>
								<CalendarIcon className="mr-2 h-5 w-5" /> Schedule a call
							</Button>
						)}
					</>
				)}
			</div>
		);
	}

	function renderRestartOptions(selectedAssignedSession: AssignedSessionView) {
		// Check minutes availability first
		const minutesCheck = checkMinutesAvailability(selectedAssignedSession.soloMode);
		if (minutesCheck) return minutesCheck;

		return (
			<div className="flex flex-col items-center justify-center py-12 rounded-lg gap-6 border-gray-300 border">
				<div className="text-center mb-2 text-[#16013E] text-xl md:text-2xl font-bold inline-flex items-center relative">
					<span>Restart call with</span>
					<div className="relative inline-block">
						<button
							onClick={() => setIsAssistantDropdownOpen(!isAssistantDropdownOpen)}
							className="ml-2 text-[#00a9af] underline inline-flex items-center"
						>
							{activeAssistant.name}
							<ChevronDownIcon className="h-4 w-4 ml-1" />
						</button>
						{isAssistantDropdownOpen && (
							<div ref={dropdownRef} className="absolute left-0 mt-2 py-0 w-48 bg-white rounded-md shadow-xl z-20 max-w-24">
								{assistants.map((assistant) => (
									<button
										key={assistant.id}
										className={cn(
											"block w-full text-left px-4 py-2 text-base", // Changed from text-sm to text-base
											activeAssistant.id === assistant.id
												? "bg-[#00a9af] text-white font-semibold hover:bg-[#00a9af] hover:text-white"
												: "text-gray-700 hover:bg-gray-100"
										)}
										onClick={() => {
											handleAssistantChange(assistant.id);
											setIsAssistantDropdownOpen(false);
										}}
									>
										{assistant.name}
									</button>
								))}
							</div>
						)}
					</div>
					<button
						onClick={playAssistantAudio}
						className="h-6 w-6 rounded-full ml-2 flex items-center justify-center"
						style={{ background: "linear-gradient(90deg, #DB4F86 -19.25%, #00A9AF 152.17%)" }}
						aria-label={isPlaying ? "Pause assistant audio" : "Play assistant audio"}
					>
						{isPlaying ? <PauseIcon className="h-3 w-3 text-white" /> : <PlayIcon className="h-3 w-3 text-white" />}
					</button>
				</div>
				{selectedAssignedSession.frontlineStudentRole && selectedAssignedSession.frontlineStudentRole !== "" && (
					<p className="text-lg text-gray-600 mb-4 text-center">
						You're playing the role of {selectedAssignedSession.frontlineStudentRole} and {activeAssistant.name} is a{" "}
						{selectedAssignedSession.frontlineAssistantRole}.
					</p>
				)}
				<p className="text-sm text-[#DB4F86] mb-8 text-center">Note: This will delete your current summary.</p>
				<Button
					className="w-11/12 sm:w-1/2 max-w-sm h-12"
					variant="default"
					size="lg"
					onClick={() => initiateSession("web")}
					disabled={isStartingSession}
				>
					{isStartingSession ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <VideoCameraIcon className="mr-2 h-5 w-5" />}
					{isStartingSession ? "Starting..." : "Restart Web Call Now"}
				</Button>
				<Button className="w-11/12 sm:w-1/2 max-w-sm h-12" variant="outline" size="lg" onClick={() => initiateSession("phone")}>
					<PhoneIcon className="mr-2 h-5 w-5" /> Restart Phone Call
				</Button>
				{selectedAssignedSession.scheduledCallBookingId && selectedAssignedSession.scheduledCallTime ? (
					<div className="w-11/12 sm:w-1/2 max-w-sm h-12 flex items-center justify-center bg-gray-100 rounded-md">
						<CalendarIcon className="mr-2 h-5 w-5 text-gray-600" />
						<span className="text-gray-800 font-medium">
							Scheduled: {format(new Date(selectedAssignedSession.scheduledCallTime), "MMM d, yyyy 'at' h:mm a")}
						</span>
					</div>
				) : (
					<Button className="w-11/12 sm:w-1/2 max-w-sm h-12" variant="outline" size="lg" onClick={() => initiateSession("schedule")}>
						<CalendarIcon className="mr-2 h-5 w-5" /> Restart by Scheduling a Call
					</Button>
				)}
				<Button
					className="w-11/12 sm:w-1/2 max-w-sm h-12"
					variant="outlineWarning"
					size="lg"
					onClick={() => {
						setShowResumeOptions(false);
						setStartNewSession(false);
					}}
				>
					Cancel
				</Button>
			</div>
		);
	}

	function renderJobDetails() {
		if (!selectedAssignedSession) return null;

		// If it's not an interview, don't show anything
		if (selectedAssignedSession.interviewStageType === null) return null;

		// If job details haven't been generated yet, show the generate button
		if (!isJobGenerated) {
			return (
				<div className="mt-12">
					<h2 className="text-xl md:text-2xl font-semibold mb-4">Job Details</h2>
					<Card className="mb-6">
						<CardContent className="space-y-4">
							<div>
								<label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700 mb-1">
									Job Title *
								</label>
								<JobTitleSelect
									value={jobTitle}
									onChange={(value) => {
										setJobTitle(value);
										searchJobs(value);
									}}
								/>
								<JobPostings jobTitle={jobTitle} options={jobPostings} onSelect={handleJobSelection} />
							</div>
							<div>
								<label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700 mb-1">
									Job Description *
								</label>
								{isLoadingJobPosting ? (
									<Spinner />
								) : (
									<textarea
										id="jobDescription"
										value={jobDescription}
										onChange={(e) => setJobDescription(e.target.value)}
										rows={8}
										className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-3 text-gray-900"
										placeholder="Enter the job description..."
										required
									/>
								)}
							</div>
							<Button
								onClick={handleGenerateJobDetails}
								disabled={!jobTitle.trim() || !jobDescription.trim() || isGeneratingJob}
								className="w-full"
							>
								{isGeneratingJob ? (
									<>
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
										Generating Job Details...
									</>
								) : (
									"Generate Job Details"
								)}
							</Button>
						</CardContent>
					</Card>
				</div>
			);
		}

		const jobSections = [
			{
				title: "Job Function",
				content: selectedAssignedSession?.jobFunction,
				type: "text"
			},
			{
				title: "Experience Level",
				content: selectedAssignedSession?.jobExperienceLevel,
				type: "text"
			},
			{
				title: "Required Skills",
				content: selectedAssignedSession?.jobSkillsRequired,
				type: "list"
			},
			{
				title: "Key Responsibilities",
				content: selectedAssignedSession?.jobResponsibilities,
				type: "list"
			}
		];

		return (
			<>
				<h2 className="text-xl md:text-2xl font-semibold mt-12 mb-4">Job Details</h2>
				<Card className="my-6">
					<CardHeader>
						<CardTitle className="text-lg font-semibold text-[#16013e]">Summary</CardTitle>
					</CardHeader>
					<CardContent className="pt-0">
						<p className="text-gray-600">{selectedAssignedSession.jobSummary}</p>
						<Button variant="ghost" size="sm" className="p-0 mt-4 text-[#16013e]" onClick={() => setShowJobPost(!showJobPost)}>
							<span>{showJobPost ? "Show Less" : "Show More"}</span>
							{showJobPost ? <ChevronUpIcon className="h-4 w-4 ml-1" /> : <ChevronDownIcon className="h-4 w-4 ml-1" />}
						</Button>
					</CardContent>
				</Card>
				{showJobPost && (
					<div className="space-y-6">
						{jobSections.map((section, index) => {
							// Skip rendering if content is empty or undefined
							if (!section.content || (Array.isArray(section.content) && section.content.length === 0)) {
								return null;
							}

							return (
								<Card key={index}>
									<CardHeader>
										<CardTitle className="text-lg font-semibold text-[#16013e]">{section.title}</CardTitle>
									</CardHeader>
									<CardContent className="pt-0">
										{section.type === "text" ? (
											<p className="text-gray-600">{section.content as string}</p>
										) : (
											<ul className="list-disc pl-5 space-y-1">
												{(section.content as string[]).map((item, idx) => (
													<li key={idx} className="text-gray-600">
														{item}
													</li>
												))}
											</ul>
										)}
									</CardContent>
								</Card>
							);
						})}
					</div>
				)}
			</>
		);
	}

	// const shouldShowResumeButton = (session: SessionView, assignedSession: AssignedSessionView) => {
	// 	if (!session) return false;

	// 	const thresholdPercentage = 80;

	// 	return (
	// 		assignedSession.status !== "completed" &&
	// 		session.reviewPercentage < thresholdPercentage &&
	// 		session.endedReason !== VapiCallEndedReason.AssistantSaidEndCallPhrase
	// 	);
	// };

	const shouldShowRestartButton = (assignedSession: AssignedSessionView, index: number) => {
		const overwriteStatus = getOverwrittenStatus(assignedSession, index);
		return (
			overwriteStatus === AssignedSessionStatus.IN_PROGRESS ||
			overwriteStatus === AssignedSessionStatus.UNSUBMITTED ||
			overwriteStatus === AssignedSessionStatus.INCOMPLETE
		);
	};

	const handleTabSelect = (scheduleId: string) => {
		setSelectedScheduleId(scheduleId);
		// The useEffect above will handle resetting showResumeOptions
	};

	const playAssistantAudio = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
				setIsPlaying(false);
			} else {
				audioRef.current.src = activeAssistant.audio;
				audioRef.current
					.play()
					.then(() => {
						setIsPlaying(true);
					})
					.catch((error) => console.error("Error playing audio:", error));
			}
		}
	};

	return (
		<div className="h-full pb-12">
			<BackButton />
			<div className="flex flex-col gap-4 mb-4">
				<h2 className="text-2xl md:text-3xl font-bold text-[#16013e] flex items-center">
					"{assignment?.assignmentTitle}" {selectedAssignedSession?.interviewStageType ? "Stages" : "Calls"}
					{selectedAssignedSession?.soloMode && (
						<span className="ml-3 inline-flex items-center px-2 py-1 text-sm font-medium rounded bg-purple-100 text-purple-800 border border-purple-200 align-middle">
							<UserCircle className="w-4 h-4 mr-1" />
							Self Practice
						</span>
					)}
				</h2>

				{/* If the assignment type is interview, show the resume select dropdown */}
				{assignment?.experienceType === ExperienceType.INTERVIEW && resumeAssistFeatureFlag && (
					<div className="w-[250px]">
						<Select
							value={selectedResumeId}
							onValueChange={(value) => {
								if (value === "create-new") {
									navigate("/user/resume-assist");
								} else {
									setSelectedResumeId(value);
								}
							}}
						>
							<SelectTrigger>
								<SelectValue placeholder="Select a Resume" />
							</SelectTrigger>
							<SelectContent>
								{resumes.map((resume) => (
									<SelectItem key={resume.id} value={resume.id}>
										{resume.title || "Untitled Resume"}
									</SelectItem>
								))}
								<SelectItem className="border-t border-gray-200" value="create-new">
									Create New Resume
								</SelectItem>
							</SelectContent>
						</Select>
					</div>
				)}
				<p>
					{assignment?.includeUserIdentification && (
						<>
							<span className="font-semibold">For: </span>
							<span className="font-normal">{assignedSessions[0].email}</span>
						</>
					)}
				</p>
			</div>

			{/* {!selectedAssignedSession?.soloMode && (
				<Button variant="ghost" size="sm" className="p-0 mb-4" onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
					{isDetailsVisible ? "Hide Assignment Details" : "Show Assignment Details"}
					{isDetailsVisible ? <ChevronUpIcon className="h-4 w-4 ml-1" /> : <ChevronDownIcon className="h-4 w-4 ml-1" />}
				</Button>
			)} */}

			{assignment && (
				<div className="text-gray-600 mb-8">
					<p className="mb-2 text-sm font-semibold">
						<span className="font-semibold">Course: </span>
						<span className="font-normal">{assignment.courseName}</span>
					</p>
					<p className="text-sm">
						<span className="font-semibold">Description: </span>
						{assignment.assignmentDescription}
					</p>
					{debugMode && (
						<>
							<p className="text-sm mt-2">scheduleId: {selectedScheduleId}</p>
							<p className="text-sm">assignmentId: {assignmentId}</p>
							<p className="text-sm">userId: {userId}</p>
							{debugMode && selectedAssignedSession && selectedAssignedSession?.status && (
								<div>status: {selectedAssignedSession?.status}</div>
							)}
							{selectedUserSession && (
								<p className="text-sm">
									plGroupIdUrl:{" "}
									<a className="underline text-blue-600" target="_blank" rel="noreferrer" href={selectedUserSession.plGroupIdUrl}>
										{selectedUserSession.plGroupIdUrl}
									</a>
								</p>
							)}
						</>
					)}
				</div>
			)}
			<div>
				{assignmentId && userId && (
					<HorizontalTabs
						tabs={tabs}
						type="user"
						selectedTab={selectedTab}
						assignmentId={assignmentId}
						userId={userId}
						onTabSelect={handleTabSelect}
					/>
				)}
				<Dialog open={showConfidentialityDialog} onOpenChange={setShowConfidentialityDialog}>
					<DialogContent className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle className="flex items-center gap-2 text-xl">
								<LockIcon className="h-6 w-6 text-gray-500" />
								Confidentiality and Data Sharing
							</DialogTitle>
						</DialogHeader>
						<div className="py-4">
							<h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
								<InfoIcon className="h-5 w-5 text-gray-500" />
								Confidentiality Reminder
							</h3>
							<p className="mb-4 text-sm text-gray-600">
								It is your responsibility to protect the privacy and confidentiality of your employer, colleagues, and your own
								personal information. <span className="font-semibold">Do not disclose any sensitive details</span> related to the
								company, its operations, employees, or your own personal information without explicit permission.
							</p>
							<h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
								<InfoIcon className="h-5 w-5 text-gray-500" />
								Data Sharing
							</h3>
							<p className="mb-2 text-sm text-gray-600">
								For this assignment, your administrators have set the following data sharing settings:
							</p>
							<div className="space-y-3 mb-4">
								{assignment?.includeTranscript && (
									<div className="flex items-center gap-2 text-sm">
										<CheckCircleIcon className="h-5 w-5 text-green-500" />
										<FileTextIcon className="h-4 w-4 text-green-500" />
										<span className="font-medium">Full Transcript will be shared</span>
									</div>
								)}

								{!assignment?.includeTranscript && (
									<div className="flex items-center gap-2 text-sm">
										<XCircleIcon className="h-5 w-5 text-red-500" />
										<FileTextIcon className="h-4 w-4 text-gray-400" />
										<span className="font-medium text-gray-500">Full Transcript will not be shared</span>
									</div>
								)}

								{assignment?.includeAudio && (
									<div className="flex items-center gap-2 text-sm">
										<CheckCircleIcon className="h-5 w-5 text-green-500" />
										<MicIcon className="h-4 w-4 text-green-500" />
										<span className="font-medium">Audio Recording will be shared</span>
									</div>
								)}

								{!assignment?.includeAudio && (
									<div className="flex items-center gap-2 text-sm">
										<XCircleIcon className="h-5 w-5 text-red-500" />
										<MicIcon className="h-4 w-4 text-gray-400" />
										<span className="font-medium text-gray-500">Audio Recording will not be shared</span>
									</div>
								)}

								{assignment?.includeUserIdentification && (
									<div className="flex items-center gap-2 text-sm">
										<CheckCircleIcon className="h-5 w-5 text-green-500" />
										<UserIcon className="h-4 w-4 text-green-500" />
										<span className="font-medium">First and last name will be shared</span>
									</div>
								)}

								{!assignment?.includeUserIdentification && (
									<div className="flex items-center gap-2 text-sm">
										<XCircleIcon className="h-5 w-5 text-red-500" />
										<UserIcon className="h-4 w-4 text-gray-400" />
										<span className="font-medium text-gray-500">First and last name will not be shared</span>
									</div>
								)}
							</div>
						</div>
						<DialogFooter className="sm:justify-start gap-2">
							<Button
								variant="outline"
								onClick={() => {
									setShowConfidentialityDialog(false);
									setPendingAction(null);
								}}
							>
								Cancel
							</Button>
							<Button onClick={startSession}>Continue</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
				{selectedAssignedSession && selectedAssignedSession.sessionId && showResumeOptions && (
					<>{renderRestartOptions(selectedAssignedSession)}</>
				)}
				{!selectedUserSession && loggedInUserId === userId && selectedAssignedSession && <>{renderStartOptions(selectedAssignedSession)}</>}
				{selectedUserSession && !showResumeOptions && (
					<div key={selectedUserSession.sessionId}>
						<div className="flex justify-between items-center mt-12 mb-4">
							<h2 className="text-xl md:text-2xl font-semibold">
								{selectedUserSession.scheduleDescription || selectedUserSession.experienceType}: Individual Session Summary
							</h2>
							{debugMode && (
								<Button size="sm" onClick={regenerateSummary} disabled={isRegenerating}>
									{isRegenerating ? (
										<>
											<svg
												className="animate-spin h-5 w-5 mr-2 text-white"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
											>
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												<path
													className="opacity-75"
													fill="currentColor"
													d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
												></path>
											</svg>
											Regenerating...
										</>
									) : (
										"Regenerate Summary"
									)}
								</Button>
							)}
						</div>

						<div className="mb-6">
							<Report
								selectedUserSession={selectedUserSession}
								userName={getUserName(assignedSessions[0])}
								satisfactionData={satisfactionData}
								competencyData={competencyData}
								showRestartButton={selectedAssignedSession ? shouldShowRestartButton(selectedAssignedSession, selectedTab) : false}
								onRestartClick={() => {
									setShowResumeOptions(true);
									setStartNewSession(true);
									try {
										posthog.capture("MINOR: Restarted Session", {
											userId: instageUser?.fronteggId,
											timestamp: new Date().toISOString()
										});
									} catch (err) {
										console.error("Error capturing event 'MINOR: Restarted Session':", err);
									}
								}}
							/>
						</div>
					</div>
				)}
				{selectedAssignedSession && renderJobDetails()}
				{!selectedUserSession && isJobGenerated && (
					<>{selectedAssignedSession?.interviewStageType && <UserInterviewPlan selectedAssignedSession={selectedAssignedSession} />}</>
				)}
				{/* <OnboardingDialog mode={callMode} isOpen={showOnboardingDialog} onOpenChange={setShowOnboardingDialog} /> */}
			</div>
			<audio ref={audioRef} style={{ display: "none" }} />
		</div>
	);
};

// const scrollToTranscript = (index: number) => {
// 	window.scrollTo(0, document.body.scrollHeight);
// };
export default UserSessionPage;
