import React, { useEffect, useState } from "react";
import { Document, Packer, Paragraph, TextRun, Table, TableCell, TableRow, HeadingLevel, VerticalAlign, AlignmentType, BorderStyle } from "docx";
import { BaseTemplateProps } from "./BaseTemplate";
import { ResumeView } from "../../../types/ResumeView";
import { saveAs } from "file-saver";
import { ModernProfessionalTemplateHTML, ModernProfessionalTemplateDocX } from "./templates/ModernProfessional";
import { ClassicTemplateHTML, ClassicTemplateDocX } from "./templates/ClassicTemplate";
import { TemplateType } from "./TemplateRegistry";

// Docx documentation: https://docx.js.org/#/?id=welcome
interface DocxTemplateProps extends BaseTemplateProps {
	onDownload?: (downloadFn: () => Promise<void>) => void;
	TemplateType?: TemplateType;
}

const DocxTemplate: React.FC<DocxTemplateProps> = ({ resume, style, onDownload, TemplateType }) => {
	const [isEditFormOpen, setIsEditFormOpen] = useState(false);
	const [editingSection, setEditingSection] = useState("");
	const [editedData, setEditedData] = useState<any>(null);

	const handleSectionClick = (section: string) => {
		setEditingSection(section);
		setEditedData(resume[section as keyof ResumeView]);
		setIsEditFormOpen(true);
	};

	const downloadDocument = React.useCallback(async () => {
		let doc;
		console.log("style", style);
		if (!style) {
			console.log("No style found");
			return;
		}

		try {
			if (TemplateType === "modern-professional") {
				doc = ModernProfessionalTemplateDocX(resume, style);
			} else if (TemplateType === "classic") {
				doc = ClassicTemplateDocX(resume, style);
			} else {
				return;
			}

			const blob = await Packer.toBlob(doc);
			saveAs(blob, `${resume.contact.firstName}_${resume.contact.lastName}_Resume.docx`);
		} catch (error) {
			console.error("Error generating DOCX:", error);
		}
	}, [resume, TemplateType, style]);

	// Expose download function through prop
	useEffect(() => {
		if (onDownload) {
			onDownload(downloadDocument);
		}
	}, [onDownload, downloadDocument]);

	if (!style) {
		console.log("No style found");
		return null;
	}

	if (TemplateType === "modern-professional") {
		return ModernProfessionalTemplateHTML(resume, style, handleSectionClick, {
			editingSection,
			editedData,
			setEditedData,
			isEditFormOpen,
			setIsEditFormOpen
		});
	} else if (TemplateType === "classic") {
		return ClassicTemplateHTML(resume, style, handleSectionClick, {
			editingSection,
			editedData,
			setEditedData,
			isEditFormOpen,
			setIsEditFormOpen
		});
	}
	return null;
};

export default DocxTemplate;
