import React, { useEffect, useState } from "react";
import { ResumeView } from "../../types/ResumeView";
import { FeedbackNew, ResumeService } from "../../services/ResumeService";
import CustomTooltip from "../Utility/CustomTooltip";

const NewResumeFeedback = ({ resume, setResume }: { resume: ResumeView; setResume: (resume: ResumeView) => void }) => {
	const [isScoreGenerated, setIsScoreGenerated] = useState(false);
	const [feedback, setFeedback] = useState<FeedbackNew | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (resume.feedbackNew.overall.score > 0) {
			setFeedback(resume.feedbackNew);
			setIsScoreGenerated(true);
		} else {
			generateFeedback();
		}
	}, [resume]);

	const generateFeedback = async () => {
		setIsLoading(true);
		try {
			const feedback = await ResumeService.generateFeedbackNew(resume.id);
			console.log("feedback: ", feedback);
			setFeedback(feedback);
			setIsScoreGenerated(true);
			// Update the parent's resume state with the new feedback
			setResume({
				...resume,
				feedbackNew: feedback
			});
		} finally {
			setIsLoading(false);
		}
	};

	const CircularProgressBar = ({ score }: { score: number }) => {
		return (
			<div className="w-32 h-32 relative">
				<svg className="w-full h-full" viewBox="0 0 100 100">
					<circle className="text-gray-200" strokeWidth="8" stroke="currentColor" fill="transparent" r="42" cx="50" cy="50" />
					<circle
						className="text-[#00a9af]"
						strokeWidth="8"
						strokeLinecap="round"
						stroke="currentColor"
						fill="transparent"
						r="42"
						cx="50"
						cy="50"
						strokeDasharray={`${score * 2.64}, 264`}
						transform="rotate(-90 50 50)"
					/>
				</svg>
				<div className="absolute inset-0 flex flex-col items-center justify-center">
					<span className="text-3xl font-bold text-[#00a9af]">{Math.round(score)}%</span>
					<span className="text-sm text-gray-600">Overall</span>
				</div>
			</div>
		);
	};

	const HorizontalProgressBar = ({ score, section, infoText }: { score: number; section: string; infoText: string }) => {
		// Calculate color interpolation between red, yellow, and teal based on score
		const getBarColor = (score: number) => {
			const red = "#FF0000";
			const yellow = "#FFD700";
			const teal = "#00a9af";

			if (score <= 50) {
				// Interpolate between red and yellow for scores 0-50
				return `color-mix(in srgb, ${red} ${100 - score * 2}%, ${yellow} ${score * 2}%)`;
			} else {
				// Interpolate between yellow and teal for scores 51-100
				return `color-mix(in srgb, ${yellow} ${100 - (score - 50) * 2}%, ${teal} ${(score - 50) * 2}%)`;
			}
		};

		return (
			<div className="flex items-center mb-2">
				<p className="text-sm font-medium text-gray-600 mr-2 w-60">{section}</p>
				<div className="w-7 h-7 text-gray-500 mr-2 flex items-center justify-center">
					<CustomTooltip content={infoText} />
				</div>

				<div className="w-full bg-gray-200 rounded-full h-2.5">
					<div
						className="h-2.5 rounded-full"
						style={{
							width: `${score}%`,
							backgroundColor: getBarColor(score)
						}}
					></div>
				</div>
				<span className="ml-2 text-sm font-medium text-gray-600 w-20">{score}%</span>
			</div>
		);
	};

	const ScoreBreakdown = () => {
		return (
			<div className="flex flex-row gap-6 mt-4 mb-4 w-full min-h-[300px]">
				<div className="flex-1 flex flex-col gap-3 bg-white p-4 rounded-md">
					{isScoreGenerated ? (
						<>
							<div className="flex flex-col items-center mb-4">
								<CircularProgressBar score={feedback?.overall.score ?? 0} />
							</div>
							<h3 className="text-lg font-bold">Score Breakdown</h3>
							<p className="text-sm text-gray-500">This is a breakdown of the score for the resume.</p>
							<HorizontalProgressBar
								score={feedback?.summary.score ?? 0}
								section="Summary"
								infoText="Evaluates the clarity, conciseness, and impact of your professional summary or objective statement."
							/>
							<HorizontalProgressBar
								score={feedback?.experience.score ?? 0}
								section="Experience"
								infoText="Assesses your work history, including the use of action verbs, quantifiable achievements, and relevant responsibilities."
							/>
							<HorizontalProgressBar
								score={feedback?.education.score ?? 0}
								section="Education"
								infoText="Reviews your academic credentials, including degrees, certifications, relevant coursework, and academic achievements."
							/>
							<HorizontalProgressBar
								score={feedback?.skills.score ?? 0}
								section="Skills"
								infoText="Evaluates your technical, soft, and industry-specific skills, including their relevance and organization."
							/>
							<HorizontalProgressBar
								score={feedback?.projects.score ?? 0}
								section="Projects"
								infoText="Analyzes your project descriptions, including technical details, methodologies used, and demonstrated outcomes."
							/>
							<HorizontalProgressBar
								score={feedback?.extraCurricular.score ?? 0}
								section="Extra Curricular"
								infoText="Reviews volunteer work, leadership roles, and other activities that demonstrate additional capabilities and character."
							/>
						</>
					) : (
						<div className="flex flex-col items-center justify-center h-full">
							<h3 className="text-lg font-bold">Score Breakdown</h3>
							<p className="text-sm text-gray-500">This is a breakdown of the score for the resume.</p>
						</div>
					)}
					<button
						className="bg-[#00a9af] text-white px-4 py-2 rounded-md flex items-center justify-center gap-2 disabled:opacity-50"
						onClick={generateFeedback}
						disabled={isLoading}
					>
						{isLoading ? (
							<>
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
								<span>Generating...</span>
							</>
						) : (
							"Generate Feedback"
						)}
					</button>
				</div>
				<div className="flex-1 flex flex-col gap-3 bg-white p-4 rounded-md">
					<h3 className="text-lg font-bold">Positive Feedback</h3>
					<p className="text-sm text-gray-500">Positive feedback for your resume overall.</p>
					<p className="text-md text-gray-500">{feedback?.overall.positiveFeedback}</p>
				</div>
				<div className="flex-1 flex flex-col gap-3 bg-white p-4 rounded-md">
					<h3 className="text-lg font-bold">Areas to Improve</h3>
					<p className="text-sm text-gray-500">Areas to consider improving for your resume overall.</p>
					<p className="text-md text-gray-500">{feedback?.overall.areasToImprove}</p>
				</div>
			</div>
		);
	};

	return (
		<div>
			<ScoreBreakdown />
		</div>
	);
};

export default NewResumeFeedback;
