import React from "react";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import { FrontlineStage, FrontlineStageType, FrontlineConfig, SavedFrontlinePlan } from "../../../../types/FrontlinePayload";
import { Input } from "../../../shadcn-ui/Input";
import { Button } from "../../../shadcn-ui/Button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";

interface FormValues {
	stages?: FrontlineStage[];
}

interface FrontlinePlanProps {
	frontlineStageOrderNumber: number;
	values: FormValues;
	setFieldValue: (field: string, value: any) => void;
	isAdminPath?: boolean;
	savedPlans?: SavedFrontlinePlan[];
	onSavedPlanSelect?: (plan: SavedFrontlinePlan) => void;
}

const FRAMEWORKS = {
	deny: {
		label: "Denying a Request",
		value: "deny"
	},
	comply: {
		label: "Complying with a Request",
		value: "comply"
	},
	gather: {
		label: "Information Gathering",
		value: "gather"
	}
} as const;

const formatSavedPlanLabel = (plan: SavedFrontlinePlan): string => {
	const stageType = FRAMEWORKS[plan.frontlineStageType].label;
	return `${stageType} - ${plan.frontlineAssistantProblem}`;
};

const STUDENT_ROLES: { label: string; value: string }[] = [
	{ label: "Service Representative", value: "Service Representative" },
	{ label: "Administrative Assistant", value: "Administrative Assistant" },
	{ label: "Other", value: "Other" }
];

const AI_ROLES: { label: string; value: string }[] = [
	{ label: "Guest", value: "Guest" },
	{ label: "Client", value: "Client" },
	{ label: "Patient", value: "Patient" },
	{ label: "Customer", value: "Customer" },
	{ label: "Other", value: "Other" }
];

export const getInitialConfig = (type: FrontlineStageType): FrontlineConfig => {
	const baseConfig: FrontlineConfig = {
		frontlineStudentRole: "Service Representative",
		frontlineAssistantRole: "Guest",
		frontlineAssistantProblem: "",
		frontlineAssistantRequest: "",
		frontlineKeyPoints: []
	};

	switch (type) {
		case "deny":
			return {
				...baseConfig,
				frontlineIssueWithRequest: "",
				frontlineInfoToCollect: undefined
			};
		case "comply":
			return {
				...baseConfig,
				frontlineInfoToCollect: [],
				frontlineIssueWithRequest: undefined
			};
		case "gather":
			return {
				...baseConfig,
				frontlineInfoToCollect: [],
				frontlineIssueWithRequest: undefined
			};
	}
};

const FrontlinePlan: React.FC<FrontlinePlanProps> = ({
	frontlineStageOrderNumber,
	values,
	setFieldValue,
	isAdminPath = true,
	savedPlans = [],
	onSavedPlanSelect
}) => {
	// Initialize stage if not set
	React.useEffect(() => {
		if (!values.stages) return;

		const stage = values.stages.find((s) => s.frontlineStageOrderNumber === frontlineStageOrderNumber);
		if (stage?.enabled && !stage.frontlineStageType) {
			const updatedStages = values.stages.map((s) => {
				if (s.frontlineStageOrderNumber === frontlineStageOrderNumber) {
					return {
						...s,
						frontlineStageType: "deny",
						config: getInitialConfig("deny")
					};
				}
				return s;
			});
			setFieldValue("stages", updatedStages);
		}
	}, [frontlineStageOrderNumber, values.stages, setFieldValue]);

	const currentStage = values.stages?.find((s) => s.frontlineStageOrderNumber === frontlineStageOrderNumber);
	if (!currentStage?.enabled) return null;

	const updateConfig = (updates: Partial<FrontlineConfig>) => {
		if (!values.stages) return;

		const updatedStages = values.stages.map((s) => {
			if (s.frontlineStageOrderNumber === frontlineStageOrderNumber) {
				return {
					...s,
					config: {
						...s.config,
						...updates
					}
				};
			}
			return s;
		});
		setFieldValue("stages", updatedStages);
	};

	const addKeyPoint = () => {
		if (!currentStage.config?.frontlineKeyPoints) {
			updateConfig({ frontlineKeyPoints: [""] });
		} else if (currentStage.config.frontlineKeyPoints.length < 5) {
			updateConfig({ frontlineKeyPoints: [...currentStage.config.frontlineKeyPoints, ""] });
		}
	};

	const updateKeyPoint = (index: number, value: string) => {
		if (!currentStage.config?.frontlineKeyPoints) return;
		const newKeyPoints = [...currentStage.config.frontlineKeyPoints];
		newKeyPoints[index] = value;
		updateConfig({ frontlineKeyPoints: newKeyPoints });
	};

	const removeKeyPoint = (index: number) => {
		if (!currentStage.config?.frontlineKeyPoints) return;
		const newKeyPoints = currentStage.config.frontlineKeyPoints.filter((_: string, i: number) => i !== index);
		updateConfig({ frontlineKeyPoints: newKeyPoints });
	};

	const addInformationToGather = () => {
		if (currentStage.frontlineStageType !== "deny" && currentStage.config) {
			const currentInfo = currentStage.config.frontlineInfoToCollect || [];
			if (currentInfo.length < 5) {
				updateConfig({ frontlineInfoToCollect: [...currentInfo, ""] });
			}
		}
	};

	const updateInformationToGather = (index: number, value: string) => {
		if (currentStage.frontlineStageType !== "deny" && currentStage.config) {
			const newInfo = [...(currentStage.config.frontlineInfoToCollect || [])];
			newInfo[index] = value;
			updateConfig({ frontlineInfoToCollect: newInfo });
		}
	};

	const removeInformationToGather = (index: number) => {
		if (currentStage.frontlineStageType !== "deny" && currentStage.config) {
			const newInfo = (currentStage.config.frontlineInfoToCollect || []).filter((_: string, i: number) => i !== index);
			updateConfig({ frontlineInfoToCollect: newInfo });
		}
	};

	const getInfoPlaceholder = (index: number): string => {
		if (currentStage.frontlineStageType === "comply") {
			const placeholders = [
				"Flight dates and times",
				"Number of passengers",
				"Baggage requirements",
				"Meal preferences",
				"Passport/ID details"
			];
			return placeholders[index] || "Required information";
		} else if (currentStage.frontlineStageType === "gather") {
			const placeholders = [
				"Flight number and date",
				"Description of damage",
				"Location and time discovered",
				"Cost of repair/replacement",
				"Photos or documentation"
			];
			return placeholders[index] || "Required information";
		}
		return "Required information";
	};

	const getKeyPointPlaceholder = (index: number): string => {
		if (currentStage.frontlineStageType === "deny") {
			const placeholders = [
				"Express understanding of situation",
				"Explain policy clearly",
				"Offer alternative solutions",
				"Maintain professional tone",
				"Provide clear next steps"
			];
			return placeholders[index] || "Key point";
		} else if (currentStage.frontlineStageType === "comply") {
			const placeholders = [
				"Confirm understanding of needs",
				"Explain booking process",
				"Review airline policies",
				"Verify all details",
				"Summarize next steps"
			];
			return placeholders[index] || "Key point";
		} else {
			const placeholders = [
				"Express concern for situation",
				"Explain investigation process",
				"Outline documentation needed",
				"Set timeline expectations",
				"Confirm contact details"
			];
			return placeholders[index] || "Key point";
		}
	};

	return (
		<div className="space-y-6">
			{savedPlans.length > 0 && onSavedPlanSelect && (
				<div className="bg-[#e6f7f7] border border-[#b3e6e8] rounded-lg p-4">
					<label className="block text-sm font-medium text-gray-700 mb-2">
						Quick Start: Use a Saved Setup
						<span className="ml-2 text-sm font-normal text-gray-500">(Optional)</span>
					</label>
					<Select
						onValueChange={(value) => {
							const selectedPlan = savedPlans.find((plan) => plan.id === value);
							if (selectedPlan) {
								onSavedPlanSelect(selectedPlan);
							}
						}}
					>
						<SelectTrigger className="w-full bg-white">
							<SelectValue placeholder="Choose a saved setup..." />
						</SelectTrigger>
						<SelectContent>
							{savedPlans.map((plan) => (
								<SelectItem key={plan.id} value={plan.id}>
									{formatSavedPlanLabel(plan)}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<p className="mt-2 text-sm text-gray-500">
						Select one of your pre-configured setups to automatically fill in the configuration below
					</p>
				</div>
			)}

			<div>
				<label className="block text-sm font-medium text-gray-700 mb-2">Framework*</label>
				<Select
					value={currentStage.frontlineStageType || ""}
					onValueChange={(value: FrontlineStageType) => {
						if (!values.stages) return;

						const updatedStages = values.stages.map((s) => {
							if (s.frontlineStageOrderNumber === frontlineStageOrderNumber) {
								return {
									...s,
									frontlineStageType: value,
									config: getInitialConfig(value)
								};
							}
							return s;
						});
						setFieldValue("stages", updatedStages);
					}}
				>
					<SelectTrigger className="w-full">
						<SelectValue placeholder="Select a framework" />
					</SelectTrigger>
					<SelectContent>
						{Object.values(FRAMEWORKS).map((framework) => (
							<SelectItem key={framework.value} value={framework.value}>
								{framework.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>

			<div>
				<label className="block text-sm font-medium text-gray-700 mb-2">Student Role*</label>
				<Select
					value={
						currentStage.config?.frontlineStudentRole === "" ||
						!STUDENT_ROLES.some((r) => r.value === currentStage.config?.frontlineStudentRole)
							? "Other"
							: currentStage.config?.frontlineStudentRole || "Service Representative"
					}
					onValueChange={(value: string) => {
						if (value === "Other") {
							updateConfig({ frontlineStudentRole: "" });
						} else {
							updateConfig({ frontlineStudentRole: value });
						}
					}}
				>
					<SelectTrigger className="w-full">
						<SelectValue placeholder="Select student role" />
					</SelectTrigger>
					<SelectContent>
						{STUDENT_ROLES.map((role) => (
							<SelectItem key={role.value} value={role.value}>
								{role.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				{(currentStage.config?.frontlineStudentRole === "" ||
					!STUDENT_ROLES.some((r) => r.value === currentStage.config?.frontlineStudentRole)) && (
					<Input
						className="mt-2"
						placeholder="Enter custom role"
						value={currentStage.config?.frontlineStudentRole || ""}
						onChange={(e) => updateConfig({ frontlineStudentRole: e.target.value })}
						autoFocus
					/>
				)}
			</div>

			<div>
				<label className="block text-sm font-medium text-gray-700 mb-2">AI Role*</label>
				<Select
					value={
						currentStage.config?.frontlineAssistantRole === "" ||
						!AI_ROLES.some((r) => r.value === currentStage.config?.frontlineAssistantRole)
							? "Other"
							: currentStage.config?.frontlineAssistantRole || "Guest"
					}
					onValueChange={(value: string) => {
						if (value === "Other") {
							updateConfig({ frontlineAssistantRole: "" });
						} else {
							updateConfig({ frontlineAssistantRole: value });
						}
					}}
				>
					<SelectTrigger className="w-full">
						<SelectValue placeholder="Select AI role" />
					</SelectTrigger>
					<SelectContent>
						{AI_ROLES.map((role) => (
							<SelectItem key={role.value} value={role.value}>
								{role.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				{(currentStage.config?.frontlineAssistantRole === "" ||
					!AI_ROLES.some((r) => r.value === currentStage.config?.frontlineAssistantRole)) && (
					<Input
						className="mt-2"
						placeholder="Enter custom role"
						value={currentStage.config?.frontlineAssistantRole || ""}
						onChange={(e) => updateConfig({ frontlineAssistantRole: e.target.value })}
						autoFocus
					/>
				)}
			</div>

			<div>
				<label className="block text-sm font-medium text-gray-700 mb-2">What is the AI's problem?*</label>
				<Input
					placeholder={
						currentStage.frontlineStageType === "comply"
							? "They have never flown, and would like help to make a booking."
							: currentStage.frontlineStageType === "gather"
							? "Passenger discovered damaged suitcase wheel after their flight"
							: "e.g. 'They missed their flight due to traffic and want to reschedule, but they booked a basic economy ticket'"
					}
					value={currentStage.config?.frontlineAssistantProblem || ""}
					onChange={(e) => updateConfig({ frontlineAssistantProblem: e.target.value })}
				/>
			</div>

			<div>
				<label className="block text-sm font-medium text-gray-700 mb-2">
					{currentStage.frontlineStageType === "gather"
						? "What situation or concern does the AI need help with?*"
						: "What is being requested?*"}
				</label>
				<Input
					placeholder={
						currentStage.frontlineStageType === "comply"
							? "Assistance booking their flight"
							: currentStage.frontlineStageType === "gather"
							? "Compensation for damage"
							: "e.g. 'Change booking for missed flight'"
					}
					value={currentStage.config?.frontlineAssistantRequest || ""}
					onChange={(e) => updateConfig({ frontlineAssistantRequest: e.target.value })}
				/>
			</div>

			{currentStage.frontlineStageType === "deny" && (
				<div>
					<label className="block text-sm font-medium text-gray-700 mb-2">What is the issue with the AI's request?*</label>
					<Input
						placeholder="e.g. 'Policy violation'"
						value={currentStage.config?.frontlineIssueWithRequest || ""}
						onChange={(e) => updateConfig({ frontlineIssueWithRequest: e.target.value })}
					/>
				</div>
			)}

			{(currentStage.frontlineStageType === "comply" || currentStage.frontlineStageType === "gather") && (
				<div>
					<label className="block text-sm font-medium text-gray-700 mb-2">
						What specific information should the student gather during the interaction?*
					</label>
					{currentStage.config?.frontlineInfoToCollect?.map((info: string, index: number) => (
						<div key={index} className="flex gap-2 mb-2">
							<Input
								placeholder={`e.g. ${getInfoPlaceholder(index)}`}
								value={info}
								onChange={(e) => updateInformationToGather(index, e.target.value)}
							/>
							<Button variant="outline" size="icon" type="button" onClick={() => removeInformationToGather(index)}>
								<XMarkIcon className="h-4 w-4" />
							</Button>
						</div>
					))}
					<Button
						type="button"
						variant="outline"
						onClick={addInformationToGather}
						className="w-full mt-2"
						disabled={currentStage.config?.frontlineInfoToCollect?.length === 5}
					>
						<PlusIcon className="h-4 w-4 mr-2" />
						Add Information to Gather
					</Button>
				</div>
			)}

			<div>
				<label className="block text-sm font-medium text-gray-700 mb-2">
					What specific points should the student communicate during the interaction?*
				</label>
				{currentStage.config?.frontlineKeyPoints?.map((point: string, index: number) => (
					<div key={index} className="flex gap-2 mb-2">
						<Input
							placeholder={`e.g. ${getKeyPointPlaceholder(index)}`}
							value={point}
							onChange={(e) => updateKeyPoint(index, e.target.value)}
						/>
						<Button variant="outline" size="icon" type="button" onClick={() => removeKeyPoint(index)}>
							<XMarkIcon className="h-4 w-4" />
						</Button>
					</div>
				))}
				<Button
					type="button"
					variant="outline"
					onClick={addKeyPoint}
					className="w-full mt-2"
					disabled={currentStage.config?.frontlineKeyPoints?.length === 5}
				>
					<PlusIcon className="h-4 w-4 mr-2" />
					Add Key Point
				</Button>
			</div>
		</div>
	);
};

export default FrontlinePlan;
