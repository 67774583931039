import React from "react";
import HorizontalTabs, { TabData } from "./HorizontalTabs";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { ActivityType } from "../../schemas/ActivityType";

export default function TabsTestPage() {
	const sampleTabs: TabData[] = [
		{
			label: "Available",
			status: AssignedSessionStatus.AVAILABLE,
			date: new Date(),
			scheduleId: "6",
			activityType: "first" as ActivityType
		},
		{
			label: "Submitted",
			status: AssignedSessionStatus.SUBMITTED,
			date: new Date(),
			scheduleId: "2",
			activityType: "first" as ActivityType
		},
		{
			label: "Submitted Late",
			status: AssignedSessionStatus.SUBMITTED_LATE,
			date: new Date(),
			scheduleId: "3",
			activityType: "first" as ActivityType
		},
		{
			label: "In Progress",
			status: AssignedSessionStatus.IN_PROGRESS,
			date: new Date(),
			scheduleId: "9",
			activityType: "first" as ActivityType
		},
		{
			label: "Incomplete",
			status: AssignedSessionStatus.INCOMPLETE,
			date: new Date(),
			scheduleId: "10",
			activityType: "first" as ActivityType
		},
		{
			label: "Unsubmitted",
			status: AssignedSessionStatus.UNSUBMITTED,
			date: new Date(),
			scheduleId: "11",
			activityType: "first" as ActivityType
		},
		{
			label: "Not Attempted",
			status: AssignedSessionStatus.NOT_ATTEMPTED,
			date: new Date(),
			scheduleId: "4",
			activityType: "first" as ActivityType
		},
		{
			label: "Pending",
			status: AssignedSessionStatus.PENDING,
			date: new Date(),
			scheduleId: "5",
			activityType: "first" as ActivityType
		},
		{
			label: "Draft",
			status: AssignedSessionStatus.DRAFT,
			date: new Date(),
			scheduleId: "8",
			activityType: "first" as ActivityType
		},
		{
			label: "Not Available",
			status: AssignedSessionStatus.NOT_AVAILABLE,
			date: new Date(),
			scheduleId: "12",
			activityType: "first" as ActivityType
		},
		{
			label: "Not Confirmed",
			status: AssignedSessionStatus.NOT_CONFIRMED,
			date: new Date(),
			scheduleId: "7",
			activityType: "first" as ActivityType
		}
	];

	return (
		<div className="p-4">
			<h1 className="text-2xl font-bold mb-4">Status Tab Examples</h1>
			<HorizontalTabs
				tabs={sampleTabs}
				type="admin"
				selectedTab={0}
				assignmentId="test-assignment"
				userId={null}
				onTabSelect={(scheduleId) => console.log("Selected schedule:", scheduleId)}
			/>
		</div>
	);
}
