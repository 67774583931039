export type FrontlineStageType = "deny" | "comply" | "gather";

export type FrontlineProgramName = "Flight Services" | "Hospitality Services" | "Office Administration";

export interface FrontlineConfig {
	frontlineStudentRole: string;
	frontlineAssistantRole: string;
	frontlineAssistantProblem: string;
	frontlineAssistantRequest: string;
	frontlineKeyPoints: string[];
	frontlineIssueWithRequest?: string;
	frontlineInfoToCollect?: string[];
}

export interface SavedFrontlinePlan {
	id: string;
	frontlineStageOrderNumber: number;
	frontlineStageType: FrontlineStageType;
	frontlineStudentRole: string;
	frontlineAssistantRole: string;
	frontlineAssistantProblem: string;
	frontlineAssistantRequest: string;
	frontlineKeyPoints: string[];
	frontlineIssueWithRequest?: string;
	frontlineInfoToCollect?: string[];
}

export interface FrontlineStage {
	frontlineStageOrderNumber: number;
	frontlineStageType: FrontlineStageType;
	enabled: boolean;
	date: Date | null;
	startDate: Date | null;
	config?: FrontlineConfig;
}

export class FrontlinePayload {
	frontlineProgramName: string;
	frontlineStageOrderNumber: number;
	frontlineStageType: FrontlineStageType;
	config?: FrontlineConfig;

	constructor(data: Partial<FrontlinePayload>) {
		this.frontlineProgramName = data.frontlineProgramName ?? "";
		this.frontlineStageOrderNumber = data.frontlineStageOrderNumber ?? 0;
		this.frontlineStageType = data.frontlineStageType ?? "deny";
		this.config = data.config;
	}
}
