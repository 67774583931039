import React, { useState } from "react";
import { ProfileView } from "../../types/ProfileView";

interface ProfileSectionNavProps {
	selectedSection: string;
	setSelectedSection: (section: string) => void;
	profile: ProfileView | null;
}

const sections = [
	{ id: "contact", label: "Contact Information", required: false },
	{ id: "summary", label: "Summary", required: true },
	{ id: "experience", label: "Experience", required: true },
	{ id: "education", label: "Education", required: true },
	{ id: "skills", label: "Skills", required: true },
	{ id: "projects", label: "Projects", required: true },
	{ id: "certifications", label: "Certifications", required: false },
	{ id: "extraCurricular", label: "Extra-Curricular", required: false },
	{ id: "interests", label: "Interests", required: false },
	{ id: "hobbies", label: "Hobbies", required: false }
];

const ProfileSectionNav: React.FC<ProfileSectionNavProps> = ({ selectedSection, setSelectedSection, profile }) => {
	const [hoveredSection, setHoveredSection] = useState<string | null>(null);

	const getMissingFields = (section: string): string[] => {
		if (!profile) return [];

		switch (section) {
			case "contact": {
				const contactFields = [
					{ key: "firstName", label: "First Name" },
					{ key: "lastName", label: "Last Name" },
					{ key: "email", label: "Email" },
					{ key: "phone", label: "Phone" },
					{ key: "address", label: "Address" },
					{ key: "linkedinURL", label: "LinkedIn" }
				];
				return contactFields
					.filter((field) => !profile.contact || !profile.contact[field.key as keyof typeof profile.contact])
					.map((field) => field.label);
			}
			case "summary":
				return !profile.summary || profile.summary.trim().length <= 50 ? ["Complete summary"] : [];
			case "experience":
				if (!profile.experience?.length) return ["Add work experience"];
				const missingExp: string[] = [];
				profile.experience.forEach((exp, idx) => {
					if (!exp.companyName) missingExp.push(`Experience ${idx + 1}: Company name`);
					if (!exp.jobTitle) missingExp.push(`Experience ${idx + 1}: Job title`);
					if (!exp.startMonth || !exp.startYear) missingExp.push(`Experience ${idx + 1}: Start date`);
					if (!exp.roles?.length) missingExp.push(`Experience ${idx + 1}: Responsibilities`);
				});
				return missingExp;
			case "education":
				if (!profile.education?.length) return ["Add education"];
				const missingEdu: string[] = [];
				profile.education.forEach((edu, idx) => {
					if (!edu.school) missingEdu.push(`Education ${idx + 1}: School name`);
					if (!edu.degree) missingEdu.push(`Education ${idx + 1}: Degree`);
					if (!edu.program) missingEdu.push(`Education ${idx + 1}: Program`);
					if (!edu.graduatingYear) missingEdu.push(`Education ${idx + 1}: Graduation year`);
				});
				return missingEdu;
			case "skills": {
				const missingSkills: string[] = [];
				if (!profile.skills?.tools?.length) missingSkills.push("Tools");
				if (!profile.skills?.languages?.length) missingSkills.push("Languages");
				if (!profile.skills?.technical?.length) missingSkills.push("Technical skills");
				if (!profile.skills?.frameworks?.length) missingSkills.push("Frameworks");
				if (!profile.skills?.coreCompetencies?.length) missingSkills.push("Core competencies");
				return missingSkills.length ? missingSkills.map((s) => `Add ${s.toLowerCase()}`) : [];
			}
			case "projects":
				if (!profile.projects?.length) return ["Add projects"];
				const missingProj: string[] = [];
				profile.projects.forEach((proj, idx) => {
					if (!proj.projectName) missingProj.push(`Project ${idx + 1}: Name`);
					if (!proj.description) missingProj.push(`Project ${idx + 1}: Description`);
					if (!proj.startMonth || !proj.startYear) missingProj.push(`Project ${idx + 1}: Date`);
				});
				return missingProj;
			case "certifications":
				return profile.certifications?.length ? [] : ["No certifications added"];
			case "extraCurricular":
				return profile.extraCurricular?.length ? [] : ["No activities added"];
			case "interests":
				return profile.interests?.length ? [] : ["No interests added"];
			case "hobbies":
				return profile.hobbies?.length ? [] : ["No hobbies added"];
			default:
				return [];
		}
	};

	const calculateSectionStatus = (section: string): "empty" | "partial" | "complete" => {
		if (!profile) return "empty";
		const missingFields = getMissingFields(section);
		if (missingFields.length === 0) return "complete";

		switch (section) {
			case "experience":
				return !profile.experience?.length ? "empty" : "partial";
			case "education":
				return !profile.education?.length ? "empty" : "partial";
			case "skills":
				return !profile.skills || Object.values(profile.skills).every((arr) => !arr?.length) ? "empty" : "partial";
			case "projects":
				return !profile.projects?.length ? "empty" : "partial";
			case "certifications":
				return !profile.certifications?.length ? "empty" : "complete";
			case "extraCurricular":
				return !profile.extraCurricular?.length ? "empty" : "complete";
			case "interests":
				return !profile.interests?.length ? "empty" : "complete";
			case "hobbies":
				return !profile.hobbies?.length ? "empty" : "complete";
			default:
				return missingFields.length === getMissingFields(section).length ? "empty" : "partial";
		}
	};

	const getStatusColor = (status: "empty" | "partial" | "complete") => {
		switch (status) {
			case "empty":
				return "bg-red-500/10 border-2 border-red-500";
			case "partial":
				return "bg-yellow-500/10 border-2 border-yellow-500";
			case "complete":
				return "bg-[#00a9af]/10 border-2 border-[#00a9af]";
		}
	};

	return (
		<div className="sticky top-4 w-64 bg-white rounded-lg shadow-md p-4 h-fit z-[100]">
			<h3 className="text-lg font-semibold mb-4 text-gray-800">Sections</h3>
			<nav>
				<ul className="space-y-2">
					{sections.map((section) => {
						const status = calculateSectionStatus(section.id);
						const missingFields = getMissingFields(section.id);

						return (
							<li key={section.id}>
								<button
									onClick={() => setSelectedSection(section.id)}
									onMouseEnter={() => setHoveredSection(section.id)}
									onMouseLeave={() => setHoveredSection(null)}
									className={`w-full text-left px-3 py-2 rounded-md transition-colors duration-200 relative ${
										selectedSection === section.id ? "border-2 border-[#00a9af] text-[#00a9af]" : "text-gray-600 hover:bg-gray-50"
									}`}
								>
									<div className="flex items-center justify-between">
										<span className="flex items-center gap-1">
											{section.label}
											{section.required && <span className="text-red-500/50">*</span>}
										</span>
										<div className={`w-3 h-3 rounded-full ${getStatusColor(status)}`} />
									</div>

									{hoveredSection === section.id && missingFields.length > 0 && (
										<div className="absolute left-full ml-2 top-0 w-48 p-2 bg-white rounded-md shadow-lg border border-gray-200 z-[9999] pointer-events-none">
											<p className="font-medium mb-1 text-gray-700">Missing:</p>
											<ul className="text-xs text-gray-600 list-disc pl-4">
												{missingFields.map((field, idx) => (
													<li key={idx}>{field}</li>
												))}
											</ul>
										</div>
									)}
								</button>
							</li>
						);
					})}
				</ul>
			</nav>
		</div>
	);
};

export default ProfileSectionNav;
